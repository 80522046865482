import React, { useEffect, useState } from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TopNavigation } from '../components/atoms/TopNavigation';
import { searchParams } from '../components/blocks/CommonFn';
import FriendsList from './Notice/FriendsList';
import GameList from './Notice/GameList';

export const NoticeList = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState('1');

  if (searchParams('game')) {
    setTab('2');
  }

  return (
    <Container>
      <TopNavigation type="back" handleEvent={() => navigate('/clubhouse')}>
        알림
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TabArea>
          <TabItem
            className={tab === '1' ? 'on' : ''}
            onClick={() => setTab('1')}
          >
            친구추가
          </TabItem>
          <TabItem
            className={tab === '2' ? 'on' : ''}
            onClick={() => setTab('2')}
          >
            게임초대
          </TabItem>
        </TabArea>
        {tab === '1' && (
          <>
            <FriendsList />
          </>
        )}
        {tab === '2' && (
          <>
            <GameList />
          </>
        )}
      </Box>
    </Container>
  );
};

export default NoticeList;

const TabArea = styled.div`
  display: flex;
  flex: 1 1;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 5px;
`;
const TabItem = styled.div`
  width: 100%;
  height: 34px;
  margin-top: 16px;
  margin-bottom: 24px;

  font-size: 14px;
  font-weight: 500;
  color: #505866;
  line-height: 34px;
  text-align: center;

  border-bottom: 2px solid #e7e9ec;

  &.on {
    font-weight: bold;
    color: #1fb04b;
    border-color: #1fb04b;
  }
`;
