import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import IconDelete from '../assets/images/icn_delete.svg';
import IconFrendsPlus from '../assets/images/icn_friends_plus.svg';
import IconMore from '../assets/images/icn_more.svg';
import IconSearch from '../assets/images/icon/search_g.svg';
import MemberImg from '../assets/images/member.png';
import { BottomButton } from '../components/atoms/BottomButton';
import { TopNavigation } from '../components/atoms/TopNavigation';
import DimmedAddFriends from './Friends/DimmedAddFriends';

export default function Friend() {
  const navigate = useNavigate();
  const [friendList, setFriendList] = useState([
    { golf_club_name: '직접 입력', location: '-' },
  ]);
  const [addPop, setAddPop] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [deleteFriendList, setDeleteFriendList] = useState<number[]>([]);

  const filterData = (query: string, data: any[]) => {
    if (!query) {
      return data;
    } else {
      return data.filter((d) => d.golf_club_name.includes(query));
    }
  };

  const [searchQuery, setSearchQuery] = useState('');
  const dataFiltered = filterData(searchQuery, friendList);

  const deleteFriend = () => {
    if (deleteFriendList.length !== 0) {
      restApi
        .put('/user/friend/delete', {
          friend_user_id_list: deleteFriendList,
        })
        .then((e) => {
          const { data } = e;
          if (data.status_code === '200') {
            setDeleteFriendList([]);
            callFriendList();
          }
        });
    }
  };

  const removeExistingValue = (arr: Array<any>, value: any) => {
    return arr.filter((item) => item !== value);
  };
  const addDeleteFriendList = (id: number) => {
    if (deleteFriendList.includes(id)) {
      setDeleteFriendList(removeExistingValue(deleteFriendList, id));
    } else {
      setDeleteFriendList([...deleteFriendList, id]);
    }
  };

  const callFriendList = () => {
    restApi.get('/user/friend/list').then((e) => {
      const { data } = e;

      if (data.status_code === '200') {
        setFriendList(data.result_data);
      } else if (data.status_code === '505') {
        setFriendList([]);
      }
    });
  };

  useEffect(() => {
    callFriendList();
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (deleteMode) {
      setDeleteFriendList([]);
      setDeleteMode(false);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <TopNavigation type="close" handleEvent={() => navigate('/mypage')}>
        친구
      </TopNavigation>
      <RightTopButton onClick={handleClick}>
        {deleteMode ? (
          <Complete>완료</Complete>
        ) : (
          <img src={IconMore} alt="more" />
        )}
      </RightTopButton>
      <SubMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            setAddPop(true);
          }}
        >
          친구 추가
          <img src={IconFrendsPlus} alt="icon" />
        </MenuItem>
        <Divider />
        <MenuItem
          style={{ color: '#c13537' }}
          onClick={() => {
            handleClose();
            setDeleteMode(true);
          }}
        >
          삭제하기
          <img src={IconDelete} alt="icon" />
        </MenuItem>
      </SubMenu>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/*<SearchBarWrap>
          <SearchBar
            id="search-bar"
            className="text"
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            placeholder="이름을 입력하세요."
            style={{ width: '100%' }}
            value={searchQuery}
          />
          <img src={IconSearch} alt="icon" />
        </SearchBarWrap>*/}
        {dataFiltered.length === 0 ? (
          <ListItem style={{ display: 'block', textAlign: 'center' }}>
            현재 등록된 친구가 없습니다.
            <br />
            친구를 추가하여 게임을 즐겨보세요
          </ListItem>
        ) : (
          dataFiltered.map((d, i) => (
            <ListItem
              key={i}
              onClick={() =>
                deleteMode && addDeleteFriendList(d.friend_user_id)
              }
              className={
                deleteFriendList.includes(d.friend_user_id) ? 'on' : 'off'
              }
            >
              {deleteMode && <CheckStatus className="check" />}
              <img src={d.friend_profile || MemberImg} alt="Member" />
              <Typography variant="h5">{d.friend_nickname}</Typography>
            </ListItem>
          ))
        )}
        {deleteMode && (
          <BottomButton
            txt="삭제"
            disabled={deleteFriendList.length === 0}
            handleEvent={deleteFriend}
            loc="bottom"
            type="warning"
          />
        )}
      </Box>
      {addPop && <DimmedAddFriends clsView={() => setAddPop(false)} />}
    </Container>
  );
}

const RightTopButton = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
`;

const Complete = styled.div`
  width: 48px;
  font-size: 14px;
  font-weight: bold;
  color: #1fb04b;
  line-height: 48px;
  text-align: center;
`;

const SubMenu = styled(Menu)`
  top: -20px;
  left: -70px;

  .MuiPaper-root {
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .Mui-focusVisible {
    background-color: initial;
  }

  ul {
    padding: 0;

    .MuiDivider-root {
      margin: 0;
    }
    li {
      justify-content: space-between;
      padding: 0 0 0 16px;
      line-height: 1;
      color: #505866;
      img {
        margin-left: 30px;
      }
    }
  }
`;

const SearchBarWrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  img {
    position: absolute;
    top: 13px;
    left: 16px;
    width: 24px;
  }
`;

const SearchBar = styled.input`
  width: 100%;
  height: 50px;
  padding: 13px 16px 13px 52px;
  border: solid 1px #b1b8c0;
  border-radius: 25px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: #b1b8c0;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  width: 100%;
  margin-bottom: 8px;
  padding: 12px 16px 12px 12px;
  border-radius: 16px;
  background-color: #fafafb;

  transition: all 300ms ease-out;

  img {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border-radius: 24px;
  }
  &.on {
    background-color: #f2f3f5;
    .check {
      border-color: #1fb04b;
      background-color: #1fb04b;
    }
  }
`;

const CheckStatus = styled.div`
  width: 20px;
  height: 20px;
  margin: 14px 24px 14px 0;
  object-fit: contain;
  border: solid 1px #b1b8c0;
  border-radius: 10px;
`;
