import React from 'react';
import { useRoutes } from 'react-router-dom';

import { SocketDataContextProvider } from '../contexts/SocketDataContext';
import ChangePassword from '../pages/ChangePassword';
import Clubhouse from '../pages/Clubhouse';
import CreateGroup from '../pages/CreateGroup';
import DrawOrder from '../pages/DrawOrder';
import FavoriteCC from '../pages/FavoriteCC';
import FindPassword from '../pages/FindPassword';
import Friend from '../pages/Friend';
import GameShare from '../pages/GameShare';
import GameType from '../pages/GameType';
import Detailpage from '../pages/Guide/DetailPage';
import GolfunGuide from '../pages/Guide/GolfunGuide';
import GuideChip from '../pages/Guide/GuideChip';
import GuideCreate from '../pages/Guide/GuideCreate';
import GuideDraw from '../pages/Guide/GuideDraw';
import GuideExit from '../pages/Guide/GuideExit';
import GuideInvite from '../pages/Guide/GuideInvite';
import GuidePlay from '../pages/Guide/GuidePlay';
import GuideRecord from '../pages/Guide/GuideRecord';
import GuideStroke from '../pages/Guide/GuideStroke';
import JoinGame from '../pages/JoinGame';
import MyPage from '../pages/MyPage';
import NonMember from '../pages/NonMember';
import NoticeList from '../pages/NoticeList';
import OnBoarding from '../pages/OnBoarding';
import PlayGame from '../pages/PlayGame';
import Personal from '../pages/Policy/Personal';
import Service from '../pages/Policy/Service';
import Profile from '../pages/Profile';
import RoundRecord from '../pages/RoundRecord';
import SelectCC from '../pages/SelectCC';
import Setting from '../pages/Setting';
import Signin from '../pages/Signin';
import Signup from '../pages/Signup';
import VersionChk from '../pages/VersionChk';
import WriteScore from '../pages/WriteScore';
import Redirect from '../Redirect';
import Splash from '../Splash';

const MainRoutes: React.FC = () => {
  const routes = useRoutes([
    {
      path: '/gameshare',
      element: (
        <SocketDataContextProvider>
          <GameShare />
        </SocketDataContextProvider>
      ),
    },
    {
      path: '/draworder',
      element: (
        <SocketDataContextProvider>
          <DrawOrder />
        </SocketDataContextProvider>
      ),
    },
    {
      path: '/playgame',
      element: (
        <SocketDataContextProvider>
          <PlayGame />
        </SocketDataContextProvider>
      ),
    },
    {
      path: '/gametype',
      element: <GameType />,
    },
    {
      path: '/selectcc',
      element: <SelectCC />,
    },
    {
      path: '/creategroup',
      element: (
        <SocketDataContextProvider>
          <CreateGroup />
        </SocketDataContextProvider>
      ),
    },
    {
      path: '/joingame',
      element: <JoinGame />,
    },
    {
      path: '/nonmember',
      element: <NonMember />,
    },
    {
      path: '/signup',
      element: <Signup />,
    },
    {
      path: '/signup/2',
      element: <Signup />,
    },
    {
      path: '/clubhouse',
      element: <Clubhouse />,
    },
    {
      path: '/mypage',
      element: <MyPage />,
    },
    {
      path: '/setting',
      element: <Setting />,
    },
    {
      path: '/personal',
      element: <Personal />,
    },
    {
      path: '/service',
      element: <Service />,
    },
    {
      path: '/changepassword',
      element: <ChangePassword />,
    },
    {
      path: '/profile',
      element: <Profile />,
    },
    {
      path: '/verchk',
      element: <VersionChk />,
    },
    {
      path: '/signin',
      element: <Signin />,
    },
    {
      path: '/golfunguide',
      element: <GolfunGuide />,
    },
    {
      path: '/guidecreate',
      element: <GuideCreate />,
    },
    {
      path: '/guideinvite',
      element: <GuideInvite />,
    },
    {
      path: '/guideplay',
      element: <GuidePlay />,
    },
    {
      path: '/guideexit',
      element: <GuideExit />,
    },
    {
      path: '/guidestroke',
      element: <GuideStroke />,
    },
    {
      path: '/guidechip',
      element: <GuideChip />,
    },
    {
      path: '/guidedraw',
      element: <GuideDraw />,
    },
    {
      path: '/guiderecord',
      element: <GuideRecord />,
    },
    {
      path: '/roundrecord',
      element: <RoundRecord />,
    },
    {
      path: '/writescore',
      element: <WriteScore />,
    },
    {
      path: '/favoritecc',
      element: <FavoriteCC />,
    },
    {
      path: '/friend',
      element: <Friend />,
    },
    {
      path: '/notice/*',
      element: <NoticeList />,
    },
    {
      path: '/onboarding',
      element: <OnBoarding />,
    },
    {
      path: '/findpassword',
      element: <FindPassword />,
    },
    {
      path: '/splash',
      element: <Splash />,
    },
    {
      path: '/*',
      element: <Redirect />,
    },
  ]);

  return routes;
};

export default MainRoutes;
