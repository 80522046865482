import React, { useEffect, useState } from 'react';
import { Box, Container, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import Skip from '../assets/images/btn_skip.svg';
import order_complete from '../assets/images/order_complete.gif';
import order_swipe_b from '../assets/images/order_swipe_b.gif';
import { BottomButton } from '../components/atoms/BottomButton';
import { TopNavigation } from '../components/atoms/TopNavigation';
import { useSiteState } from '../contexts/SiteContext';
import { useSocketDataState } from '../contexts/SocketDataContext';

export default function DrawOrder() {
  const navigate = useNavigate();
  const gameCode = localStorage.getItem('room_code');
  const { data, pid, gameUpdate } = useSocketDataState();
  const { gameInfo, gameInfoChange } = useSiteState();
  const playerData = data?.participant_list || [];
  const myPlayInfo = playerData?.find(
    (item: any) => item.participant_id === pid,
  );
  const gameMetaData = data?.game_room_metadata || {};
  const gameData = gameMetaData.game_data || {};
  const drawComplete = !!gameData.drawOrder;
  const owner = pid === data?.game_room_metadata.owner_participant_id;
  const userOrder = gameData?.drawOrder || [];

  // 소켓 기본 입력 데이터
  const baseData = {
    game_room_id: gameInfo?.game_room_id,
    participant_id: myPlayInfo?.participant_id,
    user_nickname: myPlayInfo?.user_nickname,
  };

  const go = () => {
    navigate('/playgame', { replace: true });
  };

  const skip = () => {
    const gamePayload = {
      ...baseData,
      message: {
        ...gameMetaData,
        game_data: {
          ...gameData,
          drawOrder: [myPlayInfo],
        },
      },
    };
    gameUpdate(gamePayload);
  };

  const draw = () => {
    const gamePayload = {
      ...baseData,
      message: {
        ...gameMetaData,
        game_data: {
          ...gameData,
          drawOrder: data.participant_list.sort(() => Math.random() - 0.5),
        },
      },
    };
    gameUpdate(gamePayload);
  };

  useEffect(() => {
    if (!gameCode) {
      return;
    }

    if (!gameInfo) {
      const ginfo = localStorage.getItem('ri');
      restApi
        .get(`/guest/game/detail`, {
          params: {
            game_room_id: ginfo,
          },
        })
        .then((e) => {
          const { data } = e;
          if (data.status_code === '200') {
            gameInfoChange(data.result_data);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (userOrder.length === 1) navigate('/playgame');
  }, [userOrder]);

  return (
    <Container component="main">
      <TopNavigation
        type="back"
        handleEvent={() => navigate('/gameshare')}
        subTitle=""
      >
        &nbsp;
      </TopNavigation>
      {owner && !drawComplete && (
        <RightTopButton onClick={skip}>
          <img src={Skip} alt="skip" />
        </RightTopButton>
      )}
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!drawComplete ? (
          <>
            <ContentBox container>
              <SlideImgWrap>
                <img src={order_swipe_b} />
              </SlideImgWrap>
              <TextWrap>
                <Typography
                  variant="h2Bold"
                  style={{
                    display: 'block',
                    marginTop: '24px',
                    marginBottom: '24px',
                  }}
                >
                  시작하기 전에
                  <br />
                  뽑기로 순서를 정해보세요
                </Typography>
                <Typography variant="h5">
                  이번 게임의 첫타는 누구일까요?
                </Typography>
              </TextWrap>
            </ContentBox>
          </>
        ) : (
          <Page>
            <ContentBox container>
              <Typography variant="h3Bold" style={{ textAlign: 'center' }}>
                뽑기완료!
                <br />
                게임을 시작해볼가요!
              </Typography>
              <img
                src={order_complete}
                style={{
                  width: 'calc(100vw - 90px)',
                }}
                alt="img"
              />
              <OrderList>
                {userOrder.map((d: any, i: number) => (
                  <Playerlist key={i}>
                    <OrderNm>{i + 1}</OrderNm>
                    <PlayerName>{d.user_nickname}</PlayerName>
                  </Playerlist>
                ))}
              </OrderList>
            </ContentBox>
          </Page>
        )}
        {drawComplete && owner ? (
          <>
            <BottomWrap>
              <RedrawBtn variant="contained" onClick={draw}>
                다시뽑기
              </RedrawBtn>
              <StartBtn variant="contained" onClick={go}>
                게임시작
              </StartBtn>
            </BottomWrap>
          </>
        ) : (
          <BottomButton
            txt={
              owner
                ? '순서 뽑기'
                : drawComplete
                ? '게임 시작'
                : '방장이 선택중입니다'
            }
            disabled={!owner && !drawComplete}
            loc="bottom"
            handleEvent={drawComplete ? go : draw}
          />
        )}
      </Box>
    </Container>
  );
}

const RightTopButton = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
`;

const Page = styled.div`
  width: 100%;
`;

const ContentBox = styled(Grid)`
  height: 75vh;
  align-content: center;
  justify-content: center;
`;

const TextWrap = styled.div`
  width: 100%;
  text-align: center;
`;

const SlideImgWrap = styled.div`
  overflow: hidden;
  margin-right: -20px;
  margin-left: -20px;

  > img {
    //height: 200px;
  }
`;

const OrderList = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

const Playerlist = styled(Grid)`
  margin: 8px 0 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: #fafafb;
`;
const OrderNm = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin: 0 16px 0 0;
  background-color: #e0fce5;

  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 48px;
  color: #1fb04b;
`;
const PlayerName = styled.div`
  height: 19px;
  margin: 14px 0 15px 0;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #0f0f0f;
`;

const BottomWrap = styled(Grid)`
  position: fixed;
  bottom: 34px;
  max-width: 500px;
  width: 100%;
  padding: 0px 20px;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
`;
const RedrawBtn = styled(Button)`
  width: calc(50% - 4px);
  color: #fff;
  background-color: #6d7582;
  &:hover {
    background-color: rgba(109, 117, 130, 0.7);
  }
`;
const StartBtn = styled(Button)`
  width: calc(50% - 4px);
  color: #fff;
  background-color: #1fb04b;
`;
