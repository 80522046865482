import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../../apis/apiAxios';
import close from '../../assets/images/close.svg';
import IconCheck from '../../assets/images/icn_checked.svg';
import IconPlus from '../../assets/images/icn_plus.svg';
import IconSearch from '../../assets/images/icon/search_g.svg';
import MemberImg from '../../assets/images/member.png';
import { postMessage } from '../../components/blocks/CommonFn';

interface Props {
  clsView: () => void;
}

export const DimmedAddFriends: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState('');
  const [friendList, setFriendList] = useState([]);

  const searchFriends = () => {
    if (!searchString) {
      alert('닉네임을 입력해주세요.');
      return;
    }
    restApi
      .get('/user/nickname-search', { params: { nickname: searchString } })
      .then((e) => {
        const { data } = e;
        if (data.status_code === '505') {
          setFriendList([]);
        }
        if (data.status_code === '200') {
          setFriendList(data.result_data);
        }
      });
  };
  const sendInvite = (id: number, dId: any) => {
    restApi.post('/user/friend/invite', { friend_user_id: id }).then((e) => {
      const { data } = e;
      if (data.status_code === '200') {
        searchFriends();
        //친구 초대 push 발송
        JSON.parse(dId).dk.forEach((d: any) => {
          const txt = `{ "dk": "${d}", "roomCode": "0" }`;
          postMessage('addFriend', txt);
        });
        alert('친구초대가 발송 되었습니다.');
        return;
      }
    });
  };

  return (
    <Dimmed>
      <PopLayer>
        <TopArea>
          <Typography variant="h5Bold" style={{ color: '#0f0f0f' }}>
            친구추가
          </Typography>
          <ClsButton src={close} onClick={props.clsView} alt="Close" />
        </TopArea>
        <FriendListBox>
          <SearchBarWrap>
            <SearchBar
              id="search-bar"
              className="text"
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              placeholder="닉네임을 입력해주세요."
              style={{ width: '100%' }}
              value={searchString}
            />
            <img src={IconSearch} alt="icon" />
            <SearchButton onClick={searchFriends}>검색</SearchButton>
          </SearchBarWrap>
          {friendList.length === 0 && (
            <FriendList>검색된 결과가 없습니다.</FriendList>
          )}
          {friendList.map((d: any, i) => (
            <FriendList
              key={i}
              id={d.user_id}
              onClick={() =>
                d.is_friend_invite === '0' &&
                sendInvite(
                  d.user_id,
                  `{ "dk": ${JSON.stringify(d.user_device)}, "roomCode": "0" }`,
                )
              }
            >
              <ProfileImg
                src={d.user_profile || MemberImg}
                alt="Profile image"
              />
              <Typography variant="h5">{d.nickname}</Typography>
              <StatusImg
                src={d.is_friend_invite === '1' ? IconCheck : IconPlus}
                alt="Icon"
              />
            </FriendList>
          ))}
        </FriendListBox>
      </PopLayer>
    </Dimmed>
  );
};

export default DimmedAddFriends;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000a;
  z-index: 500;
`;

const PopLayer = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  height: 90vh;
  padding: 24px 20px 0;
  background-color: #fff;
  border-radius: 32px 32px 0 0;
`;

const TopArea = styled.div`
  height: 43px;
`;
const ClsButton = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const FriendListBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 96px);
  overflow: auto;
`;
const SearchBarWrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  img {
    position: absolute;
    top: 13px;
    left: 16px;
    width: 24px;
  }
`;

const SearchBar = styled.input`
  width: 100%;
  height: 50px;
  padding: 13px 16px 13px 52px;
  border: solid 1px #b1b8c0;
  border-radius: 25px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: #b1b8c0;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  right: 0;

  width: 45px;
  font-size: 16px;
  font-weight: bold;
  color: #b1b8c0;
`;

const FriendList = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  width: 100%;
  margin-bottom: 8px;
  padding: 13px 16px 11px 12px;
  border-radius: 16px;
  background-color: #fafafb;
`;
const ProfileImg = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 24px;
`;
const StatusImg = styled.img`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 44px;
`;
