import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Home from '../../assets/images/bottom_home.svg';
import MyPage from '../../assets/images/bottom_mypage.svg';
import Score from '../../assets/images/bottom_score.svg';

interface Props {
  loc: any;
}

export const BottomNavigation: React.FC<Props> = ({ loc }) => {
  const navigate = useNavigate();
  const go = (url: any) => {
    navigate(`/${url}`);
  };

  return (
    <BottomArea>
      <ButtonArea
        className={loc === 'score' ? 'active' : 'none'}
        onClick={() => go('roundrecord')}
      >
        <img src={Score} alt="Score" />
      </ButtonArea>
      <ButtonArea
        className={loc === 'clubhouse' ? 'active' : 'none'}
        onClick={() => go('clubhouse')}
      >
        <img src={Home} alt="Home" />
      </ButtonArea>
      <ButtonArea
        className={loc === 'mypage' ? 'active' : 'none'}
        onClick={() => go('mypage')}
      >
        <img src={MyPage} alt="My Page" />
      </ButtonArea>
    </BottomArea>
  );
};

export default BottomNavigation;

const BottomArea = styled.div`
  position: fixed;
  bottom: 34px;
  left: 50%;
  display: flex;
  justify-content: space-between;
  width: 280px;
  height: 72px;
  margin-left: -140px;
  padding: 4px;
  border-radius: 36px;
  backdrop-filter: blur(14.7px);
  background-color: #0002;
  z-index: 400;
`;

const ButtonArea = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 32px;

  &.active {
    background-color: #1fb04b;
  }
`;
