import React from 'react';
import { Button, Typography } from '@mui/material';
import styled from 'styled-components';
import GolineeSmile from '../../assets/images/icon/golinee.svg';
import Golinee from '../../assets/images/icon/golinee_shadow.svg';
import GolineeTear from '../../assets/images/icon/golinee_tear.svg';

interface Props {
  title: string;
  subtitle: string;
  loc?: string;
  symbol?: string;
  buttontitle: string;
  handleEvent: () => void;
  buttontitle2?: string;
  handleEvent2?: () => void;
}

export const DimmedPopup: React.FC<Props> = ({
  title,
  subtitle,
  loc,
  buttontitle,
  handleEvent,
}) => {
  return (
    <Dimmed>
      <PopupLayer className={loc === 'middle' ? 'middle' : 'none'}>
        <GolineeImg src={Golinee} alt="Golinee" />
        <TypographyStyle variant="h3Bold">{title}</TypographyStyle>
        <br />
        <TypographyStyle variant="body3">{subtitle}</TypographyStyle>
        <ButtonStyled
          variant="contained"
          fullWidth
          sx={{ m: 1 }}
          onClick={handleEvent}
        >
          {buttontitle}
        </ButtonStyled>
      </PopupLayer>
    </Dimmed>
  );
};

export const DimmedConfirm: React.FC<Props> = ({
  title,
  subtitle,
  loc,
  symbol,
  buttontitle,
  handleEvent,
  buttontitle2,
  handleEvent2,
}) => {
  return (
    <Dimmed>
      <PopupLayer className={loc === 'middle' ? 'middle' : 'none'}>
        <GolineeImg
          src={symbol === 'smile' ? GolineeSmile : GolineeTear}
          alt="Golinee"
        />
        <TypographyStyle variant="h3Bold">{title}</TypographyStyle>
        <br />
        <TypographyStyle variant="body3" style={{ color: '#505866' }}>
          {subtitle}
        </TypographyStyle>
        <ButtonBox>
          <ConfirmButtonStyled
            variant="contained"
            fullWidth
            sx={{ m: 1 }}
            onClick={handleEvent}
            className="g"
          >
            {buttontitle}
          </ConfirmButtonStyled>
          <ConfirmButtonStyled
            variant="contained"
            fullWidth
            sx={{ m: 1 }}
            onClick={handleEvent2}
          >
            {buttontitle2}
          </ConfirmButtonStyled>
        </ButtonBox>
      </PopupLayer>
    </Dimmed>
  );
};

export default DimmedPopup;

const ButtonBox = styled.div`
  display: flex;
`;

const ButtonStyled = styled(Button)`
  max-width: 352px;
  margin: 48px 21px 0;
  padding: 21px 15px;
  border-radius: 10px;

  font-size: 16px;
  font-weight: bold;
  line-height: 1;

  &.fixed {
    position: absolute;
    bottom: 34px;
  }
`;

const ConfirmButtonStyled = styled(Button)`
  max-width: 352px;
  margin: 48px 2px 0;
  padding: 21px 15px;
  border-radius: 10px;
  background-color: #e0fce5;
  color: #1fb04b;

  font-size: 16px;
  font-weight: 500;
  line-height: 1;

  &.fixed {
    position: absolute;
    bottom: 34px;
  }

  &.g {
    background-color: #fafafb;
    color: #0f0f0f;
  }
`;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000a;
  z-index: 500;
`;

const PopupLayer = styled.div`
  position: absolute;
  bottom: 0;
  max-width: 460px;
  width: 100%;
  padding: 75px 12px 34px;
  border-radius: 20px 20px 0 0;
  background-color: #fff;

  text-align: center;

  &.middle {
    max-width: 360px;
    top: 50%;
    bottom: unset;
    margin: -100px 20px 0;
    border-radius: 20px;
  }
`;

const GolineeImg = styled.img`
  position: absolute;
  left: 50%;
  margin: -130px 0 0 -36px;
  width: 72px;
`;

const TypographyStyle = styled(Typography)`
  white-space: pre-line;
`;
