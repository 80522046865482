import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import IconChips from '../assets/images/icon/chips.svg';
import IconDrawing from '../assets/images/icon/drawing.svg';
import IconNormal from '../assets/images/icon/normal.svg';
import IconStroke from '../assets/images/icon/stroke.svg';
import X from '../assets/images/x.svg';
import { BottomButton } from '../components/atoms/BottomButton';
import { TopNavigation } from '../components/atoms/TopNavigation';
import { searchParams } from '../components/blocks/CommonFn';
import { useSiteState } from '../contexts/SiteContext';
import { ChipInfoSwipe } from './Guide/GuideChip';
import { DrawInfoSwipe } from './Guide/GuideDraw';
import { StrokeInfoSwipe } from './Guide/GuideStroke';
import { IGolfList } from './SelectCC';

interface IRule {
  id: string;
  name: string;
  listid: string[];
  list: string[];
  icon?: string[];
}

interface ICreateRoomReqBody {
  golf_club_id: number | undefined;
  course_id_list: any[];
  room_title: string | undefined;
  room_content: string;
  player_count: number;
  field_type_code: string;
  game_room_group_id?: string; // Make game_room_group_id optional
}

export default function GameType() {
  const { memberInfo } = useSiteState();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [gameType, setGameType] = useState('');
  const [players, setPlayers] = useState(4);
  const [joker, setJoker] = useState('');
  const [chips, setChips] = useState('');
  const [OECD, setOECD] = useState('');
  const [birdie, setBirdie] = useState('');
  const [roundType, setRoundType] = useState('1');
  const [golfCCInfo, setGolfCCInfo] = useState<IGolfList>();
  const [selectCourse, setSelectCourse] = useState<any[]>([]);

  const [showChipInfo, setShowChipInfo] = useState(false);
  const [showDrawInfo, setShowDrawInfo] = useState(false);
  const [showStrokeInfo, setShowStrokeInfo] = useState(false);

  const location = useLocation();

  const rule: IRule[] = [
    {
      id: 'gametype',
      name: '게임타입',
      listid: ['drawing', 'chips', 'stroke', 'normal'],
      list: ['뽑기', '칩', '스트로크', '일반'],
      icon: [IconDrawing, IconChips, IconStroke, IconNormal],
    },
    {
      id: 'joker',
      name: '조커',
      listid: ['0', '1', 'excl'],
      list: ['Par', 'Bogey', '미포함'],
    },
    {
      id: 'chips',
      name: '칩',
      listid: ['7', '8'],
      list: ['7칩', '8칩(양파포함)'],
    },
    {
      id: 'oecd',
      name: 'OECD',
      listid: ['4', '5', '6', '999'],
      list: ['4Point 이상', '5Point 이상', '6Point 이상', '미적용'],
    },
    {
      id: 'birdie',
      name: 'Birdie Point',
      listid: ['1', '0'],
      list: ['적용', '미적용'],
    },
  ];

  const setItem = (id: string, lid: string) => {
    switch (id) {
      case 'gametype':
        setGameType((prevGameType) => (prevGameType === lid ? '' : lid));
        break;
      case 'joker':
        setJoker((prevJoker) => (prevJoker === lid ? '' : lid));
        break;
      case 'chips':
        setChips((prevChips) => (prevChips === lid ? '' : lid));
        break;
      case 'oecd':
        setOECD((prevOECD) => (prevOECD === lid ? '' : lid));
        break;
      case 'birdie':
        setBirdie((prevBirdie) => (prevBirdie === lid ? '' : lid));
        break;
      default:
        break;
    }
  };

  const removeExistingValue = (arr: Array<any>, value: any) => {
    return arr.filter((item) => item !== value);
  };

  const HandlerCourse = (v: any) => {
    if (selectCourse.includes(v)) {
      setSelectCourse(removeExistingValue(selectCourse, v));
    } else {
      setSelectCourse([...selectCourse, v]);
    }
  };

  const AccordionSelectList: React.FC<{
    selectItem: IRule;
    selected: string;
  }> = ({ selectItem, selected }) => {
    const itemTF = !!selectItem.icon;

    const handleAccordionItem = (lid: string, i: number) => {
      setItem(selectItem.id, lid);
    };

    return (
      <SelectItemBox
        className={selected ? 'selectedItem' : 'none'}
        style={{
          height: selected
            ? 95
            : (selectItem.list.length -
                (players === 1 && selectItem.id === 'gametype'
                  ? 3
                  : players !== 4 && selectItem.id === 'gametype'
                  ? 1
                  : 0)) *
                52 +
              45,
          backgroundColor: selected ? '#e0fce5' : '#f2f3f5',
        }}
      >
        <Typography variant="body1Bold" sx={{ marginLeft: '16px' }}>
          {selectItem.name}
        </Typography>
        {selectItem.listid.map((v: string, i: number) => {
          if (
            players === 4 ||
            (players < 4 && players !== 1 && i > 0) ||
            (players === 1 && i > 2) ||
            selectItem.id !== 'gametype'
          )
            return (
              <SelectItem
                variant="body1"
                key={v}
                onClick={() => handleAccordionItem(v, i)}
                style={{ zIndex: selected === v ? 11 : 10 }}
              >
                {itemTF && (
                  <IconBackground>
                    <img src={selectItem.icon?.[i]} alt="icon" />
                  </IconBackground>
                )}
                {selectItem.list[i]}
              </SelectItem>
            );
        })}
      </SelectItemBox>
    );
  };

  const NumberOfPlayer = () => {
    const itemElements: JSX.Element[] = [];
    for (let i = 1; i < 5; i++) {
      itemElements.push(
        <RoundTypeList
          item
          xs
          key={i}
          className={players === i ? 'on' : 'none'}
          onClick={() => setPlayers(i)}
        >
          {i}명
        </RoundTypeList>,
      );
    }

    return (
      <>
        <Typography variant="body1">플레이어 인원</Typography>
        <Grid container gap={1}>
          {itemElements}
        </Grid>
      </>
    );
  };

  const ButtonActivate = (): boolean => {
    if (page === 1) {
      return !(roundType && selectCourse.length);
    }

    if (page === 2) {
      if (gameType === 'drawing') return !(joker && OECD);
      if (gameType === 'chips') return !chips;
      if (gameType === 'stroke') return !(OECD && birdie);
      if (gameType === 'normal') return !gameType;
      return true;
    }

    return true;
  };

  const createGame = () => {
    if (location.state === 'main') {
      // 그룹 정보 초기화
      localStorage.removeItem('GGID');
      localStorage.removeItem('GGCD');
    }

    let apiURL = '/game/game-room';
    const GGID = localStorage.getItem('GGID');
    const rule = [];
    if (gameType === 'drawing') rule.push(1);
    if (gameType === 'chips') rule.push(2);
    if (gameType === 'stroke') rule.push(3);
    if (gameType === 'normal') rule.push(4);

    localStorage.removeItem('ri');
    localStorage.removeItem('room_code');
    localStorage.setItem(
      'game',
      JSON.stringify({
        gameType,
        joker,
        chips,
        OECD: gameType === 'normal' ? '999' : OECD,
        birdie,
      }),
    );
    localStorage.setItem('roundType', roundType);

    const requestBody: ICreateRoomReqBody = {
      golf_club_id: golfCCInfo?.golf_club_id,
      course_id_list: selectCourse,
      room_title: golfCCInfo?.golf_club_name,
      room_content: `${memberInfo?.nickname}님의 파티`,
      player_count: players,
      field_type_code: roundType,
    };

    if (GGID) {
      apiURL = '/game-room-group/create/game-room';
      requestBody.game_room_group_id = GGID;
    }

    restApi.post(apiURL, requestBody).then((e) => {
      const { data } = e;
      if (data.status_code === '200') {
        const roomCode = data.result_data.room_code;
        localStorage.setItem('room_code', roomCode);
        navigate('/gameshare');
      }
    });
  };

  useEffect(() => {
    const param = searchParams('g');
    if (param) {
      restApi
        .get(`/game/golf-club`, {
          params: {
            golf_club_id: param,
          },
        })
        .then((e) => {
          setGolfCCInfo(e.data.result_data);
        });
    }

    console.log('select cc > location', location);
  }, []);

  return (
    <Container component="main">
      <TopNavigation
        type="back"
        subTitle="게임 생성하기"
        handleEvent={
          page === 2 ? () => setPage(1) : () => navigate('/selectcc')
        }
      >
        {page === 1 ? '골프 유형 선택하기' : '게임모드 선택하기'}
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TopCCInfo>
          <Box
            sx={{
              display: 'inline-flex',
              flexDirection: page === 1 ? 'column' : 'inherit',
            }}
          >
            <Typography variant="h5Bold" sx={{ mr: '12px' }}>
              {golfCCInfo?.golf_club_name}
            </Typography>
            {page === 1 && (
              <HoleInfoLoc variant="body1">{golfCCInfo?.location}</HoleInfoLoc>
            )}
            {page === 2 && (
              <>
                <HoleInfo>{selectCourse.length * 9}홀</HoleInfo>
                {selectCourse.map((v, i) => {
                  const courseName = golfCCInfo?.course_list.find(
                    (course) => course.course_id === v,
                  )?.course_name;
                  return (
                    <HoleInfo key={v} className={i === 0 ? 'front' : 'back'}>
                      {courseName}
                    </HoleInfo>
                  );
                })}
              </>
            )}
          </Box>
        </TopCCInfo>

        {page === 1 ? (
          <Box sx={{ width: '100%' }}>
            <Typography variant="body1">라운드 유형</Typography>
            <Grid container gap={1}>
              <RoundTypeList
                item
                xs
                className={roundType === '1' ? 'on' : 'none'}
                onClick={() => setRoundType('1')}
              >
                필드
              </RoundTypeList>
              <RoundTypeList
                item
                xs
                className={roundType === '2' ? 'on' : 'none'}
                onClick={() => setRoundType('2')}
              >
                스크린 골프
              </RoundTypeList>
            </Grid>
            <Typography variant="body1">코스 선택</Typography>
            <Box>
              {golfCCInfo?.course_list.map((list: any, i: number) => {
                return (
                  <CourseListItem
                    key={list.course_id}
                    onClick={() => HandlerCourse(list.course_id)}
                    className={
                      selectCourse[0] === list.course_id
                        ? 'front'
                        : selectCourse[1] === list.course_id
                        ? 'back'
                        : 'none'
                    }
                  >
                    {selectCourse[0] === list.course_id ? (
                      <span>전반</span>
                    ) : selectCourse[1] === list.course_id ? (
                      <span>후반</span>
                    ) : (
                      <></>
                    )}
                    {list.course_name} 코스
                  </CourseListItem>
                );
              })}
            </Box>
            <NumberOfPlayer />
          </Box>
        ) : (
          <Grid container gap={1}>
            {rule[0] && (
              <Grid item xs={5.8}>
                <AccordionSelectList selectItem={rule[0]} selected={gameType} />
              </Grid>
            )}
            {gameType === 'drawing' && rule[1] && (
              <Grid item xs={5.8}>
                <AccordionSelectList selectItem={rule[1]} selected={joker} />
              </Grid>
            )}
            {gameType === 'chips' && rule[2] && (
              <Grid item xs={5.8}>
                <AccordionSelectList selectItem={rule[2]} selected={chips} />
              </Grid>
            )}
            {((gameType === 'drawing' && joker) || gameType === 'stroke') &&
              rule[3] && (
                <Grid item xs={5.8}>
                  <AccordionSelectList selectItem={rule[3]} selected={OECD} />
                </Grid>
              )}
            {gameType === 'stroke' && OECD && rule[3] && (
              <Grid item xs={5.8}>
                <AccordionSelectList selectItem={rule[4]} selected={birdie} />
              </Grid>
            )}
          </Grid>
        )}

        {(showChipInfo || showDrawInfo || showStrokeInfo) && (
          <Dimmed>
            <DimmedPopClose
              onClick={() => {
                setShowChipInfo(false);
                setShowDrawInfo(false);
                setShowStrokeInfo(false);
              }}
            >
              <img src={X} alt="close" />
            </DimmedPopClose>
            <InfoArea>
              {showChipInfo && ChipInfoSwipe()}
              {showDrawInfo && DrawInfoSwipe()}
              {showStrokeInfo && StrokeInfoSwipe()}
            </InfoArea>
          </Dimmed>
        )}

        {gameType !== '' && (
          <GameInfoStyle>
            {gameType === 'drawing' && (
              <div>
                <InfoTopStyle>
                  <IconBackground className="info">
                    <img src={IconDrawing} alt="icon" />
                  </IconBackground>
                  <InfoTitleStyle>뽑기</InfoTitleStyle>
                  <GmaeGuideStyle onClick={() => setShowDrawInfo(true)}>
                    가이드 보기
                  </GmaeGuideStyle>
                </InfoTopStyle>
                <InfoBodyStyle>
                  매 홀 마다 전원 홀 아웃시 뽑기를 통해 팀을 선정해서
                  계산합니다. <br />
                  조커 포함일 경우 팀에 조커가 포함 될 수 있으며, 조커는 실제
                  타수와 상관없이 설정한 타수로 계산됩니다 (파,보기)
                  <br />
                  <br />
                  점수 계산은 합산 타수가 작은(승리)팀이 은행에서 1포인트
                  획득하며 양팀의 타수가 동점일 경우 다음판은 배판이 됩니다.
                </InfoBodyStyle>
              </div>
            )}
            {gameType === 'chips' && (
              <div>
                <InfoTopStyle>
                  <IconBackground className="info">
                    <img src={IconChips} alt="icon" />
                  </IconBackground>
                  <InfoTitleStyle>칩</InfoTitleStyle>
                  <GmaeGuideStyle onClick={() => setShowChipInfo(true)}>
                    가이드 보기
                  </GmaeGuideStyle>
                </InfoTopStyle>
                <InfoBodyStyle>
                  전반 진행과 후반 진행전에 임의로 공평하게 칩이 분배됩니다.
                  <br />
                  <br />
                  칩에 해당하는 행동을 한 사람은 칩을 건네받으며 9홀 기준으로
                  본인이 가지고 있는 칩 갯수만큼 은행에서 1포인트 적립합니다.
                </InfoBodyStyle>
              </div>
            )}
            {gameType === 'stroke' && (
              <div>
                <InfoTopStyle>
                  <IconBackground className="info">
                    <img src={IconStroke} alt="icon" />
                  </IconBackground>
                  <InfoTitleStyle>스트로크</InfoTitleStyle>
                  <GmaeGuideStyle onClick={() => setShowStrokeInfo(true)}>
                    가이드 보기
                  </GmaeGuideStyle>
                </InfoTopStyle>
                <InfoBodyStyle>
                  스트로크는 개인플레이 게임이며, 기본적으로 모든 선수가 홀
                  아웃을 하면 각 플레이어 간 타수 차이만큼 포인트가 변동됩니다.
                  <br />
                  <br />
                  스트로크 게임은 플레이어의 점수에 따라 게임이 배판으로
                  적용되는 경우가 있으며, 배수가 누적 적용될 수도 있으며, 옵션을
                  통해 OECD 모드를 활성화 시킬 수 있습니다.
                </InfoBodyStyle>
              </div>
            )}
            {gameType === 'normal' && (
              <div>
                <InfoTopStyle>
                  <IconBackground className="info">
                    <img src={IconNormal} alt="icon" />
                  </IconBackground>
                  <InfoTitleStyle>일반</InfoTitleStyle>
                  <GmaeGuideStyle onClick={() => setShowStrokeInfo(true)}>
                    가이드 보기
                  </GmaeGuideStyle>
                </InfoTopStyle>
                <InfoBodyStyle>
                  일반 게임은 다른 옵션 없이 오직 기록을 위한 게임입니다.
                </InfoBodyStyle>
              </div>
            )}
          </GameInfoStyle>
        )}

        <BottomButton
          txt={page === 1 ? '다음' : '게임생성'}
          disabled={ButtonActivate()}
          handleEvent={page === 1 ? () => setPage(2) : createGame}
          loc="bottom"
        />
      </Box>
    </Container>
  );
}

const TopCCInfo = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  margin-right: 8px;
  padding: 14px 24px;
  border-radius: 31px;
  background-color: #fafafb;
`;

const HoleInfoLoc = styled(Typography)`
  color: #505866;
`;

const HoleInfo = styled.div`
  width: fit-content;
  padding: 2px 4px 1px;
  border-radius: 8px;
  background-color: #e0fce5;

  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #1fb04b;

  &.back {
    color: #3162d2;
    background-color: #e0ebfc;
  }
`;

const RoundTypeList = styled(Grid)`
  margin: 8px 0;
  padding: 10px;
  border-radius: 8px;
  background-color: #e7e9ec;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.01px;
  color: #505866;

  &.on {
    background-color: #e0fce5;
    font-weight: bold;
    color: #1fb04b;
  }
`;

const CourseListItem = styled.div`
  position: relative;
  margin: 8px 0;
  padding: 13px;
  border-radius: 8px;
  background-color: #e7e9ec;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.01px;
  line-height: 1;
  color: #505866;

  span {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
  }

  &.front {
    color: #1fb04b;
    background-color: #e0fce5;

    span {
      background-color: #1fb04b;
    }
  }

  &.back {
    color: #3162d2;
    background-color: #e0ebfc;

    span {
      background-color: #3162d2;
    }
  }
`;

const SelectItemBox = styled.div`
  position: relative;
  width: 100%;
  min-height: 74px;
  padding: 12px 0;
  margin-bottom: 8px;
  border-radius: 24px;
  transition: all 300ms ease-out;

  &.selectedItem > .MuiTypography-body1 {
    top: 35px;
  }
`;

const SelectItem = styled(Typography)`
  position: absolute;
  top: 35px;
  left: 12px;
  display: inline-flex;
  align-items: center;
  width: calc(100% - 32px);
  height: 44px;
  margin: 4px;
  padding: 4px;
  border-radius: 22px;
  background-color: #fff;
  transition: all 300ms ease-out;
  cursor: pointer;

  &:not(:has(img)) {
    justify-content: center;
  }

  &:nth-child(3) {
    top: 87px;
  }
  &:nth-child(4) {
    top: 139px;
  }
  &:nth-child(5) {
    top: 191px;
  }
  &:nth-child(6) {
    top: 243px;
  }
`;

const IconBackground = styled.span`
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  border-radius: 18px;
  background-color: #fafafb;

  &.info {
    background-color: #fff;
  }
`;

const GameInfoStyle = styled.div`
  position: relative;
  //bottom: 130px;
  width: 100%;

  margin-top: 50px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #fafafb;
`;

const InfoTopStyle = styled.div``;
const InfoBodyStyle = styled.div`
  margin-top: 16px;

  font-size: 13px;
  font-weight: 500;
  color: #0f0f0f;
`;

const InfoTitleStyle = styled.span`
  font-size: 14px;
  font-weight: bold;
  vertical-align: text-bottom;
  color: #0f0f0f;
`;

const GmaeGuideStyle = styled.div`
  position: absolute;
  width: 72px;
  border-radius: 8px;
  background-color: #e7e9ec;

  top: 10px;
  right: 12px;

  font-size: 12px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #151719;
`;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000a;
  z-index: 500;
`;

const DimmedPopClose = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  height: 30px;
  padding: 3px;
  border-radius: 15px;
  background-color: #fff;
`;

const InfoArea = styled.div`
  width: 80%;
  padding-top: 20px;
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
`;
