import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import restApi from '../apis/apiAxios';
import { useSiteState } from './SiteContext';

interface Data {
  game_room_metadata: any;
  participant_list: any;
  kick_out_participant_id?: number;
  game_room_list?: any;
  delete_game_room_group_id?: number;
  delete_game_room_id_list?: any;
  group_name?: any;
}

interface IHoleInfo {
  hole_id: number;
  shot: number;
  putt: number | null;
}

interface ISocketContext {
  data: any;
  groupData: any;
  pid: number | null;
  sendShotCount: (holeinfo: {
    game_data?: { point: number };
    hole_id_list: { putt: number | null; hole_id: any; shot: number }[];
  }) => void;
  resetChips: () => void;
  sendChip: (sendPlayer: number, player: number, chips: number) => void;
  shuffleStick: () => void;
  playerUpdate: (data: any) => void;
  gameUpdate: (data: any) => void;
  webSocketClose: () => void;
  socketDisconnect: any;
  groupSocketDisconnect: any;
  groupSocketClose: any;
  groupDataRequest: () => void;
  sendEjectPlayer: (data: any) => void;
  AddNonePlayer: (playerID: number, nickname: string) => void;
  detachRoom: (id: number) => void;
}

const defaultContext: ISocketContext = {
  data: null,
  groupData: null,
  pid: null,
  sendShotCount: () => {},
  resetChips: () => {},
  sendChip: () => {},
  shuffleStick: () => {},
  playerUpdate: () => {},
  gameUpdate: () => {},
  webSocketClose: () => {},
  socketDisconnect: () => {},
  groupSocketDisconnect: () => {},
  groupSocketClose: () => {},
  groupDataRequest: () => {},
  sendEjectPlayer: () => {},
  AddNonePlayer: () => {},
  detachRoom: () => {},
};

const SocketDataContext = React.createContext(defaultContext);

interface Props {
  children: JSX.Element | JSX.Element[];
}

const SocketDataContextProvider: React.FC<Props> = ({ children }) => {
  const [data, setData] = useState<Data | null>(null);
  const [groupData, setGroupData] = useState<Data | null>(null);
  const [pid, setPid] = useState(0);
  const { memberInfo, gameInfo } = useSiteState();
  const navigate = useNavigate();
  const rule = JSON.parse(localStorage.getItem('game') as string) || '';
  const roomId = Number(localStorage.getItem('ri'));
  const GGID = localStorage.getItem('GGID');
  const owner = memberInfo?.user_id === gameInfo?.owner_user_id;
  const myPlayInfo = data?.participant_list?.find(
    (item: any) => item.participant_id === pid,
  );

  const ws = useRef<ReconnectingWebSocket | null>(null);
  const gws = useRef<ReconnectingWebSocket | null>(null);

  const dataRequest = () => {
    console.log('data request', !!ws.current, pid);
    if (!ws.current || !pid) {
      return;
    }

    console.log('set data');
    setData(null);

    const modifiedData = gameInfo?.course_id_list?.map(
      (course: { hole_list: { hole_id: number; par: number }[] }) => {
        const modifiedHoleList = course.hole_list.map(({ hole_id, par }) => ({
          hole_id,
          par,
        }));
        return { ...course, hole_list: modifiedHoleList };
      },
    );

    ws.current.send(
      JSON.stringify({
        game_room_id: roomId,
        participant_id: pid,
        user_nickname: memberInfo?.nickname,
        message: {
          game_room_id: roomId,
          game_start_datetime: new Date().toISOString(),
          owner_user_id: gameInfo?.owner_user_id,
          owner_participant_id: owner && pid,
          title: gameInfo?.room_title,
          content: '게임방 내용입니다.',
          max_player_count: gameInfo?.player_count,
          now_participant_count: 4,
          cc_id: gameInfo?.golf_club_id,
          cc_name: gameInfo?.room_title,
          hole_list: modifiedData?.flatMap((item: any) => item.hole_list),
          profile_image_file_url: memberInfo?.profile_image_url,
          game_data: {
            bank: rule.gameType === 'drawing' ? 40 : 0,
            allsquare: 1,
            round_type: localStorage.getItem('roundType'),
            ...rule,
          },
        },
        chat_type_code: '1',
      }),
    );
  };

  const AddNonePlayer = (playerID: number, nickname: string) => {
    if (!ws.current || !playerID) {
      return;
    }

    const modifiedData = gameInfo?.course_id_list?.map(
      (course: { hole_list: { hole_id: number; par: number }[] }) => {
        const modifiedHoleList = course.hole_list.map(({ hole_id, par }) => ({
          hole_id,
          par,
        }));
        return { ...course, hole_list: modifiedHoleList };
      },
    );

    ws.current.send(
      JSON.stringify({
        game_room_id: roomId,
        participant_id: playerID,
        user_nickname: nickname,
        message: {
          game_room_id: roomId,
          game_start_datetime: new Date().toISOString(),
          owner_user_id: gameInfo?.owner_user_id,
          cc_id: gameInfo?.golf_club_id,
          cc_name: gameInfo?.room_title,
          hole_list: modifiedData?.flatMap((item: any) => item.hole_list),
          profile_image_file_url: null,
          game_data: {
            bank: rule.gameType === 'drawing' ? 40 : 0,
            allsquare: 1,
            round_type: localStorage.getItem('roundType'),
            ...rule,
          },
        },
        chat_type_code: '1',
      }),
    );
  };

  const sendShotCount = (holeinfo: any) => {
    if (!ws.current) {
      return;
    }

    ws.current.send(
      JSON.stringify({
        game_room_id: roomId,
        participant_id: pid,
        user_nickname: memberInfo?.nickname,
        message: {
          user_nickname: memberInfo?.nickname,
          game_room_id: roomId,
          participant_id: pid,
          ...holeinfo,
        },
        chat_type_code: '3',
      }),
    );
  };

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const shuffleStick = () => {
    const teamArray: { team: number | undefined; view: number }[][] = [
      [],
      [],
      [],
      [],
    ];

    for (let i = 0; i < 18; i++) {
      const setStick =
        data?.game_room_metadata.game_data.joker === 'excl'
          ? [1, 1, 2, 2]
          : [1, 1, 2, 2, 3];
      shuffleArray(setStick);
      for (let n = 0; n < 4; n++) {
        const team = [setStick.shift()];
        teamArray[n].push({ team: team[0], view: 0 });
      }
    }

    data?.participant_list.forEach((player: any, idx: number) => {
      if (!ws.current) {
        return;
      }

      const gameData = {
        ...player.game_data,
        draw: teamArray[idx],
      };

      const message = {
        ...player,
        game_data: gameData,
      };

      const payload = {
        game_room_id: roomId,
        participant_id: player.participant_id,
        user_nickname: player.user_nickname,
        message: message,
      };

      playerUpdate(payload);
    });
  };

  const gameUpdate = (data: any) => {
    if (!ws.current) {
      return;
    }

    const payload = {
      ...data,
      chat_type_code: '2',
    };

    ws.current.send(JSON.stringify(payload));
  };

  const playerUpdate = (data: any) => {
    if (!ws.current) {
      return;
    }

    const payload = {
      ...data,
      chat_type_code: '3',
    };

    ws.current.send(JSON.stringify(payload));
  };

  const sendEjectPlayer = (data: any) => {
    if (!ws.current) {
      return;
    }

    const payload = {
      ...data,
      chat_type_code: '6',
    };

    ws.current.send(JSON.stringify(payload));
  };

  const resetChips = () => {
    const playerCnt = data?.participant_list.length;
    let setChips;
    let loopCnt;

    if (playerCnt === 3) {
      loopCnt = 3;
      setChips =
        data?.game_room_metadata.game_data.chips === '7'
          ? [1, 2, 3, 4, 5, 6, 7, 0, 0]
          : [1, 2, 3, 4, 5, 6, 7, 8, 0];
    } else {
      loopCnt = playerCnt === 4 ? 2 : 4;
      setChips =
        data?.game_room_metadata.game_data.chips === '7'
          ? [1, 2, 3, 4, 5, 6, 7, 0]
          : [1, 2, 3, 4, 5, 6, 7, 8];
    }

    shuffleArray(setChips);

    data?.participant_list.forEach((player: any) => {
      if (!ws.current) {
        return;
      }

      let preChips: any[] = [];
      for (let i = 1; i <= loopCnt; i++) {
        preChips = [...preChips, setChips.shift()];
      }

      const filteredArray = preChips.filter((chip: any) => chip !== 0);

      const gameData = {
        ...player.game_data,
        chips: filteredArray,
      };

      const message = {
        game_room_id: roomId,
        hole_id_list: player.hole_id_list,
        participant_id: player.participant_id,
        user_nickname: player.user_nickname,
        game_data: gameData,
      };

      const payload = {
        game_room_id: roomId,
        participant_id: player.participant_id,
        user_nickname: player.user_nickname,
        message: message,
        chat_type_code: '3',
      };

      ws.current.send(JSON.stringify(payload));
    });
  };

  const sendChip = (sendPId: number, participantId: number, chip: number) => {
    if (!ws.current) {
      return;
    }

    const playersData = [
      data?.participant_list.find(
        (item: any) => item.participant_id === sendPId,
      ),
      data?.participant_list.find(
        (item: any) => item.participant_id === participantId,
      ),
    ];

    playersData.forEach((player: any, i: number) => {
      if (!ws.current) {
        return;
      }

      if (i === 0) {
        player.game_data.chips = player.game_data.chips.filter(
          (n: number) => n !== chip,
        );
      } else {
        player.game_data.chips = [...player.game_data.chips, chip];
      }

      const message = {
        game_room_id: roomId,
        hole_id_list: player.hole_id_list,
        participant_id: player.participant_id,
        user_nickname: player.user_nickname,
        game_data: player.game_data,
      };

      const payload = {
        game_room_id: roomId,
        participant_id: player.participant_id,
        user_nickname: player.user_nickname,
        message: message,
        chat_type_code: '3',
      };

      ws.current.send(JSON.stringify(payload));
    });
  };

  const webSocketClose = () => {
    if (!ws.current) {
      return;
    }

    const payload = {
      game_room_id: roomId,
      participant_id: pid,
      user_nickname: memberInfo?.nickname,
      message: '',
      chat_type_code: '5',
    };
    ws.current.send(JSON.stringify(payload));
  };

  const webSocketConnect = () => {
    console.log('webSocketConnect > ', roomId, !roomId, pid, !pid);
    if (!pid) {
      return;
    }

    const webSocketUrl = `${process.env.REACT_APP_SOCKET_URL}/game-room/${roomId}`;

    //if (ws.current) {
    //  return;
    //}

    ws.current = new ReconnectingWebSocket(webSocketUrl);
    ws.current.onopen = () => {
      dataRequest();
    };

    ws.current.onmessage = (event: { data: string }) => {
      //const newData = JSON.parse(event.data);
      if (event.data !== 'pong') {
        const newData: Data = JSON.parse(event.data);
        console.log('pid = ', pid);
        if (newData.participant_list.length > 0) {
          if (newData?.kick_out_participant_id === pid) {
            localStorage.removeItem('room_code');
            localStorage.removeItem('ri');
            navigate('/clubhouse');
          }

          setData((prevData) => {
            return {
              ...prevData,
              ...newData,
            };
          });
        }
      }
    };

    setInterval(() => {
      if (ws.current?.readyState === 1) {
        ws.current?.send('ping');
      }
    }, 30000);

    ws.current.onerror = (error) => console.error('ws..onerror = ', error);
    ws.current.onclose = (error) => console.error('ws..onclose = ', error);
  };

  const groupDataRequest = () => {
    if (!gws.current) {
      return;
    }

    gws.current.send(
      JSON.stringify({
        game_room_group_id: Number(GGID),
        user_id: memberInfo?.user_id,
        message: {
          //game_room_id: Number(localStorage.getItem('ri')),
        },
        chat_type_code: '1',
      }),
    );
  };

  const groupSocketClose = () => {
    if (!gws.current) {
      return;
    }

    const payload = {
      game_room_group_id: Number(GGID),
      user_id: memberInfo?.user_id,
      message: {
        //game_room_id: Number(localStorage.getItem('ri')),
      },
      chat_type_code: '3',
    };
    gws.current.send(JSON.stringify(payload));
  };

  const detachRoom = (id: any) => {
    if (!gws.current) {
      return;
    }

    const payload = {
      game_room_group_id: Number(GGID),
      user_id: memberInfo?.user_id,
      message: {
        game_room_id: id,
      },
      chat_type_code: '2',
    };
    gws.current.send(JSON.stringify(payload));
  };

  const webSocketGroupConnect = () => {
    console.log('webSocketGroupConnect > ', GGID, roomId);
    if (!GGID || roomId) {
      return;
    }

    const webSocketUrl = `${process.env.REACT_APP_SOCKET_URL}/game-room-group/${GGID}`;

    //if (gws.current) {
    //  return;
    //}

    gws.current = new ReconnectingWebSocket(webSocketUrl);
    gws.current.onopen = () => {
      groupDataRequest();
    };

    gws.current.onmessage = (event: { data: string }) => {
      //const newData = JSON.parse(event.data);
      if (event.data !== 'pong') {
        const newData: Data = JSON.parse(event.data);

        console.log('groupgame socket : ', newData);

        localStorage.setItem('GGND', newData.group_name);

        if (newData?.delete_game_room_id_list) {
          alert('그룹이 해산되었습니다.\n메인 페이지로 이동합니다.');
          localStorage.removeItem('GGID');
          localStorage.removeItem('GGCD');
          localStorage.removeItem('GGCU');
          navigate('/clubhouse');
        }

        if (newData) {
          setGroupData((prevData) => {
            return {
              ...prevData,
              ...newData,
            };
          });
        }
      }
    };

    setInterval(() => {
      if (gws.current?.readyState === 1) {
        gws.current?.send('ping');
      }
    }, 30000);

    gws.current.onerror = (error) => console.error(error);
    gws.current.onclose = (error) => console.error(error);
  };

  const socketDisconnect = () => {
    console.log('socketDisconnect > ', ws.current);
    //setPid(0);
    ws.current?.close();
  };

  const groupSocketDisconnect = () => {
    console.log('groupSocketDisconnect > ', gws.current);
    gws.current?.close();
  };

  useEffect(() => {
    if (!memberInfo || !gameInfo) {
      return;
    }

    if (roomId) {
      restApi
        .post('/game/join', {
          game_room_id: roomId,
          is_player: '1',
          player_nickname: memberInfo?.nickname,
        })
        .then((e) => {
          if (e.data.status_code === '505') {
            alert('요청하신 방을 찾을 수 없습니다.\n다시 확인해주세요');
            navigate('/clubhouse');
            return;
          }

          if (!localStorage.getItem('accessToken')) {
            const nonMeberPid =
              Number(localStorage.getItem('nmPid')) ||
              Math.floor(Math.random() * 90) + 100;
            localStorage.setItem('nmPid', nonMeberPid.toString());
            localStorage.setItem(
              'nickname',
              localStorage.getItem('nickname') || memberInfo?.nickname || '',
            );
            console.log('nonMeberPid = ', nonMeberPid);
            setPid(nonMeberPid);
          } else {
            setPid(e.data.result_data.participant_id);
          }
        });
    }
  }, [memberInfo, gameInfo]);

  const createWebSocketConnection = (
    socket: any,
    connectFunction: any,
    dataFunction: any,
  ) => {
    if (!socket.current || socket.current?.readyState !== 1) {
      connectFunction();
    } else {
      dataFunction();
    }

    return () => {
      socket.current?.close();
    };
  };

  useEffect(() => {
    if (!memberInfo) {
      return;
    }

    return createWebSocketConnection(
      gws,
      webSocketGroupConnect,
      groupDataRequest,
    );
  }, [memberInfo, gameInfo, pid]);

  useEffect(() => {
    if (!memberInfo) {
      return;
    }

    return createWebSocketConnection(ws, webSocketConnect, dataRequest);
  }, [memberInfo, gameInfo, pid]);

  useEffect(() => {
    console.log('pid chg > ', pid);
  }, [pid]);

  return (
    <SocketDataContext.Provider
      value={{
        data,
        groupData,
        pid,
        sendShotCount,
        resetChips,
        sendChip,
        shuffleStick,
        playerUpdate,
        gameUpdate,
        webSocketClose,
        socketDisconnect,
        groupSocketDisconnect,
        groupSocketClose,
        groupDataRequest,
        sendEjectPlayer,
        AddNonePlayer,
        detachRoom,
      }}
    >
      {children}
    </SocketDataContext.Provider>
  );
};

const useSocketDataState = (): ISocketContext => useContext(SocketDataContext);

export { SocketDataContextProvider, SocketDataContext, useSocketDataState };
