import React, { Fragment, useEffect, useState } from 'react';
import { Box, Container, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import close from '../assets/images/close.svg';
import ImgNoneGame from '../assets/images/funny_brothers.svg';
import IcnGameType from '../assets/images/icn_game_type.svg';
import IcnKebap from '../assets/images/icn_kebap.svg';
import IcnRank from '../assets/images/icn_ranking.svg';
import { DimmedConfirm } from '../components/atoms/DimmedPopup';
import TopNavigation from '../components/atoms/TopNavigation';
import { useSiteState } from '../contexts/SiteContext';
import { useSocketDataState } from '../contexts/SocketDataContext';
import { FriendList } from './Friends/FriendList';
import DimmedRankingView from './Group/DimmedRankingView';

export default function CreateGroup() {
  const navigate = useNavigate();
  const [gameList, setGameList] = useState([]);
  const [friendList, setFriendList] = useState(false);
  const groupCode = localStorage.getItem('GGCD') || '0';
  const gameID = Number(localStorage.getItem('GGID')) || 0;
  const [joinPopView, setJoinPopView] = useState(false);
  const [joinPartyName, setJoinPartyName] = useState('');
  const [groupDeletePopView, setGroupDeletePopView] = useState(false);
  const [partyCode, setPartyCode] = useState('');
  const [bottomPopMenu, setBottomPopMenu] = useState(false);
  const [editGroupName, setEditGroupName] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [ejectGroupPop, setEjectGroupPop] = useState(false);
  const [rankView, setRankView] = useState(false);
  const [detachPopView, setDetachPopView] = useState(false);
  const { groupData, groupSocketClose, groupDataRequest, detachRoom } =
    useSocketDataState();
  const [roomID, setRoomID] = useState(0);
  const { memberInfo } = useSiteState();
  const userID = memberInfo?.user_id;
  const groupOwner = groupData?.group_owner_user_id === userID;

  const MemberListLayout = (obj: any) => {
    const baseUsers = [1, 2, 3, 4];
    const { users, max } = obj;
    const empty = max - users.length;

    return (
      <>
        {Array.isArray(users) &&
          users.map((v: any, i: number) => {
            return (
              <Member key={i}>
                {v.is_owner && <Manager>방장</Manager>}
                {v.player_nickname}
              </Member>
            );
          })}

        {baseUsers.map((v: any, i: number) => {
          return i < empty ? (
            <Member key={i} className="blank" />
          ) : (
            <Fragment key={i}></Fragment>
          );
        })}
      </>
    );
  };

  const groupJoinPop = (rc: any, rn: string, fp: number, pc: number) => {
    console.log('groupJoinPop > ', rc);
    setJoinPopView(true);
    setJoinPartyName(rn);
    setPartyCode(rc);
  };

  const groupJoin = () => {
    console.log('groupJoin');
    restApi
      .get(`/guest/check-room-code`, {
        params: {
          room_code: partyCode,
        },
      })
      .then((e) => {
        if (e.data.status_code === '200') {
          localStorage.setItem('room_code', partyCode);
          localStorage.setItem('ri', e.data.result_data);
          navigate('/gameshare');
        } else {
          localStorage.removeItem('room_code');
          localStorage.removeItem('ri');
          if (e.data.status_code === '505') {
            alert('파티 정보가 없습니다. 파티정보를 확인해주세요.');
          } else {
            alert(e.data.status_message);
          }
        }
      });
  };

  const EditGroupName = () => {
    restApi
      .put('/game-room-group/update', {
        game_room_group_id: groupData?.game_room_group_id,
        group_name: groupName,
      })
      .then((e) => {
        console.log(e);
        const { data } = e;
        if (data.status_code === '200') {
          alert('그룹이름 변경이 완료 되었습니다.');
          groupDataRequest();
          setEditGroupName(false);
          return;
        }
      });
  };

  const ejectGroup = () => {
    localStorage.removeItem('GGID');
    localStorage.removeItem('GGCD');
    localStorage.removeItem('GGND');
    setEjectGroupPop(false);
    navigate('/clubhouse');
  };

  const RankingView = () => setRankView(true);

  return (
    <Container component="main">
      <TopNavigation
        type="close"
        subTitle="그룹게임"
        handleEvent={() => navigate('/clubhouse')}
      >
        {groupData?.group_name || '그룹 게임'}
      </TopNavigation>
      <RightTopButton
        onClick={() => {
          setBottomPopMenu(true);
          console.log(groupOwner, groupData, userID);
        }}
      >
        <img src={IcnKebap} alt="skip" />
      </RightTopButton>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!groupData || groupData?.game_room_list.length === 0 ? (
          <GameNone>
            <Typography variant="h3Bold">생성된 게임이 없네요!</Typography>
            <Typography
              variant="h5"
              style={{ color: '#b1b8c0', margin: '4px 0 32px' }}
            >
              게임을 생성하거나 그룹을 초대해보세요!
            </Typography>
            <img src={ImgNoneGame} alt="img" />
          </GameNone>
        ) : (
          <>
            {groupData?.game_room_list.map((item: any, i: number) => (
              <GameList
                key={i}
                onClick={(e) => {
                  e.stopPropagation();
                  groupJoinPop(
                    item.room_code,
                    item.room_content,
                    item.participant_list.length,
                    item.player_count,
                  );
                }}
              >
                <GameParty>
                  <TitleArea>
                    <ImgStyled>
                      <img src={IcnGameType} alt="Icon" />
                    </ImgStyled>
                    <TextWrap>
                      <PartyName variant="h5Bold">
                        {item.room_content}
                      </PartyName>
                      <PartyLoc variant="body1">{item.room_title}</PartyLoc>
                    </TextWrap>
                    <PartyStats variant="body1">
                      {item.participant_list.length}/{item.player_count}
                    </PartyStats>
                  </TitleArea>
                  {groupOwner && (
                    <DetachBtn
                      src={close}
                      alt="icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setRoomID(item.game_room_id);
                        setDetachPopView(true);
                      }}
                    />
                  )}
                  <MemberList>
                    <MemberListLayout
                      users={item.participant_list}
                      max={item.player_count}
                    />
                  </MemberList>
                </GameParty>
              </GameList>
            ))}
          </>
        )}
        <BottomWrap>
          <GrayBtn
            className="double"
            variant="contained"
            onClick={() => setFriendList(true)}
          >
            초대하기
          </GrayBtn>
          <GreenBtn
            className="double"
            variant="contained"
            onClick={() => navigate('/selectcc')}
          >
            게임 생성하기
          </GreenBtn>
          <RankBtn>
            <img onClick={() => RankingView()} src={IcnRank} alt="ranking" />
          </RankBtn>
        </BottomWrap>
        {friendList && groupCode && (
          <FriendList
            clsView={() => setFriendList(false)}
            gameCode={groupCode}
            gameID={gameID}
          />
        )}
      </Box>
      {joinPopView && (
        <DimmedConfirm
          title={`${joinPartyName}에 참여 하겠습니까?`}
          symbol="smile"
          loc="middle"
          subtitle=""
          buttontitle={'취소하기'}
          handleEvent={() => setJoinPopView(false)}
          buttontitle2={'참여하기'}
          handleEvent2={() => groupJoin()}
        />
      )}
      {detachPopView && (
        <DimmedConfirm
          title={`정말로 게임방을 제외하시겠어요?`}
          symbol="tear"
          loc="middle"
          subtitle=""
          buttontitle={'취소하기'}
          handleEvent={() => setDetachPopView(false)}
          buttontitle2={'제외하기'}
          handleEvent2={() => detachRoom(roomID)}
        />
      )}
      {groupDeletePopView && (
        <DimmedConfirm
          title={`정말로 그룹을 해산하시겠어요?`}
          symbol="tear"
          loc="middle"
          subtitle=""
          buttontitle={'취소하기'}
          handleEvent={() => setGroupDeletePopView(false)}
          buttontitle2={'해산하기'}
          handleEvent2={() => groupSocketClose()}
        />
      )}
      {ejectGroupPop && (
        <DimmedConfirm
          title="그룹 떠나기"
          subtitle={`그룹을 떠날경우 다시 접속하기 위해서는\n초대를 다시 받아야 합니다.\n정말로 떠나시겠습니까?`}
          loc="middle"
          symbol="crying"
          buttontitle="취소"
          handleEvent={() => setEjectGroupPop(false)}
          buttontitle2="나가기"
          handleEvent2={() => ejectGroup()}
        />
      )}
      {bottomPopMenu && (
        <Dimmed>
          <BottomPopArea>
            {groupOwner ? (
              <>
                <TopArea>
                  <Typography variant="h5Bold" style={{ color: '#0f0f0f' }}>
                    그룹설정
                  </Typography>
                  <ClsButton
                    src={close}
                    onClick={() => setBottomPopMenu(false)}
                    alt="Close"
                  />
                </TopArea>
                <BottomMenuList
                  onClick={() => {
                    setBottomPopMenu(false);
                    setEditGroupName(true);
                  }}
                >
                  그룹 이름 수정
                </BottomMenuList>
                <BottomMenuList
                  style={{ color: '#c13537' }}
                  onClick={() => {
                    setBottomPopMenu(false);
                    setGroupDeletePopView(true);
                  }}
                >
                  그룹 해산하기
                </BottomMenuList>
              </>
            ) : (
              <>
                <TopArea>
                  <Typography variant="h5" style={{ color: '#0f0f0f' }}>
                    그룹에서 나가게 됩니다.
                  </Typography>
                </TopArea>
                <GroupExitPop>
                  <ExitButton
                    onClick={() => {
                      setEjectGroupPop(true);
                      setBottomPopMenu(false);
                    }}
                  >
                    그룹 나가기
                  </ExitButton>
                  <CloseButton onClick={() => setBottomPopMenu(false)}>
                    닫기
                  </CloseButton>
                </GroupExitPop>
              </>
            )}
          </BottomPopArea>
        </Dimmed>
      )}
      {editGroupName && (
        <Dimmed>
          <BottomPopArea>
            <TopArea>
              <Typography variant="h5Bold" style={{ color: '#0f0f0f' }}>
                그룹 이름 수정
              </Typography>
              <ClsButton
                src={close}
                onClick={() => setEditGroupName(false)}
                alt="Close"
              />
            </TopArea>
            <GroupName
              onChange={(e) => {
                setGroupName(e.target.value);
              }}
              value={groupName}
              placeholder={groupData?.group_name || '그룹 게임'}
            />
            <GreenBtn
              disabled={!groupName}
              variant="contained"
              onClick={() => EditGroupName()}
            >
              수정
            </GreenBtn>
          </BottomPopArea>
        </Dimmed>
      )}
      {rankView && <DimmedRankingView clsView={() => setRankView(false)} />}
    </Container>
  );
}

const RightTopButton = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
`;

const GameNone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;

const GameList = styled.div`
  position: relative;
  width: 100%;
`;

const GameParty = styled.div`
  width: 100%;
  margin: 10px 0;
  padding: 16px;
  border-radius: 16px;
  background-color: #fafafb;
`;

const TitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 16px;
`;

const ImgStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.img {
    width: 48px;
    height: 48px;
  }
`;

const TextWrap = styled.div`
  width: 100%;
  padding-left: 16px;
`;

const PartyName = styled(Typography)`
  color: #0f0f0f;
`;

const PartyLoc = styled(Typography)`
  color: #505866;
`;

const PartyStats = styled(Typography)`
  margin-top: 20px;
  color: #1fb04b;
`;

const DetachBtn = styled.img`
  position: absolute;
  top: 14px;
  right: 4px;

  width: 38px;
`;

const MemberList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Member = styled.div`
  width: calc(50% - 2px);
  height: 40px;
  padding: 5px 17px;
  margin-bottom: 4px;
  border-radius: 8px;
  background-color: #fff;
  border: solid 1px #1fb04b;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  color: #0f0f0f;

  &.blank {
    background-color: #f2f3f5;
    border-color: #b1b8c0;
  }
`;

const Manager = styled.div`
  width: 22px;
  height: 14px;
  background-color: #e0fce5;

  font-size: 8px;
  font-weight: bold;
  color: #1fb04b;
  border-radius: 7px;
  line-height: 14px;
  text-align: center;
`;

const Blank = styled.div``;

const BottomWrap = styled(Grid)`
  position: fixed;
  bottom: 34px;
  max-width: 500px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
`;

const GrayBtn = styled(Button)`
  width: 100%;
  height: 48px;
  margin-top: 20px;
  color: #fff;
  background-color: #6d7582;
  &:hover {
    background-color: rgba(109, 117, 130, 0.7);
  }
  &.double {
    width: calc(40% - 34px);
  }
`;

const GreenBtn = styled(Button)`
  width: 100%;
  height: 48px;
  margin-top: 20px;
  color: #fff;
  background-color: #1fb04b;
  &.double {
    width: calc(60% - 34px);
  }
`;

const RankBtn = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  padding: 2px;
  margin-top: 20px;
  background-color: #e0fce5;
`;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000a;
  z-index: 500;
`;

const BottomPopArea = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 25px 21px 34px;
  border-radius: 20px 20px 0 0;
  background-color: #fff;

  text-align: center;

  &.middle {
    max-width: 360px;
    top: 50%;
    bottom: unset;
    margin: -100px 20px 0;
    border-radius: 20px;
  }
`;

const GroupExitPop = styled.div``;

const ExitPopTitle = styled.div`
  margin-bottom: 13px;
  font-size: 14px;
  color: #0f0f0f;
`;

const ExitButton = styled.div`
  width: 100%;
  height: 48px;
  background-color: #f9e7e9;
  border-radius: 8px;
  margin: 0 auto;

  font-size: 16px;
  line-height: 48px;
  color: #c13537;
  font-weight: bold;
`;

const CloseButton = styled.div`
  margin-top: 15px;
  font-size: 16px;
  color: #151719;
`;

const TopArea = styled.div`
  height: 43px;
`;

const ClsButton = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const BottomMenuList = styled.div`
  font-size: 16px;
  color: #0f0f0f;
  line-height: 2.5;
`;

const GroupName = styled.input`
  width: 100%;
  height: 50px;
  padding: 13px 16px 13px 32px;
  border: solid 1px #b1b8c0;
  border-radius: 25px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: #b1b8c0;
  }
`;
