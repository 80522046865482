import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import styled from 'styled-components';
import restApi from '../../apis/apiAxios';
import close from '../../assets/images/close.svg';
import { useSocketDataState } from '../../contexts/SocketDataContext';
import { InviteGame } from './InviteGame';
import { SearchFriend } from './Search';

interface Props {
  clsView: () => void;
  gameCode: string;
  gameID: number;
}

export const FriendList: React.FC<Props> = (props) => {
  const [tab, setTab] = useState('1');
  const [playerName, setPlayerName] = useState('');
  const roomID = localStorage.getItem('ri');
  const { data, AddNonePlayer } = useSocketDataState();

  const gameMetaData = data?.game_room_metadata || {};
  const playerCount = gameMetaData.max_player_count;
  const playerData = data?.participant_list || [];

  const copyCode = () => {
    const elementText = `intent://com.greendata.golfun?game=${props.gameCode}`;

    if (navigator.share) {
      navigator.share({
        title: 'Golfun 참가코드',
        text: `다음의 코드를 복사하여 게임에 참여하세요.\n${props.gameCode}`,
        //url: elementText,
      });
    } else {
      navigator.clipboard
        .writeText(`참가코드 : ${props.gameCode}`)
        .then(() => {
          alert(
            '코드 복사가 완료 되었습니다.\n친구에게 메시지를 전달해 주세요.',
          );
        })
        .catch((err) => {
          alert('Error in copying text: ' + err);
        });
    }
  };

  const AddPlayer = () => {
    if (playerCount <= playerData.length) {
      alert(
        '파티인원수가 가득 찼습니다. 플레이어 추가가 불가합니다.\n플레이어 확인 후 진행해주세요.',
      );
      return;
    }

    if (playerData?.find((item: any) => item.user_nickname === playerName)) {
      alert(
        '파티에 동일한 이름의 플레이어가 있습니다.\n다른 이름으로 추가해 주세요.',
      );
      return;
    }

    restApi
      .post('/guest/join', {
        participant_id: 0,
        game_room_join_dto: {
          game_room_id: roomID,
          is_player: '0',
          player_nickname: playerName,
        },
      })
      .then((result) => {
        const { data } = result;
        console.log(data.result_data.participant_id);
        AddNonePlayer(data.result_data.participant_id, playerName);
        alert(`${playerName}님이 파티에 추가되었습니다.`);
        setPlayerName('');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Dimmed>
      <PopLayer>
        <TopArea>
          <Typography variant="h5Bold" style={{ color: '#0f0f0f' }}>
            플레이어초대하기
          </Typography>
          <ClsButton src={close} onClick={props.clsView} alt="Close" />
        </TopArea>
        <TabArea>
          <TabItem
            className={tab === '1' ? 'on' : ''}
            onClick={() => setTab('1')}
          >
            친구
          </TabItem>
          <TabItem
            className={tab === '2' ? 'on' : ''}
            onClick={() => setTab('2')}
          >
            검색
          </TabItem>
          <TabItem
            className={tab === '3' ? 'on' : ''}
            onClick={() => setTab('3')}
          >
            플레이어 추가
          </TabItem>
          <TabItem
            className={tab === '4' ? 'on' : ''}
            onClick={() => setTab('4')}
          >
            링크 복사
          </TabItem>
        </TabArea>
        <ListBox
          sx={{
            height: '90%',
            paddingTop: 4,
            display: 'block',
            overflowY: 'auto',
          }}
        >
          {tab === '1' && (
            <InviteGame gameCode={props.gameCode} gameID={props.gameID} />
          )}
          {tab === '2' && (
            <SearchFriend
              page="game"
              gameCode={props.gameCode}
              gameID={props.gameID}
            />
          )}
          {tab === '3' && (
            <>
              <PlayerName
                onChange={(e) => {
                  setPlayerName(e.target.value);
                }}
                value={playerName}
                placeholder="닉네임을 입력해주세요."
              />
              <h4
                style={{
                  color: '#c0c0c0',
                  textIndent: '-12px',
                  paddingLeft: '12px',
                }}
              >
                ※ 플레이어 추가로 생성된 플레이어는 스코어 계산에만 사용되어지며
                <br />
                플레이어 데이터가 저장되지는 않습니다.
              </h4>
              <GreenBtn
                disabled={!playerName}
                variant="contained"
                onClick={() => AddPlayer()}
              >
                플레이어 추가
              </GreenBtn>
            </>
          )}
          {tab === '4' && (
            <>
              <TxtBox>
                <Typography variant="body3">
                  함께 플레이할 친구에게 초대 코드를 공유해주세요.
                  <br />
                  게임 참여하기에서 코드를 입력하면 그룹으로 입장하게 됩니다.
                </Typography>
              </TxtBox>

              <CopyButton onClick={() => copyCode()}>코드 복사하기</CopyButton>
            </>
          )}
        </ListBox>
      </PopLayer>
    </Dimmed>
  );
};

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000a;
  z-index: 500;
`;

const PopLayer = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  height: 90vh;
  padding: 24px 20px 0;
  background-color: #fff;
  border-radius: 32px 32px 0 0;
`;

const TopArea = styled.div`
  height: 43px;
`;
const ClsButton = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const TabArea = styled.div`
  display: flex;
  flex: 1 1;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 5px;
`;
const TabItem = styled.div`
  width: 100%;
  height: 34px;
  margin-top: 16px;
  //margin-bottom: 24px;

  font-size: 14px;
  font-weight: 500;
  color: #505866;
  line-height: 34px;
  text-align: center;

  border-bottom: 2px solid #e7e9ec;

  &.on {
    font-weight: bold;
    color: #1fb04b;
    border-color: #1fb04b;
  }
`;

const ListBox = styled(Box)`
  div:last-child {
    margin-bottom: 30px;
  }
`;
const TxtBox = styled.div`
  width: 100%;
  padding: 19px 0 19px 16px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #fafafb;
  line-height: 1.67;
`;
const CopyButton = styled(Button)`
  width: 100%;
  height: 53px;
  border-radius: 26px;
  background-color: #e0fce5;
  &:hover {
    background-color: #e0fce5;
  }
`;

const PlayerName = styled.input`
  width: 100%;
  height: 50px;
  padding: 13px 16px 13px 32px;
  border: solid 1px #b1b8c0;
  border-radius: 25px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: #b1b8c0;
  }
`;

const GrayBtn = styled(Button)`
  width: 100%;
  margin-top: 20px;
  color: #fff;
  background-color: #6d7582;
  &:hover {
    background-color: rgba(109, 117, 130, 0.7);
  }
  &.double {
    width: calc(50% - 4px);
  }
`;

const GreenBtn = styled(Button)`
  width: 100%;
  margin-top: 20px;
  color: #fff;
  background-color: #1fb04b;
  &.double {
    width: calc(50% - 4px);
  }
`;
