import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../../apis/apiAxios';
import close from '../../assets/images/close.svg';
import IconStar from '../../assets/images/icn_star.svg';
import IconSearch from '../../assets/images/icon/search_g.svg';

interface Props {
  CCName: (t: string) => void;
  CCID: (n: [number, string]) => void;
  clsView: () => void;
}

export const DimmedCCList: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState('1');
  const [name, setName] = useState('');
  const [fieldList, setFieldList] = useState([
    { golf_club_name: '직접 입력', location: '-' },
  ]);

  const filterData = (query: string, data: any[]) => {
    if (!query) {
      return data;
    } else {
      return data.filter((d) =>
        d.golf_club_name.toLowerCase().includes(query.toLowerCase()),
      );
    }
  };

  const [searchQuery, setSearchQuery] = useState('');
  const dataFiltered = filterData(searchQuery, fieldList);

  const sendCCId = (CC: [number, string]) => {
    props.CCID(CC);
  };
  const sendCCName = () => {
    if (!name) {
      alert('CC이름을 입력해주세요.');
      return;
    } else {
      props.CCName(name);
    }
  };

  useEffect(() => {
    restApi.get('/game/golf-club-list').then((e) => {
      const { data } = e;

      if (data.status_code === '200') {
        setFieldList(data.result_data);
      }
    });
  }, []);

  return (
    <Dimmed>
      <PopLayer>
        <TopArea>
          <Typography variant="h5Bold" style={{ color: '#0f0f0f' }}>
            라운드 장소 선택
          </Typography>
          <ClsButton src={close} onClick={props.clsView} alt="Close" />
        </TopArea>
        <TabArea>
          <TabItem
            className={tab === '1' ? 'on' : ''}
            onClick={() => setTab('1')}
          >
            골프장 찾기
          </TabItem>
          <TabItem
            className={tab === '2' ? 'on' : ''}
            onClick={() => setTab('2')}
          >
            직접 입력
          </TabItem>
        </TabArea>
        {tab === '1' && (
          <CCListBox>
            <SearchBarWrap>
              <SearchBar
                id="search-bar"
                className="text"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder="골프장 이름을 입력하세요."
                style={{ width: '100%' }}
                value={searchQuery}
              />
              <img src={IconSearch} alt="icon" />
            </SearchBarWrap>
            {dataFiltered.map((d, i) => (
              <CCListItem
                key={i}
                onClick={() => sendCCId([d.golf_club_id, d.golf_club_name])}
              >
                <Typography variant="h5Bold">{d.golf_club_name}</Typography>
                <Typography variant="body1" style={{ color: '#505866' }}>
                  {d.location}
                </Typography>
                <CCListIcon src={IconStar} alt=">" />
              </CCListItem>
            ))}
          </CCListBox>
        )}
        {tab === '2' && (
          <WriteClubName>
            <TextField
              placeholder="라운드 장소를 입력해주세요"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputButton>
                    <span onClick={sendCCName}>저장</span>
                  </InputButton>
                ),
              }}
            />
          </WriteClubName>
        )}
      </PopLayer>
    </Dimmed>
  );
};

export default DimmedCCList;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000a;
  z-index: 500;
`;

const PopLayer = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  max-width: 460px;
  height: 90vh;
  padding: 24px 20px 0;
  background-color: #fff;
  border-radius: 32px 32px 0 0;
`;

const TopArea = styled.div``;
const ClsButton = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const TabArea = styled.div`
  display: flex;
  flex: 1 1;
  justify-content: space-evenly;
`;
const TabItem = styled.div`
  width: 100%;
  height: 34px;
  margin-top: 16px;
  margin-bottom: 24px;

  font-size: 14px;
  font-weight: 500;
  color: #505866;
  line-height: 34px;
  text-align: center;

  border-bottom: 2px solid #e7e9ec;

  &.on {
    font-weight: bold;
    color: #1fb04b;
    border-color: #1fb04b;
  }
`;

const CCListBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 96px);
  overflow: auto;
`;
const SearchBarWrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  img {
    position: absolute;
    top: 13px;
    left: 16px;
    width: 24px;
  }
`;

const SearchBar = styled.input`
  width: 100%;
  height: 50px;
  padding: 13px 16px 13px 52px;
  border: solid 1px #b1b8c0;
  border-radius: 25px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: #b1b8c0;
  }
`;

const CCListItem = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  padding: 18px 16px 16px 24px;
  border-radius: 16px;
  background-color: #f2f3f5;
`;

const CCListIcon = styled.img`
  position: absolute;
  margin-top: -8px;
  top: 50%;
  right: 16px;
`;

const WriteClubName = styled.div`
  fieldset {
    border: unset;
  }
`;
const InputButton = styled.div`
  width: 45px;
  font-size: 16px;
  font-weight: bold;
  color: #b1b8c0;
`;
