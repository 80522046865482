import React from 'react';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import GlobalStyles from './components/blocks/GlobalStyles';
import StyleContext from './contexts/StyleContext';
import Routes from './routes';

function App() {
  return (
    <StyleContext>
      <StyledEngineProvider injectFirst>
        <GlobalStyles />
        <div className="App">
          <Routes />
        </div>
      </StyledEngineProvider>
    </StyleContext>
  );
}

export default App;
