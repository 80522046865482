import React, { ReactElement } from 'react';
import MuiContext from './MuiContext';
import { SiteContextProvider } from './SiteContext';

interface Props {
  children: ReactElement | ReactElement[];
}

const StyleContext: React.FC<Props> = ({ children }) => {
  return (
    <MuiContext>
      <SiteContextProvider>{children}</SiteContextProvider>
    </MuiContext>
  );
};

export default StyleContext;
