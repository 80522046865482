import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../../apis/apiAxios';
import IconCheck from '../../assets/images/icn_checked.svg';
import IconCloseRed from '../../assets/images/icn_close_red.svg';
import IconGameType from '../../assets/images/icn_game_type.svg';

export const GameList = () => {
  const navigate = useNavigate();
  const [gameListItem, setGameListItem]: any[] = useState([]);
  const [groupListItem, setGroupListItem]: any[] = useState([]);

  const join = (id: any, code: any) => {
    const isGG = code.slice(0, 2) === 'G_';

    if (isGG) {
      localStorage.removeItem('ri');
      localStorage.setItem('room_code', code);
      restApi
        .delete(`/game-room-group/invite?game_room_group_id=${id}`)
        .then((e) => {
          const { data } = e;
          if (data.status_code === '200') {
            localStorage.removeItem('GGCD');
            localStorage.removeItem('GGID');
            localStorage.setItem('GGCD', code);
            localStorage.setItem('GGID', id);
            navigate(`/creategroup`);
          }
        });
    } else {
      restApi.delete(`/game/invite?game_room_id=${id}`).then((e) => {
        const { data } = e;
        if (data.status_code === '200') {
          localStorage.removeItem('ri');
          localStorage.setItem('room_code', code);
          navigate(`/gameshare`);
        }
      });
    }
  };

  const inviteDelete = (id: any, code: any) => {
    restApi.delete(`/game/invite?game_room_id=${id}`).then((e) => {
      const { data } = e;
      if (data.status_code === '200') {
        callInviteList();
      }
    });
  };

  const inviteGroupDelete = (id: any, code: any) => {
    restApi
      .delete(`/game-room-group/invite?game_room_group_id=${id}`)
      .then((e) => {
        const { data } = e;
        if (data.status_code === '200') {
          callGroupInviteList();
        }
      });
  };

  const callInviteList = () => {
    restApi.get('/game/invite').then((e) => {
      const { data } = e;
      if (data.status_code === '200') {
        setGameListItem(data.result_data);
      } else if (data.status_code === '505') {
        setGameListItem([]);
      }
    });
  };

  const callGroupInviteList = () => {
    restApi.get('/game-room-group/invite-list').then((e) => {
      const { data } = e;
      if (data.status_code === '200') {
        setGroupListItem(data.result_data);
      } else if (data.status_code === '505') {
        setGroupListItem([]);
      }
    });
  };

  useEffect(() => {
    callInviteList();
    callGroupInviteList();
  }, []);

  return (
    <GameListBox>
      {gameListItem.length === 0 && groupListItem.length === 0 && (
        <GameListItem>초대 받은 게임이 없습니다.</GameListItem>
      )}
      {gameListItem.length !== 0 && <ListTitle>파티 초대</ListTitle>}
      {gameListItem.map((d: any, i: number) => (
        <GameListItem key={i} id={d.game_room_code}>
          <ProfileImg src={IconGameType} alt="Game type" />
          <TextWrap>
            <Typography variant="h5Bold">{d.invite_user_nickname}</Typography>
            <Typography variant="body1" style={{ color: '#505866' }}>
              {d.golf_club_name}
            </Typography>
          </TextWrap>
          <StatusImg
            className="cancel"
            src={IconCloseRed}
            alt="Icon"
            onClick={() => inviteDelete(d.game_room_id, d.game_room_code)}
          />
          <StatusImg
            src={IconCheck}
            alt="Icon"
            onClick={() => join(d.game_room_id, d.game_room_code)}
          />
        </GameListItem>
      ))}
      {groupListItem.length !== 0 && <ListTitle>그룹 초대</ListTitle>}
      {groupListItem.map((d: any, i: number) => (
        <GameListItem key={i} id={d.group_code}>
          <ProfileImg src={IconGameType} alt="Game type" />
          <TextWrap>
            <Typography variant="h5Bold">{d.owner_user_nickname}</Typography>
            <Typography variant="body1" style={{ color: '#505866' }}>
              {d.group_name}
            </Typography>
          </TextWrap>
          <StatusImg
            className="cancel"
            src={IconCloseRed}
            alt="Icon"
            onClick={() =>
              inviteGroupDelete(d.game_room_group_id, d.group_code)
            }
          />
          <StatusImg
            src={IconCheck}
            alt="Icon"
            onClick={() => join(d.game_room_group_id, d.group_code)}
          />
        </GameListItem>
      ))}
    </GameListBox>
  );
};

export default GameList;

const GameListBox = styled(Box)`
  width: 100%;
`;
const ListTitle = styled.div`
  margin: 3px auto 0;
`;
const GameListItem = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  width: 100%;
  margin-bottom: 8px;
  padding: 13px 16px 11px 12px;
  border-radius: 16px;
  background-color: #fafafb;
`;
const ProfileImg = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 24px;
`;
const StatusImg = styled.img`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 44px;

  &.cancel {
    right: 68px;
  }
`;
const TextWrap = styled.div``;
