import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../../apis/apiAxios';
import Arrow from '../../assets/images/icn_arrow_down.svg';
import Field from '../../assets/images/icn_field.svg';
import Screen from '../../assets/images/icn_screen.svg';
import crownOrange from '../../assets/images/icon/crown_orange.png';
import ImgWrite from '../../assets/images/write.svg';
import { useSiteState } from '../../contexts/SiteContext';

interface RoundInfo {
  participant_id?: number;
  play_datatime?: string;
  field_type_code?: string;
  play_ground_name?: string;
  score?: number;
}

export default function List() {
  const navigate = useNavigate();
  const { memberInfo } = useSiteState();
  const [roundList, setRoundList] = useState([]);
  const [listType, setListType] = useState('0');
  const dataFiltered = roundList?.filter(
    (d: RoundInfo) => d.field_type_code === listType || listType === '0',
  );
  const [detailView, setDetailView] = useState(0);
  const [detailScore, setDetailScore] = useState<any>([]);

  const gameDetail = (pid: number) => {
    if (detailView === pid) {
      setDetailView(0);
    } else {
      restApi
        .get('/user/round-history/detail', {
          params: {
            participant_id: pid,
          },
        })
        .then((e) => {
          const { data } = e;
          if (data.status_code === '200') {
            setDetailView(pid);
            setDetailScore(data.result_data);
          }
        });
    }
  };

  const chgGameType = (gameID: string, gameType: string) => {
    restApi
      .put('/game/field-type', {
        game_room_id: gameID,
        field_type_code: gameType,
      })
      .then((e) => {
        const { data } = e;
        if (data.status_code === '200') {
          readData();
          setListType(gameType);
        }
      });
  };

  const readData = () => {
    restApi
      .get('/user/round-history/list', {
        params: {
          field_type_code: 0,
          page_num: 0,
        },
      })
      .then((e) => {
        const { data } = e;
        if (data.status_code === '200') {
          setRoundList(data.result_data);
        }
      });
  };

  useEffect(() => {
    readData();
  }, []);

  return (
    <>
      <WriteScore onClick={() => navigate('/writescore')}>
        <img src={ImgWrite} alt="Icon" />
        <Typography variant="h5Bold" ml={1}>
          스코어 기록하기
        </Typography>
      </WriteScore>
      <RoundListTab>
        <RoundTypeList>
          <RoundType
            className={listType === '0' ? 'on' : ''}
            onClick={() => setListType('0')}
          >
            통합
          </RoundType>
          <RoundType
            className={listType === '1' ? 'on' : ''}
            onClick={() => setListType('1')}
          >
            필드
          </RoundType>
          <RoundType
            className={listType === '2' ? 'on' : ''}
            onClick={() => setListType('2')}
          >
            스크린
          </RoundType>
        </RoundTypeList>
      </RoundListTab>
      {dataFiltered.map((d: RoundInfo, i) => (
        <RoundList key={i}>
          <RoundSummary>
            <img src={d.field_type_code === '2' ? Screen : Field} alt="Icon" />
            <RoundInfoStyled>
              <Typography variant="body3" style={{ color: '#505866' }}>
                {moment(d.play_datatime).format('YYYY년 MM월 DD일')}
              </Typography>
              <Typography variant="h5Bold" style={{ color: '#0f0f0f' }}>
                {d.play_ground_name}
              </Typography>
            </RoundInfoStyled>
            <RoundScore
              variant="h5Bold"
              style={{
                color: d.field_type_code === '2' ? '#3162d2' : '#1fb04b',
                backgroundColor:
                  d.field_type_code === '2' ? '#e0ebfc' : '#e0fce5',
              }}
            >
              {d.score}타
            </RoundScore>
            {d.participant_id && (
              <IconArrow
                src={Arrow}
                className={detailView === d.participant_id ? 'view' : 'none'}
                onClick={() => gameDetail(d.participant_id || 0)}
                alt=">"
              />
            )}
          </RoundSummary>
          {detailView === d.participant_id &&
            (detailScore.participant_list?.length === 0 ? (
              <>
                홀 정보가 없습니다.
                <br />
                (수기입력하신 게임은 홀 정보가 없습니다.)
              </>
            ) : (
              <RoundDetail>
                <ScoreBoard>
                  <StyledTable stickyHeader sx={{ tableLayout: 'fixed' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 113 }} />
                        {detailScore.participant_list[0].hole_id_list?.map(
                          (v: any, i: number) => {
                            return (
                              <TableCell
                                key={i}
                                sx={{
                                  width: 52,
                                }}
                              >
                                {i + 1}H
                              </TableCell>
                            );
                          },
                        )}
                        <TableCell
                          sx={{ width: 90 }}
                          style={{ color: '#1fb04b' }}
                        >
                          TOTAL
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detailScore.participant_list.map((v: any, n: number) => {
                        const totalShot = v.hole_id_list.reduce(
                          (sum: any, item: any) => sum + item.shot,
                          0,
                        );
                        const totalPutt = v.hole_id_list.reduce(
                          (sum: any, item: any) => sum + item.putt,
                          0,
                        );

                        return (
                          <TableRow key={n}>
                            <TableCell>
                              <NameWrap
                                className={
                                  v.participant_id === detailView
                                    ? 'me'
                                    : 'none'
                                }
                              >
                                {v.player_nickname}
                              </NameWrap>
                            </TableCell>
                            {detailScore.participant_list[n].hole_id_list?.map(
                              (cv: any, i: number) => {
                                const shot = v.hole_id_list[i]?.shot || '-';
                                const score = shot - cv?.par;
                                return (
                                  <TableCell
                                    key={i}
                                    className="shotInfo"
                                    style={{ position: 'relative' }}
                                  >
                                    <ShotWrap>
                                      <ShotBox
                                        className={
                                          (v.hole_id_list?.length === i
                                            ? 'playHole'
                                            : 'hole') +
                                          (score < 0 ? ' under' : ' none')
                                        }
                                      >
                                        <div className="parInfo">
                                          par{cv?.par}
                                        </div>
                                        {isNaN(score)
                                          ? '-'
                                          : score > 0
                                          ? `+${score}`
                                          : score}
                                        {v.hole_id_list[i]?.putt !==
                                          undefined && (
                                          <Putt key={i}>
                                            {v.hole_id_list[i]?.putt}
                                          </Putt>
                                        )}
                                      </ShotBox>
                                    </ShotWrap>
                                  </TableCell>
                                );
                              },
                            )}
                            <TableCell style={{ position: 'relative' }}>
                              <TotalWrap>
                                <TotalBox>
                                  {totalShot}
                                  {totalPutt !== 0 && (
                                    <Putt className="total">{totalPutt}</Putt>
                                  )}
                                </TotalBox>
                              </TotalWrap>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </StyledTable>
                </ScoreBoard>
                <ChgTypeButton
                  className={d.field_type_code === '1' ? 'field' : 'screen'}
                  onClick={() =>
                    chgGameType(
                      detailScore.game_room_id,
                      d.field_type_code === '1' ? '2' : '1',
                    )
                  }
                >
                  {d.field_type_code === '1'
                    ? '스크린으로 이동하기'
                    : '필드로 이동하기'}
                </ChgTypeButton>
              </RoundDetail>
            ))}
        </RoundList>
      ))}
      {dataFiltered.length === 0 && (
        <RoundList>완료된 게임 정보가 없습니다.</RoundList>
      )}
    </>
  );
}

const WriteScore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 96px;
  margin-bottom: 32px;
  padding: 26px;
  border-radius: 40px;
  background-color: #fafafb;
`;

const RoundListTab = styled.div`
  width: 100%;
`;

const RoundTypeList = styled.div`
  display: flex;
  margin-bottom: 24px;
  height: 34px;
`;

const RoundType = styled.div`
  flex-grow: 1;

  font-size: 14px;
  color: #505866;
  font-weight: 500;
  text-align: center;

  border-bottom: 2px solid #e7e9ec;

  &.on {
    color: #1fb04b;
    font-weight: bold;
    border-color: #1fb04b;
  }
`;

const RoundList = styled.div`
  width: 100%;
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 16px;
  background-color: #fafafb;
`;

const RoundSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  width: 100%;
  padding-right: 30px;
`;

const RoundInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  flex-grow: 1;
  margin: 0 16px;
`;

const RoundScore = styled(Typography)`
  display: block;
  width: 50px;
  height: 28px;
  border-radius: 8px;
  text-align: center;
  line-height: 28px;
`;

const IconArrow = styled.img`
  position: absolute;
  margin-top: -13px;
  padding: 10px;
  top: 50%;
  right: 0;
  transition-duration: 0.3s;

  &.view {
    transform: rotate(180deg);
  }
`;

const RoundDetail = styled.div``;

const ScoreBoard = styled(TableContainer)`
  table * {
    border-width: 0 !important;
  }
  th,
  td {
    padding: 0;
    background-color: #fafafb;
  }

  tr > td > div {
    //background-color: #fafafb;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const NameWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 60px;
  margin: 4px 0;
  padding-left: 24px;
  font-size: 14px;
  font-weight: 500;

  &.me {
    font-weight: bold;
    color: #1fb04b;
  }
`;
const ShotWrap = styled.div`
  height: 60px;
  padding: 8px 4px;
`;
const TotalWrap = styled.div`
  height: 60px;
  padding: 8px 8px 8px 4px;
`;

const ShotBox = styled.div`
  position: relative;
  width: 44px;
  height: 44px;
  padding-top: 2px;
  border-radius: 8px;
  background-color: #e7e9ec;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  &.playHole {
    background-color: #e0fce5;
  }
  &.under {
    background-color: #f9e7e9;
    color: #c13537;
  }
  & > .parInfo {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #505866;
  }
`;

const TotalBox = styled.div`
  height: 44px;
  border-radius: 8px;
  background-color: #1fb04b;

  font-size: 18px;
  font-weight: bold;
  line-height: 44px;
  color: #fff;
`;

const Putt = styled.div`
  position: absolute;
  margin: 0;
  padding: 1px 5px;
  right: 0;
  bottom: 3px;
  border: 1px solid #ccc;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  text-align: center;

  &.total {
    bottom: 20px;
    right: 10px;
  }
`;

const StyledTable = styled(Table)`
  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
  }
  th:first-child {
    border-left: 1px solid #ccc;
    z-index: 4;
  }
  td:first-child {
    border-left: 1px solid #ccc;
    //background-color: #fff;
    z-index: 3;
  }

  tr:first-child > th {
    border-top: 1px solid #ccc;
  }

  * {
    text-align: center;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
`;

const ChgTypeButton = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #3162d2;

  text-align: center;

  &.screen {
    color: #1fb04b;
  }
`;
