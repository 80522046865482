import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import restApi from '../../apis/apiAxios';
import IconCheck from '../../assets/images/icn_checked.svg';
import IconCloseRed from '../../assets/images/icn_close_red.svg';
import MemberImg from '../../assets/images/member.png';

export const FriendsList = () => {
  const [friendList, setFriendList] = useState([]);

  useEffect(() => {
    CallFriendList();
  }, []);

  const CallFriendList = () => {
    restApi.get('/user/friend/invite/list').then((e) => {
      const { data } = e;
      if (data.status_code === '200') {
        setFriendList(data.result_data);
      } else if (data.status_code === '505') {
        setFriendList([]);
      }
    });
  };

  const AddFriend = (id: number) => {
    restApi.post('/user/friend/add', { friend_user_id: id }).then((e) => {
      const { data } = e;
      if (data.status_code === '200') {
        CallFriendList();
      }
    });
  };
  const DeleteInvite = (id: number) => {
    restApi
      .delete('/user/friend/invite', { params: { friend_user_id: id } })
      .then((e) => {
        const { data } = e;
        if (data.status_code === '200') {
          CallFriendList();
        }
      });
  };

  return (
    <FriendListBox>
      {friendList.length === 0 && (
        <FriendList>친구 요청이 없습니다.</FriendList>
      )}
      {friendList.map((d: any, i) => (
        <FriendList key={i} id={d.friend_user_id}>
          <ProfileImg src={d.friend_profile || MemberImg} alt="Profile image" />
          <Typography variant="h5">{d.friend_nickname}</Typography>
          <StatusImg
            onClick={() => DeleteInvite(d.friend_user_id)}
            className="cancel"
            src={IconCloseRed}
            alt="Icon"
          />
          <StatusImg
            onClick={() => AddFriend(d.friend_user_id)}
            src={IconCheck}
            alt="Icon"
          />
        </FriendList>
      ))}
    </FriendListBox>
  );
};

export default FriendsList;

const FriendListBox = styled(Box)`
  width: 100%;
`;

const FriendList = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  width: 100%;
  margin-bottom: 8px;
  padding: 13px 16px 11px 12px;
  border-radius: 16px;
  background-color: #fafafb;
`;
const ProfileImg = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 24px;
`;
const StatusImg = styled.img`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 44px;
  &.cancel {
    right: 68px;
  }
`;
