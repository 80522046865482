import React, { useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import { BottomButton } from '../components/atoms/BottomButton';
import { InputBox } from '../components/atoms/InputBox';
import { TopNavigation } from '../components/atoms/TopNavigation';
import {
  isNickname,
  isValidEmail,
  isValidPassword,
} from '../components/blocks/format';

interface SignupFormData {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
}

const SignupForm: React.FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [id, setId] = useState<string>('');
  const [pw, setPw] = useState<string>('');
  // const [cpw, setCPw] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [nickname, setNickname] = useState<string>('');
  const [nameMessage, setNameMessage] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [pwMessage, setPwMessage] = useState<string>('');
  const [nicknameMessage, setNicknameMessage] = useState<string>('');

  const [formData, setFormData] = useState<SignupFormData>({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
  };

  const nextStep = () => {
    if (page === 1) {
      const emailChk = isValidEmail(id);
      if (!emailChk) {
        setEmailMessage('이메일 형식이 맞지 않습니다. 확인해주세요.');
        return emailChk;
      }

      const pwChk = isValidPassword(pw);
      if (!pwChk) {
        setPwMessage('비밀번호 형식이 맞지 않습니다.');
        return pwChk;
      }

      restApi
        .get(`/guest/check-email`, {
          params: {
            email: id,
          },
        })
        .then((e) => {
          const { data } = e;
          if (data.status_code !== '2001') {
            setEmailMessage(data.status_message);
          } else if (data.result_data === '사용 가능한 이메일입니다.') {
            setPage(2);
          }
        });
    }
    if (page === 2) {
      if (!isNickname(nickname)) {
        setNicknameMessage(
          '닉네임 형식을 맞춰 주세요(한글, 영문 포함 3자 이상 12자 이하)',
        );
        return;
      }

      restApi
        .get('/guest/check-nickname', {
          params: {
            nickname: nickname,
          },
        })
        .then((e) => {
          const { data } = e;
          if (data.status_code === '2005') {
            restApi
              .post('/guest/sign-up', {
                user_name: name,
                nickname: nickname,
                email: id,
                password: pw,
              })
              .then((e) => {
                const { data } = e;
                if (data.status_code === '200') {
                  alert(
                    '가입이 완료되었습니다.\n로그인 페이지에서 로그인해주세요.',
                  );
                  navigate('/signin');
                }
              });
          } else if (data.status_code === '2006') {
            setNicknameMessage(data.result_data);
          }
        });
    }
  };

  return (
    <Container>
      <TopNavigation type="back" handleEvent={() => navigate('/onboarding')}>
        {page === 1 ? '계정 만들기' : '닉네임 설정하기'}
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
          {page === 1 && (
            <>
              <InputBox
                id="name"
                title={'이름'}
                value={name}
                placehold="이름을 입력해주세요."
                handleEvent={(event) => {
                  setName(event.target.value);
                  setNameMessage('');
                }}
                pwView={false}
                message={nameMessage}
              />
              <InputBox
                id="email"
                title="이메일 주소"
                value={id}
                placehold="ID@example.com"
                handleEvent={(event) => {
                  setId(event.target.value);
                  setEmailMessage('');
                }}
                pwView={false}
                message={emailMessage}
              />

              <InputBox
                id="password"
                title="비밀번호"
                value={pw}
                placehold="영문, 숫자 2가지 조합 8~15자"
                handleEvent={(event) => {
                  setPw(event.target.value);
                  setPwMessage('');
                }}
                pwView={true}
                message={pwMessage}
              />

              <InfoMessage>
                이메일이 정확한지 확인해주세요! 비밀번호 분실시 찾을 수
                없습니다!
              </InfoMessage>
            </>
          )}

          {page === 2 && (
            <>
              <TopInfo>
                게임에서는 이름이 아닌 닉네임으로 표시됩니다.
                <br />
                미풍양속 및 사회 통념에 어긋나는 닉네임은 제한될 수 있습니다.
              </TopInfo>

              <InputBox
                id="nickname"
                title="닉네임"
                value={nickname}
                placehold="이름을 입력해주세요."
                handleEvent={(event) => {
                  setNickname(event.target.value);
                  setNicknameMessage('');
                }}
                message={nicknameMessage}
              />
            </>
          )}
        </form>

        <BottomButton
          txt={page === 1 ? '다음' : '계정 만들기'}
          disabled={!(page === 1 ? name && id && pw : nickname)}
          handleEvent={nextStep}
        />
      </Box>
    </Container>
  );
};

export default SignupForm;

const InfoMessage = styled.div`
  width: 100%;
  margin: 28px 0 8px;
  padding: 5px 0 4px;
  border-radius: 8px;
  background-color: #e0fce5;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.1px;
  text-align: center;
  color: #1fb04b;
`;

const TopInfo = styled.div`
  width: 100%;
  padding: 19px 16px 18px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #fafafb;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.1px;
  text-align: left;
  color: #0f0f0f;
`;
