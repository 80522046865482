import React from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';

interface Props {
  txt: string;
  disabled: boolean;
  loc?: string;
  handleEvent: () => void;
  type?: string;
}

export const BottomButton: React.FC<Props> = ({
  txt,
  disabled,
  loc,
  handleEvent,
  type,
}) => {
  return (
    <ButtonStyled
      variant="contained"
      fullWidth
      sx={{ m: 1 }}
      onClick={handleEvent}
      disabled={disabled}
      className={`${loc} ${type}`}
    >
      {txt}
    </ButtonStyled>
  );
};

export default BottomButton;

const ButtonStyled = styled(Button)`
  max-width: 460px;
  width: calc(100% - 40px);
  margin-top: 40px;

  &.bottom {
    position: absolute;
    bottom: 44px;
  }
  &.warning {
    background-color: #f9e7e9;
    color: #c13537;
  }
  &:disabled {
    background-color: #e7e9ec;
    color: #b1b8c0;
  }
`;
