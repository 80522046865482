import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import BottomNavigation from '../components/atoms/BottomNavigation';
import { useSiteState } from '../contexts/SiteContext';
import Analysis from './Record/Analysis';
import List from './Record/List';

interface RoundInfo {
  play_datatime?: string;
  field_type_code?: string;
  play_ground_name?: string;
  score?: number;
}

export default function RoundRecord() {
  const { memberInfo } = useSiteState();
  const [page, setPage] = useState('list');

  return (
    <Container>
      <TopArea>
        <Title variant="h3Bold">라운드 기록</Title>
        <InfoList>
          <InfoItem>
            <InfoTitle variant="body1">최고</InfoTitle>
            <InfoInfo variant="h4Bold">
              {memberInfo?.life_best_score}타
            </InfoInfo>
          </InfoItem>
          <InfoItem>
            <InfoTitle variant="body1">평균</InfoTitle>
            <InfoInfo variant="h4Bold">
              {Math.floor(memberInfo?.average_score as number)}타
            </InfoInfo>
          </InfoItem>
        </InfoList>
      </TopArea>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <MenuList>
          <MenuItem
            className={page === 'list' ? 'on' : ''}
            onClick={() => setPage('list')}
          >
            리스트
          </MenuItem>
          <MenuItem
            className={page === 'analysis' ? 'on' : ''}
            onClick={() => setPage('analysis')}
          >
            타수분석
          </MenuItem>
        </MenuList>
      </Box>
      {page === 'list' && <List />}
      {page === 'analysis' && <Analysis />}
      <BottomNavigation loc="score" />
    </Container>
  );
}

const TopArea = styled(Grid)`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Typography)`
  color: #1a1e27;
`;
const InfoList = styled.div`
  display: flex;
`;
const InfoItem = styled.div`
  display: flex;
  margin-left: 14px;
  align-items: flex-end;
`;
const InfoTitle = styled(Typography)`
  margin-right: 4px;
  color: #0f0f0f;
  line-height: 1.1;
`;
const InfoInfo = styled(Typography)`
  color: #1fb04b;
  line-height: 1;
`;

const MenuList = styled.div`
  display: flex;

  margin-bottom: 16px;

  width: 100%;
  height: 40px;
  background-color: #fafafb;
  border-radius: 20px;
`;
const MenuItem = styled.div`
  flex-grow: 1;

  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;

  &:first-child {
    border-radius: 20px 0 0 20px;
  }
  &:last-child {
    border-radius: 0 20px 20px 0;
  }

  &.on {
    font-weight: bold;
    color: #fafafa;
    background-color: #1fb04b;
    border-radius: 20px;
  }
`;
