import React, { useEffect, useState } from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import logo from '../assets/images/golfun.svg';
import icnAlarm from '../assets/images/icn_alarm.svg';
import icnClose from '../assets/images/icn_close.svg';
import icnFriends from '../assets/images/icn_friends.svg';
import icnNewAlarm from '../assets/images/icn_new_alarm.svg';
import create from '../assets/images/icon/create.svg';
import golinee from '../assets/images/icon/golinee.svg';
import createGroupImg from '../assets/images/icon/group_create.svg';
import guide from '../assets/images/icon/guide.svg';
import joinImg from '../assets/images/icon/join.svg';
import MemberImg from '../assets/images/member.png';
import X from '../assets/images/x.svg';
import BottomNavigation from '../components/atoms/BottomNavigation';
import { DimmedConfirm } from '../components/atoms/DimmedPopup';
import { searchParams } from '../components/blocks/CommonFn';
import { gameTypeTranslate } from '../components/blocks/format';
import { useSiteState } from '../contexts/SiteContext';
import { useSocketDataState } from '../contexts/SocketDataContext';

export default function Clubhouse() {
  const { memberInfo, gameInfoChange, memberInfoChange } = useSiteState();
  const navigate = useNavigate();
  const roomCode = localStorage.getItem('room_code') || searchParams('game');
  const GGCD = localStorage.getItem('GGCD') || '';
  const GGND = localStorage.getItem('GGND') || '';
  const [gameBar, setGameBar] = useState<boolean>(false);
  const [deleteGamePop, setDeleteGamePop] = useState(false);
  const [createGroupPop, setCreateGroupPop] = useState(false);
  const [newNotic, setNewNotic] = useState(false);
  const accessToken = localStorage.getItem('accessToken');
  const nmNickname = localStorage.getItem('nickname');
  const myImage = memberInfo?.profile_image_url || MemberImg;
  const { socketDisconnect, groupSocketDisconnect } = useSocketDataState();
  const [joinGameList, setJoinGameList] = useState([]);
  const [ejectPlayerPop, setEjectPlayerPop] = useState(false);
  const [ejectGroupPop, setEjectGroupPop] = useState(false);
  const [ejectParticipantID, setEjectParticipantID] = useState(0);

  const createGame = () => {
    if (!accessToken) {
      alert('게임 생성은 회원만 가능합니다.');
      return;
    }

    navigate('/selectcc', { state: 'main' });
  };

  const createGroup = () => {
    restApi.post('/game-room-group/create').then((e: any) => {
      const { data } = e;

      if (data.status_code === '200') {
        localStorage.setItem('GGCD', data.result_data.group_code);
        localStorage.setItem('GGID', data.result_data.game_room_group_id);

        navigate('/creategroup');
      }
    });
  };
  const goGameGuide = () => navigate('/golfunguide');
  const joinGame = () => navigate('/joingame');
  const goNoticeList = () => navigate('/notice');
  const goFriend = () => navigate('/friend');

  const deleteGame = () => {
    localStorage.removeItem('room_code');
    localStorage.removeItem('ri');
    localStorage.removeItem('GGCD');
    localStorage.removeItem('GGID');
    setDeleteGamePop(false);
  };

  const preGame = () => {
    if (!roomCode && GGCD) {
      navigate(`/creategroup`);
    } else {
      navigate(`/gameshare`);
    }
  };

  const joinPreGame = (type: string, code: any) => {
    if (type !== 'G' && code.group_code !== null) {
      localStorage.setItem('GGCD', code.group_code);
    } else {
      localStorage.removeItem('GGCD');
    }
    if (type !== 'G' && code.group_id !== null) {
      localStorage.setItem('GGID', code.group_id);
    } else {
      localStorage.removeItem('GGID');
    }
    localStorage.setItem('room_code', code.room_code);

    if (type === 'G') {
      localStorage.removeItem('room_code');
      localStorage.removeItem('ri');
      restApi.post(`/game-room-group/join`, { group_code: code }).then((e) => {
        const { data } = e;
        if (data.status_code === '200') {
          localStorage.setItem('GGCD', data.result_data.group_code);
          localStorage.setItem('GGID', data.result_data.game_room_group_id);
          navigate('/creategroup');
        } else {
          console.log(e);
        }
      });
    } else {
      navigate(`/gameshare`);
    }
  };

  const CodeListCall = () => {
    restApi.get(`/user/code-list`).then((e: any) => {
      const { data } = e;
      if (data.status_code === '200') {
        setJoinGameList(data.result_data);
      }
    });
  };

  const ejectGroup = () => {
    localStorage.removeItem('GGID');
    localStorage.removeItem('GGCD');
    localStorage.removeItem('GGND');
    setEjectGroupPop(false);
  };

  const ejectPlayer = (id: any) => {
    restApi.put('/game/kick-out', { participant_id: id }).then((e) => {
      const { data } = e;
      if (data?.status_code === '200') {
        CodeListCall();
        setEjectParticipantID(0);
        setEjectPlayerPop(false);
      }
    });
  };

  useEffect(() => {
    gameInfoChange(null);

    if (!accessToken) {
      if (nmNickname || searchParams('game')) {
        memberInfoChange({ nickname: nmNickname || '' });
      } else {
        navigate('/onboarding');
      }
    }

    // 알람 체크
    restApi.get('/user/all/invite').then((e) => {
      const { data } = e;
      if (data.status_code === '200') {
        setNewNotic(data.result_data);
      }

      // 참여중인 게임 리스트
      CodeListCall();
    });

    socketDisconnect();
    groupSocketDisconnect();

    // 파티정보 삭제
    localStorage.removeItem('room_code');
    localStorage.removeItem('ri');
  }, []);

  return (
    <Container component="main" sx={{}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <TopArea>
          <img src={logo} alt="logo" />
          <div>
            <img src={icnFriends} onClick={goFriend} alt="logo" />
            <img
              src={newNotic ? icnNewAlarm : icnAlarm}
              onClick={goNoticeList}
              alt="Notice"
            />
          </div>
        </TopArea>

        <MemberWrap>
          <MyImage style={{ backgroundImage: `url(${myImage})` }} />
          <div style={{ color: '#505866', width: '40%' }}>
            안녕하세요!{' '}
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {memberInfo?.nickname}
            </Typography>
          </div>
          <MemberInfoBox>
            <ViewScore>
              <Typography variant="body3">최고 </Typography>
              <Typography
                variant="h5"
                sx={{
                  display: 'contents',
                  fontWeight: 'bold',
                  color: '#1fb04b',
                }}
              >
                {memberInfo?.life_best_score === 0
                  ? '- '
                  : memberInfo?.life_best_score}
                타
              </Typography>
            </ViewScore>
            <ViewScore>
              <Typography variant="body3">평균 </Typography>
              <Typography
                variant="h5"
                sx={{
                  display: 'contents',
                  fontWeight: 'bold',
                  color: '#1fb04b',
                }}
              >
                {memberInfo?.average_score === 0
                  ? '- '
                  : Math.floor(memberInfo?.average_score as number)}
                타
              </Typography>
            </ViewScore>
          </MemberInfoBox>
        </MemberWrap>

        <Grid container gap={1}>
          <ContentsWrap item xs={12} onClick={createGame}>
            <div style={{ marginRight: '24px' }}>
              <img src={create} alt="create game" />
            </div>
            <div style={{ color: '#505866', textAlign: 'left' }}>
              <Typography
                variant="h4Bold"
                sx={{ color: '#1a1e27', marginBottom: '5px' }}
              >
                게임 생성하기
              </Typography>
              <Typography variant="body1" style={{ color: '#505866' }}>
                기본 스코어관리부터 내기게임까지,
                <br />
                다양하게 골프를 즐겨봐요!
              </Typography>
            </div>
          </ContentsWrap>
          <ContentsWrap item xs={12} onClick={() => setCreateGroupPop(true)}>
            <div style={{ marginRight: '24px', paddingTop: '5px' }}>
              <img src={createGroupImg} alt="create game" />
            </div>
            <div style={{ color: '#505866', textAlign: 'left' }}>
              <Typography
                variant="h4Bold"
                sx={{ color: '#1a1e27', marginBottom: '5px' }}
              >
                그룹게임 생성하기
              </Typography>
              <Typography variant="body1" style={{ color: '#505866' }}>
                4명보다 인원이 더 많다면
                <br />
                그룹게임으로 진행해보세요!
              </Typography>
            </div>
          </ContentsWrap>
          <ContentsWrap
            item
            xs={5.8}
            sx={{ height: '172px' }}
            onClick={goGameGuide}
          >
            <div>
              <img src={guide} alt="Golfun guide" />
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                골펀 가이드
              </Typography>
            </div>
          </ContentsWrap>
          <ContentsWrap
            item
            xs={5.8}
            sx={{ height: '172px' }}
            onClick={joinGame}
          >
            <div>
              <img src={joinImg} alt="Join game" />
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                게임 참여하기
              </Typography>
            </div>
          </ContentsWrap>
        </Grid>

        {(joinGameList.length !== 0 || GGCD) && (
          <GameStatus>
            <img src={golinee} alt="Golinee" onClick={() => setGameBar(true)} />
          </GameStatus>
        )}
      </Box>
      {gameBar && (
        <Dimmed>
          <PopupLayer>
            <PopupTitle variant="body1">
              현재 생성되어있는 게임방입니다.
              <br />
              돌아가려는 게임방을 선택해주세요.
            </PopupTitle>
            {joinGameList.map((v: any, i: number) => {
              return (
                <GameListItem key={i} onClick={() => joinPreGame('P', v)}>
                  <GameItemTitle>{v.room_title}</GameItemTitle>
                  <GameItemType>{gameTypeTranslate(v.game_type)}</GameItemType>
                  {v.group_code !== null && <GameItemProps>그룹</GameItemProps>}
                  <GameItemProps>
                    {v.now_participant_count}/{v.max_player_count}명
                  </GameItemProps>
                  <GameListDel
                    src={icnClose}
                    onClick={(e) => {
                      e.stopPropagation();
                      //ejectPlayer(v.participant_id);
                      setEjectParticipantID(v.participant_id);
                      setEjectPlayerPop(true);
                    }}
                    alt="delete"
                  />
                </GameListItem>
              );
            })}
            {GGCD && (
              <GameListItem onClick={() => joinPreGame('G', GGCD)}>
                <GameItemProps className="group">게임그룹</GameItemProps>
                <GameItemTitle>{GGND}</GameItemTitle>
                <GameListDel
                  src={icnClose}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEjectGroupPop(true);
                  }}
                  alt="delete"
                />
              </GameListItem>
            )}
            <PopupClose onClick={() => setGameBar(false)}>닫기</PopupClose>
          </PopupLayer>
        </Dimmed>
      )}
      {ejectGroupPop && (
        <DimmedConfirm
          title="그룹 떠나기"
          subtitle={`그룹을 떠날경우 다시 접속하기 위해서는\n초대를 다시 받아야 합니다.\n정말로 떠나시겠습니까?`}
          loc="middle"
          symbol="crying"
          buttontitle="취소"
          handleEvent={() => setEjectGroupPop(false)}
          buttontitle2="나가기"
          handleEvent2={() => ejectGroup()}
        />
      )}
      {ejectPlayerPop && (
        <DimmedConfirm
          title="파티 떠나기"
          subtitle={`파티를 떠날 경우 파티의 스코어 정보가 삭제됩니다.\n정말로 떠나시겠습니까?`}
          loc="middle"
          symbol="crying"
          buttontitle="취소"
          handleEvent={() => setEjectPlayerPop(false)}
          buttontitle2="삭제하기"
          handleEvent2={() => ejectPlayer(ejectParticipantID)}
        />
      )}
      {createGroupPop && (
        <DimmedConfirm
          title="그룹게임 생성"
          subtitle={`다수의 파티를 그룹으로 설정하는 기능입니다.\n일반파티는 상단의 게임 생성하기를 눌러 생성해주세요.`}
          loc="middle"
          symbol="smile"
          buttontitle="뒤로가기"
          handleEvent={() => setCreateGroupPop(false)}
          buttontitle2="그룹 생성"
          handleEvent2={() => createGroup()}
        />
      )}
      {deleteGamePop && (
        <DimmedConfirm
          title="정말로 게임을 종료하시겠어요?"
          subtitle="종료하시면 해당 진행된 게임정보는 저장되지 않습니다."
          loc="middle"
          buttontitle="게임 종료하기"
          handleEvent={() => deleteGame()}
          buttontitle2="뒤로가기"
          handleEvent2={() => setDeleteGamePop(false)}
        />
      )}
      <BottomNavigation loc="clubhouse" />
    </Container>
  );
}

const TopArea = styled(Grid)`
  width: 100%;
  height: 50px;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 36px;

  img {
    width: 90px;
    height: 41px;
  }
  div > img {
    width: 44px;
  }
`;

const MemberWrap = styled.div`
  width: 100%;
  padding: 10px;
  margin: 4px 4px 16px;
  display: flex;
  border-radius: 36px;
  background-color: #fafafb;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

const MyImage = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 48px;

  background: no-repeat center/100%;
  background-size: cover;
`;

const ContentsWrap = styled(Grid)`
  width: 100%;
  padding: 70px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 36px;
  background-color: #fafafb;
  overflow: hidden;
`;

const MemberInfoBox = styled.div`
  width: 90px;
  margin-right: 3px;
  padding: 7px 11px;
  border-radius: 8px;
  //background-color: #e7e9ec;
`;

const ViewScore = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.span`
  position: absolute;
  top: -24px;
  left: -24px;
  width: 24px;
  height: 24px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border-radius: 12px;

  img {
    width: 24px;
  }
`;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000a;
  z-index: 500;
`;

const PopupLayer = styled.div`
  position: absolute;
  bottom: 0;
  max-width: 500px;
  width: 100%;
  padding: 24px 22px 34px;
  border-radius: 20px 20px 0 0;
  background-color: #fff;
`;

const PopupTitle = styled(Typography)`
  margin-bottom: 16px;
  text-align: center;
`;

const GameListItem = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: #fafafb;
`;

const GameItemTitle = styled.div`
  border-radius: 8px;

  font-size: 14px;
  font-weight: 500;
`;

const GameItemType = styled.div`
  height: 24px;
  border-radius: 8px;
  background-color: #e0fce5;
  padding: 2px 6px;
  margin-left: 8px;

  font-size: 12px;
  font-weight: bold;
  color: #1fb04b;
`;

const GameItemProps = styled.div`
  height: 24px;
  border-radius: 8px;
  background-color: #e7e9ec;
  padding: 2px 6px;
  margin-left: 4px;

  font-size: 12px;
  font-weight: bold;
  color: #6d7582;

  &.group {
    margin-left: 0;
    margin-right: 8px;

    background-color: #e0ebfc;
    color: #3162d2;
  }
`;

const GameListDel = styled.img`
  position: absolute;
  top: 15px;
  right: 16px;
`;

const PopupClose = styled.div`
  width: 100%;

  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
`;

const GameStatus = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 167px;
  right: -17px;
  width: 70px;
  min-height: 70px;
  padding: 5px 0 5px 20px;
  border-radius: 15px 0 0 15px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #1fb04b;
  transition: all 300ms;

  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #fff0;

  & > img {
    width: 33px;
    margin-right: 8px;
  }

  div {
    //height: 70px;
    padding: 8px 0 8px 5px;
    white-space: nowrap;
  }

  &.extension {
    width: 204px;
    padding-left: 12px;
    color: #fff;
  }
`;
