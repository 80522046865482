import React, { useEffect, useState } from 'react';
import { Box, Container, TextField, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import backspace from '../assets/images/icn_backerase.svg';
import backspaceOn from '../assets/images/icn_backerase_on.svg';
import Field from '../assets/images/icn_field.svg';
import Screen from '../assets/images/icn_screen.svg';
import ImgWrite from '../assets/images/write.svg';
import { BottomButton } from '../components/atoms/BottomButton';
import { InputBox } from '../components/atoms/InputBox';
import { TopNavigation } from '../components/atoms/TopNavigation';
import { useSiteState } from '../contexts/SiteContext';
import DimmedCCList from './Record/DimmedCCList';

export default function WriteScore() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [score, setScore] = useState(0);
  const [roundType, setRoundType] = useState('1');
  const [memo, setMemo] = useState('');
  const [playdate, setPlaydate] = useState(new Date());
  const [CCId, setCCId] = useState(0);
  const [CCName, setCCName] = useState('');
  const [CClistView, setCClistView] = useState(false);

  const keyPress = (nm: string) => {
    if (nm === 'empty') {
      setScore(0);
      return;
    }

    if (nm === 'del') {
      if (score !== 0) {
        const txt = String(score);
        if (txt.length === 1) {
          setScore(0);
        } else {
          setScore(Number(txt.slice(0, txt.length - 1)));
        }
      }
      return;
    }

    if (score === 0) {
      setScore(Number(nm));
    } else {
      setScore(Number(score + nm));
    }
  };

  const nextPage = () => {
    setPage(2);
  };

  const inputScore = () => {
    restApi
      .post('/user/handwriting-score', {
        score,
        play_datatime: moment.utc(playdate).format('YYYYMMDD'),
        field_type_code: roundType,
        golf_club_id: CCId ? CCId : null,
        play_ground: CCName,
        memo,
      })
      .then((e: any) => {
        const { data } = e;
        if (data.status_code === '200') {
          alert('데이터 등록이 완료되었습니다.');
          navigate('/roundrecord');
        } else {
          alert('데이터 등록이 실패하었습니다.');
          navigate('/roundrecord');
        }
      });
  };

  const viewCCList = () => {
    setCClistView(true);
  };

  const selectedName = (t: string) => {
    setCCName(t);
    setCClistView(false);
  };
  const selectedCCId = (n: [number, string]) => {
    setCCId(n[0]);
    setCCName(n[1]);
    setCClistView(false);
  };

  return (
    <Container>
      <TopNavigation
        type={page === 1 ? 'close' : 'back'}
        handleEvent={() => navigate('/roundrecord')}
      >
        스코어 기록하기
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {page === 1 && (
          <>
            <ScoreInputBox>
              <ScoreInput className={score === 0 ? 'none' : ''}>
                {score === 0 ? '스코어를 입력하세요' : score}
              </ScoreInput>
            </ScoreInputBox>
            <NumberKeyboard>
              <KeyboardItem onClick={() => keyPress('1')}>1</KeyboardItem>
              <KeyboardItem onClick={() => keyPress('2')}>2</KeyboardItem>
              <KeyboardItem onClick={() => keyPress('3')}>3</KeyboardItem>
              <KeyboardItem onClick={() => keyPress('4')}>4</KeyboardItem>
              <KeyboardItem onClick={() => keyPress('5')}>5</KeyboardItem>
              <KeyboardItem onClick={() => keyPress('6')}>6</KeyboardItem>
              <KeyboardItem onClick={() => keyPress('7')}>7</KeyboardItem>
              <KeyboardItem onClick={() => keyPress('8')}>8</KeyboardItem>
              <KeyboardItem onClick={() => keyPress('9')}>9</KeyboardItem>
              <KeyboardItem
                onClick={() => keyPress('empty')}
                style={{ color: '#b1b8c0' }}
              >
                취소
              </KeyboardItem>
              <KeyboardItem onClick={() => keyPress('0')}>0</KeyboardItem>
              <KeyboardItem onClick={() => keyPress('del')}>
                <img src={backspace} alt="Delete" />
              </KeyboardItem>
            </NumberKeyboard>
          </>
        )}
        {page === 2 && (
          <>
            <ScoreValue variant="h2Bold">{score}타</ScoreValue>

            <MenuList>
              <MenuName>날짜</MenuName>
              <MenuValue>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePickerStyled
                    views={['year', 'month', 'day']}
                    format={'YYYY월 MM월 DD일'}
                    value={moment.utc(playdate)}
                    onChange={(d: any) => setPlaydate(d)}
                  />
                </LocalizationProvider>
              </MenuValue>
            </MenuList>
            <MenuList>
              <MenuName>라운드유형</MenuName>
              <MenuValue>
                <SelectItem
                  onClick={() => setRoundType('1')}
                  className={roundType === '1' ? 'fOn' : ''}
                >
                  필드
                </SelectItem>
                <SelectItem
                  onClick={() => setRoundType('2')}
                  className={roundType === '2' ? 'sOn' : ''}
                >
                  스크린
                </SelectItem>
              </MenuValue>
            </MenuList>
            <MenuList>
              <MenuName>라운드장소</MenuName>
              <MemoInput
                id="location"
                placeholder="라운드 장소를 입력해주세요."
                value={CCName}
                onClick={viewCCList}
                readOnly
              />
            </MenuList>
            <MenuList>
              <MenuName>메모</MenuName>
              <MemoInput
                id="memo"
                placeholder="메모를 입력해주세요."
                value={memo}
                onChange={(d: any) => setMemo(d.target.value)}
                multiple
              />
            </MenuList>
          </>
        )}
        <BottomButton
          txt={page === 1 ? '다음' : '저장하기'}
          disabled={!score}
          loc="bottom"
          handleEvent={page === 1 ? nextPage : inputScore}
        />
        {CClistView && (
          <DimmedCCList
            CCName={(CC: string) => selectedName(CC)}
            CCID={(CC: [number, string]) => selectedCCId(CC)}
            clsView={() => setCClistView(false)}
          />
        )}
      </Box>
    </Container>
  );
}

const ScoreInputBox = styled.div`
  width: 100%;
  margin-top: 170px;
`;
const ScoreInput = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #505866;
  text-align: center;
  &.none {
    color: #b1b8c0;
  }
`;

const NumberKeyboard = styled.div`
  position: fixed;
  bottom: 140px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  text-align: center;
`;
const KeyboardItem = styled.div`
  height: 50px;
  padding: 3px;
  flex: 0 1 30%;

  font-size: 18px;
  font-weight: 500;
  color: #1a1e27;
  line-height: 50px;
`;

const ScoreValue = styled(Typography)`
  width: calc(100% - 28px);
  margin-bottom: 50px;
`;

const MenuList = styled.div`
  display: flex;
  align-items: center;

  width: calc(100% - 28px);
  height: 34px;
  margin: 7px 0;
`;
const MenuName = styled.div`
  width: 110px;

  font-size: 14px;
  font-weight: bold;
  text-align: left;
`;
const MenuValue = styled.div`
  display: flex;
  flex: 1 1;
`;
const DatePickerStyled = styled(DatePicker)`
  > div {
    border: unset;
  }
  input {
    padding: 0;
  }
  fieldset {
    border: none;
  }
`;

const SelectItem = styled.div`
  width: 72px;
  height: 34px;
  margin-right: 8px;
  border-radius: 8px;
  background-color: #e7e9ec;

  font-size: 12px;
  font-weight: 500;
  color: #505866;
  line-height: 34px;
  text-align: center;

  &.fOn {
    color: #1fb04b;
    background-color: #e0fce5;
  }
  &.sOn {
    color: #3162d2;
    background-color: #e0ebfc;
  }
`;

const MemoInput = styled.input`
  width: 60%;
  font-size: 14px;
  font-weight: 500;
  color: #505866;
  border: 0;

  &::placeholder {
    color: #b1b8c0;
  }
`;

const BottomContents = styled.div`
  position: fixed;
  max-width: 460px;
  width: 100%;
  bottom: 0;
`;
