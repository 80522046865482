import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  id: string;
  title: string;
  value: string | undefined;
  placehold: string;
  handleEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
  message: string;
  pwView?: boolean | false;
}

export const InputBox: React.FC<Props> = ({
  id,
  title,
  value,
  placehold,
  handleEvent,
  message,
  pwView,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <div style={{ width: '100%' }}>
      <InputTitle variant="body1">{title}</InputTitle>
      <OutlinedInput
        id={id}
        value={value}
        onChange={handleEvent}
        type={showPassword || !pwView ? 'text' : 'password'}
        placeholder={placehold}
        error={!!message}
        endAdornment={
          pwView && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                color="info"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
      />
      <AlertMessage>{message}</AlertMessage>
    </div>
  );
};

export default InputBox;

const InputTitle = styled(Typography)`
  margin-left: 12px;

  :not(:first-child) {
    margin-top: 10px;
  }
`;

const AlertMessage = styled.div`
  height: 15px;
  margin-left: 12px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #c13537;
`;
