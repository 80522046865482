import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  ComposedChart,
  Line,
  Cell,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';
import restApi from '../../apis/apiAxios';
import { isScoreValue } from '../../components/blocks/format';

interface Igir {
  all_time_rate: number;
  last_5_games_rate: number;
}

export default function Analysis() {
  const [listType, setListType] = useState('1');
  const [series, setSeries] = useState([]);
  const [distribution, setDistribution] = useState([]);
  const [average, setAverage] = useState([]);
  const [gir, setGir] = useState<Igir>({
    all_time_rate: 0,
    last_5_games_rate: 0,
  });

  const formatDate = (strDate: string) => {
    return moment(strDate).format('MM.DD');
  };

  const pageSet = (n: string) => {
    setSeries([]);
    setDistribution([]);
    setAverage([]);
    setGir({ all_time_rate: 0, last_5_games_rate: 0 });
    setListType(n);
  };

  useEffect(() => {
    restApi
      .get('/user/round-history/analytics', {
        params: {
          field_type_code: listType,
        },
      })
      .then((e) => {
        const { data } = e;

        if (data.status_code === '200') {
          const gameDatetimeArray = data.result_data.analytics_list.sort(
            (a: any, b: any) =>
              new Date(a.game_datetime).getTime() -
              new Date(b.game_datetime).getTime(),
          );

          setSeries(gameDatetimeArray);
          setDistribution(
            data.result_data.score_analytics_list.sort((a: any, b: any) => {
              return a.name - b.name;
            }),
          );
          setAverage(
            data.result_data.analytics_by_par_score_list
              .sort((a: any, b: any) => {
                return a.par - b.par;
              })
              .map((item: any) => {
                const { par, ...rest } = item;
                return { name: par, ...rest };
              }),
          );
        }
      });

    restApi
      .get('/user/round-history/on-green-rate', {
        params: {
          field_type_code: listType,
        },
      })
      .then((e) => {
        const { data } = e;

        if (data.status_code === '200') {
          setGir(data.result_data);
        }
      });
  }, [listType]);

  return (
    <>
      <RoundListTab>
        <RoundTypeList>
          <RoundType
            className={listType === '1' ? 'on' : ''}
            onClick={() => pageSet('1')}
          >
            필드
          </RoundType>
          <RoundType
            className={listType === '2' ? 'on' : ''}
            onClick={() => pageSet('2')}
          >
            스크린
          </RoundType>
        </RoundTypeList>
      </RoundListTab>
      {gir.all_time_rate !== 0 && (
        <>
          <ContnentTitle variant="h4Bold">그린 적중률</ContnentTitle>
          <ResponsiveContainer
            width="100%"
            style={{ backgroundColor: '#fafafb', borderRadius: '16px' }}
          >
            <GIRData>
              <div>
                <TimeState variant="body1">전체 경기</TimeState>
                <Per
                  variant="h1Bold"
                  sx={{ color: listType === '2' ? '#3162d2' : '#1fb04b' }}
                >
                  {gir.all_time_rate.toFixed(1)}%
                </Per>
              </div>
              <div>
                <TimeState variant="body1">최근 5경기</TimeState>
                <Per
                  variant="h1Bold"
                  sx={{ color: listType === '2' ? '#3162d2' : '#1fb04b' }}
                >
                  {gir.last_5_games_rate.toFixed(1)}%
                </Per>
              </div>
            </GIRData>
          </ResponsiveContainer>
        </>
      )}
      {series.length !== 0 && (
        <>
          <ContnentTitle variant="h4Bold">최근 5경기 추이</ContnentTitle>
          <ResponsiveContainer
            width="100%"
            height={200}
            style={{ backgroundColor: '#fafafb', borderRadius: '16px' }}
          >
            <AreaChart
              data={series}
              margin={{
                top: 30,
                right: 30,
                left: 30,
                bottom: 15,
              }}
            >
              <CartesianGrid
                strokeDasharray="5 3"
                horizontal={false}
                stroke="#e7e9ec"
              />
              <XAxis
                dataKey="game_datetime"
                axisLine={false}
                tickLine={false}
                tickFormatter={formatDate}
              />
              <YAxis
                domain={[
                  series.reduce((min: any, item: any) => {
                    return item.score < min ? item.score : min;
                  }, Infinity) - 20,
                  series.reduce((max: any, item: any) => {
                    return item.score > max ? item.score : max;
                  }, -Infinity) + 20,
                ]}
                hide
              />
              <Area
                type="monotone"
                dataKey="score"
                stroke={listType === '2' ? '#3162d2' : '#1fb04b'}
                fill={listType === '2' ? '#3162d244' : '#1fb04b44'}
                dot={{
                  r: 4,
                  strokeWidth: 1.7,
                  fill: '#fff',
                }}
                label={(props: any) => {
                  return (
                    <text
                      x={props.x}
                      y={props.y - 15}
                      fontSize="14px"
                      fill={listType === '2' ? '#3162d2' : '#1fb04b'}
                      width="65"
                      textAnchor="middle"
                      alignmentBaseline="middle"
                    >
                      {props?.value}
                    </text>
                  );
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </>
      )}
      {distribution.length !== 0 && (
        <>
          <ContnentTitle variant="h4Bold">점수분포</ContnentTitle>
          {/*{roundList?.score_analytics_list.map((d: any, i: number) => (
        <RoundList key={i}>
          <RoundInfoStyled>{d.name}</RoundInfoStyled>
          <RoundInfoStyled>{d.count}</RoundInfoStyled>
          <RoundInfoStyled>{d.rate}</RoundInfoStyled>
        </RoundList>
      ))}*/}
          <ResponsiveContainer
            width="100%"
            height={240}
            style={{ backgroundColor: '#fafafb', borderRadius: '16px' }}
          >
            <BarChart
              width={500}
              height={300}
              data={distribution}
              margin={{
                top: 30,
                right: 30,
                left: 30,
                bottom: 25,
              }}
            >
              <defs>
                <linearGradient id="bgColor1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0" stopColor="rgba(31, 176, 75, 0.65)" />
                  <stop offset="1" stopColor="rgba(31, 176, 75, 0.1)" />
                </linearGradient>
                <linearGradient id="bgColor2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0" stopColor="rgba(49, 98, 210, 0.68)" />
                  <stop offset="1" stopColor="rgba(49, 98, 210, 0.1)" />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDasharray="5 2"
                vertical={false}
                stroke="#e7e9ec"
              />
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                tickFormatter={() => {
                  return '';
                }}
              />
              <Bar
                dataKey="rate"
                fill={listType === '2' ? 'url(#bgColor2)' : 'url(#bgColor1)'}
                radius={[8, 8, 0, 0]}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                label={(props: any) => {
                  return (
                    <g>
                      <rect
                        width={props.width}
                        height="4"
                        fill={isScoreValue(props.name).color}
                        x={props.x}
                        y={190}
                        rx="2"
                        ry="2"
                      />
                      <text
                        x={props.width / 2 + props.x}
                        y={205}
                        fontSize="12px"
                        fill="#505866"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                      >
                        {isScoreValue(props?.name).txt}
                      </text>
                      <text
                        x={props.width / 2 + props.x}
                        y={220}
                        fontSize="14px"
                        fontWeight="bold"
                        fill="#0f0f0f"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                      >
                        {Math.floor(props?.value)}%
                      </text>
                    </g>
                  );
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </>
      )}

      {average.length !== 0 && (
        <>
          <ContnentTitle variant="h4Bold">파(Par)별 평균 점수</ContnentTitle>
          <ResponsiveContainer
            width="100%"
            height={240}
            style={{ backgroundColor: '#fafafb', borderRadius: '16px' }}
          >
            <ComposedChart
              width={500}
              height={300}
              data={average}
              margin={{
                top: 30,
                right: 30,
                left: 30,
                bottom: 25,
              }}
            >
              <defs>
                <linearGradient id="par0" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0" stopColor="#f4df65" />
                  <stop offset="1" stopColor="rgba(243, 224, 101, 0)" />
                </linearGradient>
                <linearGradient id="par1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0" stopColor="#f4c169" />
                  <stop offset="1" stopColor="rgba(245, 193, 107, 0)" />
                </linearGradient>
                <linearGradient id="par2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0" stopColor="#53b5e0" />
                  <stop offset="1" stopColor="rgba(83, 181, 224, 0)" />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDasharray="5 2"
                vertical={false}
                stroke="#e7e9ec"
              />
              <XAxis
                dataKey="par"
                axisLine={false}
                tickLine={false}
                tickFormatter={() => {
                  return '';
                }}
              />
              <YAxis
                domain={[0, 6]}
                axisLine={false}
                tickLine={false}
                width={10}
                ticks={[1, 2, 3, 4, 5, 6]}
              />
              <Bar dataKey="average_score" barSize={36} radius={[8, 8, 0, 0]}>
                {average.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(#par${index})`} />
                ))}
              </Bar>
              <Line
                dataKey="name"
                stroke="#fff0"
                dot={false}
                label={(props: any) => {
                  return (
                    <g>
                      <rect
                        width={28}
                        height="4"
                        fill="#000"
                        x={props.x - 14.5}
                        y={props.y}
                        rx="2"
                        ry="2"
                      />
                      <text
                        x={props.x}
                        y={200}
                        fontSize="14px"
                        fontWeight="bold"
                        fill="#1a1e27"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                      >
                        Par {props.value}
                      </text>
                    </g>
                  );
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}
      {gir.all_time_rate === 0 &&
        series.length === 0 &&
        distribution.length === 0 &&
        average.length === 0 && (
          <RoundList>완료된 게임 정보가 없습니다.</RoundList>
        )}
    </>
  );
}

const GIRData = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 30px 0;
`;

const TimeState = styled(Typography)``;
const Per = styled(Typography)``;

const RoundListTab = styled.div`
  width: 100%;
`;
const RoundTypeList = styled.div`
  display: flex;
  margin-bottom: 24px;
  height: 34px;
`;
const RoundType = styled.div`
  flex-grow: 1;

  font-size: 14px;
  color: #505866;
  font-weight: 500;
  text-align: center;

  border-bottom: 2px solid #e7e9ec;

  &.on {
    color: #1fb04b;
    font-weight: bold;
    border-color: #1fb04b;
  }
`;

const RoundList = styled.div`
  width: 100%;
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 16px;
  background-color: #fafafb;
`;

const ContnentTitle = styled(Typography)`
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
`;
