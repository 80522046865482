import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1Bold: React.CSSProperties;
    h2Bold: React.CSSProperties;
    h3Bold: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h5Bold: React.CSSProperties;
    body1Bold: React.CSSProperties;
    body2Bold: React.CSSProperties;
    body3: React.CSSProperties;
    body3Bold: React.CSSProperties;
    body4: React.CSSProperties;
    body4Bold: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h1Bold: React.CSSProperties;
    h2Bold: React.CSSProperties;
    h3Bold: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h5Bold: React.CSSProperties;
    body1Bold: React.CSSProperties;
    body2Bold: React.CSSProperties;
    body3: React.CSSProperties;
    body3Bold: React.CSSProperties;
    body4: React.CSSProperties;
    body4Bold: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Bold: true;
    h2Bold: true;
    h3Bold: true;
    h4Bold: true;
    h5Bold: true;
    body1Bold: true;
    body2Bold: true;
    body3: true;
    body3Bold: true;
    body4: true;
    body4Bold: true;
  }
}

interface Props {
  children: JSX.Element | JSX.Element[];
}

const MuiContextProvider: React.FC<Props> = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        light: '#6ebf79',
        main: '#6ebf79',
        dark: '#6ebf79',
      },
      info: {
        light: '#b1b8c0',
        main: '#b1b8c0',
        dark: '#b1b8c0',
      },
    },
    typography: {
      htmlFontSize: 12,
      fontFamily: [
        'Pretendard',
        'Apple SD Gothic Neo',
        'arial',
        'sans-serif',
      ].join(','),
      h1: {
        fontWeight: '500',
        fontSize: '36px',
        letterSpacing: '-3',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      h1Bold: {
        fontWeight: 'bold',
        fontSize: '36px',
        letterSpacing: '-3',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      h2: {
        fontWeight: '500',
        fontSize: '24px',
        letterSpacing: '-1.5',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      h2Bold: {
        fontWeight: 'bold',
        fontSize: '24px',
        letterSpacing: '-1.5',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      h3: {
        fontWeight: '500',
        fontSize: '20px',
        letterSpacing: '-1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      h3Bold: {
        fontWeight: 'bold',
        fontSize: '20px',
        letterSpacing: '-1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      h4: {
        fontWeight: '500',
        fontSize: '18px',
        letterSpacing: '-1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      h4Bold: {
        fontWeight: 'Bold',
        fontSize: '18px',
        letterSpacing: '-1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      h5: {
        fontWeight: '500',
        fontSize: '16px',
        letterSpacing: '-1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      h5Bold: {
        fontWeight: 'bold',
        fontSize: '16px',
        letterSpacing: '-1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      body1: {
        fontWeight: '500',
        fontSize: '14px',
        letterSpacing: '-0.1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      body1Bold: {
        fontWeight: 'bold',
        fontSize: '14px',
        letterSpacing: '-0.1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      body2: {
        fontWeight: '500',
        fontSize: '13px',
        letterSpacing: '-0.1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      body2Bold: {
        fontWeight: 'bold',
        fontSize: '13px',
        letterSpacing: '-0.1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      body3: {
        fontWeight: '500',
        fontSize: '12px',
        letterSpacing: '-0.1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      body3Bold: {
        fontWeight: 'bold',
        fontSize: '12px',
        letterSpacing: '-0.1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      body4: {
        fontWeight: '500',
        fontSize: '10px',
        letterSpacing: '-0.1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
      body4Bold: {
        fontWeight: 'bold',
        fontSize: '10px',
        letterSpacing: '-0.1',
        lineHeight: '1.4',
        color: '#0f0f0f',
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            width: '100%',
            margin: '6px 0 4px',
            border: 'solid 1px #b1b8c0',
            borderRadius: '25px',
            backgroundColor: '#fff',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '1',
          },
          input: {
            padding: '15px 24px',
            '::placeholder': {
              color: '#b1b8c0',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            padding: '0',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' && {
                backgroundColor: '#21b04b',
                color: '#fff',
              }),
            boxShadow: 'none',
            borderRadius: '24px',
            padding: '12px 5px',
            fontSize: '16px',
            lineHeight: 1.2,
            ':hover': {
              boxShadow: 'none',
            },
          }),
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            position: 'relative',
            minHeight: '100vh',
            paddingBottom: '120px',
            paddingRight: '20px',
            paddingLeft: '20px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {},
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            //width: '100%',
          },
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiContextProvider;
