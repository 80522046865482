export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isKoreanNotIncluded = (str: string): boolean => {
  const koreanRegex =
    /^[^\uAC00-\uD7A3\u3131-\u3163\uFFA1-\uFFDC\uFF61-\uFF9F]+$/;
  return koreanRegex.test(str);
};

export const isValidPassword = (password: string): boolean => {
  const passwordRegex = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,15}$/;
  return passwordRegex.test(password) && isKoreanNotIncluded(password);
};

export const isNickname = (str: string | undefined): boolean => {
  const nicknameRegex = /^[가-힣a-zA-Z]{2,12}$/;
  return nicknameRegex.test(<string>str);
};

export const isImage = (file: any) => {
  // Get the file extension
  const extension = file.name.split('.').pop().toLowerCase();

  // Define the supported image file extensions
  const supportedExtensions = ['jpg', 'jpeg', 'png', 'heic'];

  // Check if the file extension is in the supported extensions array
  if (supportedExtensions.includes(extension)) {
    return true;
  } else {
    return false;
  }

  // Check the file's MIME type
  const mimeType = file.type.toLowerCase();
  return mimeType.startsWith('image/');
};

export const isScoreValue = (score: any) => {
  switch (score) {
    case -6:
      return { txt: '피닉스', color: '#7742ef' };
    case -5:
      return { txt: '오스트리치', color: '#7742ef' };
    case -4:
      return { txt: '콘도르', color: '#7742ef' };
    case -3:
      return { txt: '알바트로스', color: '#7742ef' };
    case -2:
      return { txt: '이글', color: '#3169f8' };
    case -1:
      return { txt: '버디', color: '#54b4e1' };
    case 0:
      return { txt: '파', color: '#56be84' };
    case 1:
      return { txt: '보기', color: '#f3e065' };
    case 2:
      return { txt: '더블', color: '#f4c16b' };
    case 3:
      return { txt: '트리플', color: '#e96f2c' };
    case 4:
      return { txt: '쿼드로플', color: '#d91515' };
    case 5:
      return { txt: '퀀튜플', color: '#860000' };
    case 6:
      return { txt: '섹튜플', color: '#860000' };
    case 7:
      return { txt: '셉튜플', color: '#860000' };
    default:
      return score;
  }
};

export const gameTypeTranslate = (txt: string) => {
  switch (txt) {
    case 'stroke':
      return '스트로크';
    case 'drawing':
      return '뽑기';
    case 'chip':
      return '칩';
    case 'normal':
      return '일반';
    default:
      return txt;
  }
};
