import React, { useEffect, useState } from 'react';
import { Box, Container, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import MemberImg from '../assets/images/member.png';
import { BottomButton } from '../components/atoms/BottomButton';
import { DimmedConfirm } from '../components/atoms/DimmedPopup';
import ToastPopup from '../components/atoms/ToastPopup';
import { TopNavigation } from '../components/atoms/TopNavigation';
import { useSiteState } from '../contexts/SiteContext';
import { useSocketDataState } from '../contexts/SocketDataContext';
import { FriendList } from './Friends/FriendList';

interface IUserInfo {
  email: string;
  login_id: string;
  nickname: string;
  user_id: number;
  user_name: string;
}

export default function GameShare() {
  const navigate = useNavigate();
  const gameCode = localStorage.getItem('room_code');
  const gameID = Number(localStorage.getItem('ri')) || 0;
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [friendList, setFriendList] = useState(false);
  const [toast, setToast] = useState(false);
  const {
    data,
    pid,
    resetChips,
    shuffleStick,
    sendEjectPlayer,
    groupSocketDisconnect,
  } = useSocketDataState();
  const { memberInfo, gameInfo, gameInfoChange } = useSiteState();
  const [courseList, setCourseList] = useState([]);
  const [confirmView, setConfirmView] = useState(false);
  const [selectID, setSelectID] = useState(0);
  //const owner = memberInfo?.user_id === Number(data?.game_room_metadata.owner_user_id);
  const owner = pid === data?.game_room_metadata?.owner_participant_id;
  const myPlayInfo = data?.participant_list?.find(
    (item: any) => item.participant_id === pid,
  );
  const myHoleInfo = myPlayInfo?.hole_id_list || null;
  const [ejectTitle, setEjectTitle] = useState('');
  const playersJoinMax =
    data?.game_room_metadata?.max_player_count ===
    data?.participant_list?.length;
  const playerList = data?.participant_list?.sort(
    (a: any, b: any) => a.participant_id - b.participant_id,
  );

  const handleShare = () => {
    setFriendList(true);
    //copyCode();
  };

  const copyCode = () => {
    const elementText = `${window.location.origin}/clubhouse?game=${gameCode}`;
    if (navigator.share) {
      navigator.share({
        title: 'Golfun 참여하기',
        text: '링크를 클릭하여 게임에 참여해보세요!',
        url: elementText,
      });
    } else {
      navigator.clipboard
        .writeText(elementText)
        .then(() => {
          alert('복사가 완료 되었습니다.');
        })
        .catch((err) => {
          alert('Error in copying text: ' + err);
        });
    }
  };

  const go = () => {
    if (
      data?.game_room_metadata.game_data?.gameType === 'chips' &&
      myPlayInfo.hole_id_list.length === 0 &&
      owner
    ) {
      resetChips();
    }
    if (
      data?.game_room_metadata.game_data?.gameType === 'drawing' &&
      myPlayInfo.hole_id_list.length === 0 &&
      owner
    ) {
      shuffleStick();
    }

    if (
      data?.participant_list.length === 1 ||
      data?.game_room_metadata.game_data?.drawOrder?.length > 0
    ) {
      navigate('/playgame', { replace: true });
    } else {
      navigate('/draworder', { replace: true });
    }
  };

  const ejectPlayerPop = (id: any, nickname: string) => {
    console.log('pid = ', pid);
    const title =
      id === pid
        ? '해당 파티에서 나가시겠습니까 ?'
        : nickname + '님을 게임에서 추방하시겠습니까?';
    setSelectID(id);
    setEjectTitle(title);

    if (owner || id === pid) setConfirmView(true);
  };

  const ejectPlayer = (id: any) => {
    restApi.put('/game/kick-out', { participant_id: id }).then((e) => {
      const { data } = e;
      if (data?.status_code === '200') {
        const payload = {
          game_room_id: gameInfo?.game_room_id,
          participant_id: pid,
          user_nickname: myPlayInfo?.user_nickname,
          message: {
            participant_id: id,
          },
        };
        sendEjectPlayer(payload);
        setToast(true);
        setConfirmView(false);
      }
    });
  };

  const EmptyPlayer = ({ size }: any): any => {
    if (size > 0) {
      const numbers = [];

      for (let i = 1; i <= size; i++) {
        numbers.push(
          <Grid item xs={6} key={i}>
            <PlayerButton
              variant="outlined"
              fullWidth
              onClick={() => handleShare()}
            >
              <AddButton>+</AddButton>
              <PlayerTxt variant="body1">플레이어 초대하기</PlayerTxt>
            </PlayerButton>
          </Grid>,
        );
      }
      return numbers;
    }
    return <></>;
  };

  useEffect(() => {
    groupSocketDisconnect();

    if (!gameCode) {
      return;
    }

    if (localStorage.getItem('accessToken'))
      restApi
        .get(`/user/detail`, {
          params: {
            user_email: localStorage.getItem('user_id'),
          },
        })
        .then((e) => {
          setUserInfo(e.data.result_data);
        });
    restApi
      .get(`/guest/check-room-code`, {
        params: {
          room_code: localStorage.getItem('room_code'),
        },
      })
      .then((e) => {
        const { data } = e;
        localStorage.setItem('ri', data.result_data);
        localStorage.setItem('hs', '1');

        if (data.status_code === '200') {
          restApi
            .get(`/guest/game/detail`, {
              params: {
                game_room_id: data.result_data,
              },
            })
            .then((e) => {
              const { data } = e;
              if (data.status_code === '200') {
                gameInfoChange(data.result_data);
                setCourseList(data.result_data.course_id_list);
              }
            });
        } else {
          restApi
            .delete(
              `/game/invite/room-code?room_code=${localStorage.getItem(
                'room_code',
              )}`,
            )
            .then((e) => {
              const { data } = e;
              if (data.status_code === '200') {
                console.log('친구초대방 삭제');
              }
            });
        }
      });
  }, []);

  useEffect(() => {
    if (myHoleInfo?.length > 1) go();
  }, [myHoleInfo]);

  return (
    <Container component="main">
      <TopNavigation
        type="back"
        subTitle="게임 생성하기"
        handleEvent={() => navigate('/clubhouse')}
      >
        인원 초대하기
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TopCCInfo>
          <HoleInfoWrap>
            <Typography
              variant="h5Bold"
              sx={{ lineHeight: '21px', marginBottom: '0' }}
              mb={0.6}
            >
              {gameInfo?.room_title}
            </Typography>
            <Box sx={{ display: 'inline-flex' }}>
              {gameInfo?.course_id_list && (
                <HoleInfo>{gameInfo.course_id_list.length * 9}홀</HoleInfo>
              )}
            </Box>
            {courseList?.map((course: any, i: number) => {
              return (
                <Box sx={{ display: 'inline-flex' }} key={course.course_id}>
                  <HoleInfo className={i === 0 ? 'front' : 'back'}>
                    {course.course_name}
                  </HoleInfo>
                </Box>
              );
            })}
          </HoleInfoWrap>
        </TopCCInfo>

        <Grid container spacing={1}>
          {playerList?.map((v: any, i: any) => {
            const exit = v.exit_yn;
            const id = v.participant_id;
            const nickname = v.user_nickname;

            if (!exit && nickname)
              return (
                <Grid item xs={6} key={id}>
                  <PlayerButton
                    variant="outlined"
                    fullWidth
                    onClick={() => ejectPlayerPop(id, nickname)}
                  >
                    <div>
                      <PlayerImg
                        style={{
                          backgroundImage: `url(${
                            v.profile_image_file_url || MemberImg
                          })`,
                        }}
                      />
                    </div>
                    <PlayerTxt variant="body1">{nickname}</PlayerTxt>
                    {pid === id && <div>me</div>}
                  </PlayerButton>
                </Grid>
              );
          })}
          <EmptyPlayer
            size={
              Number(data?.game_room_metadata?.max_player_count) -
              data?.participant_list?.length
            }
          />
        </Grid>

        <AlertMessage
          style={{
            display:
              data?.game_room_metadata?.max_player_count <
              data?.participant_list?.length
                ? 'initial'
                : 'none',
          }}
        >
          설정된 플레이어 수를 초과했습니다. 인원을 확인해주세요.
        </AlertMessage>

        <BottomButton
          txt={`게임시작`}
          disabled={!playersJoinMax}
          loc="bottom"
          handleEvent={go}
        />
      </Box>
      {toast && (
        <ToastPopup
          title="플레이어가 추방되었습니다"
          loc="top"
          icon="red"
          closeHandler={() => setToast(false)}
        />
      )}
      {friendList && gameCode && (
        <FriendList
          clsView={() => setFriendList(false)}
          gameCode={gameCode}
          gameID={gameID}
        />
      )}
      {confirmView && (
        <DimmedConfirm
          title={ejectTitle}
          symbol="smile"
          loc="middle"
          subtitle=""
          buttontitle={'취소하기'}
          handleEvent={() => setConfirmView(false)}
          buttontitle2={selectID === pid ? '게임 나가기' : '추방하기'}
          handleEvent2={() => ejectPlayer(selectID)}
        />
      )}
    </Container>
  );
}

const PlayerButton = styled(Button)`
  flex-direction: column;
  height: 165px;
  background-color: #fafafb;
  border-color: #fafafb;
  text-transform: unset;
`;

const AddButton = styled.div`
  width: 44px;
  height: 44px;
  margin-bottom: 15px;
  border-radius: 22px;
  background-color: #e7e9ec;
  font-size: 35px;
  color: #6d7582;
`;

const TopCCInfo = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  padding: 20px 24px;
  border-radius: 31px;
  background-color: #fafafb;
`;

const HoleInfoWrap = styled.div`
  display: flex;
`;

const HoleInfo = styled.div`
  width: fit-content;
  margin-left: 8px;
  padding: 5px 4px 4px;
  border-radius: 8px;
  background-color: #e0fce5;

  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #1fb04b;

  &.back {
    background-color: #e0ebfc;
    color: #3162d2;
  }
`;
const PlayerImg = styled.div`
  width: 84px;
  height: 84px;
  border-radius: 42px;

  background: no-repeat center/100%;
  background-size: cover;
`;

const PlayerTxt = styled(Typography)`
  margin-top: 4px;
  color: #505866;
`;

const TextBox = styled.p`
  text-align: center;
  margin-block: 5px;
`;

const GameRoomCode = styled.div`
  font-size: 2.3rem;
  text-decoration: underline;
  margin-block: 20px;
`;

const AlertMessage = styled.div`
  height: 15px;
  margin: 20px auto;
  font-size: 12px;
  font-weight: 500;
  color: #c13537;
`;
