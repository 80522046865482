import React, { useState } from 'react';
import { Box, FormControl, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import { BottomButton } from '../components/atoms/BottomButton';
import { InputBox } from '../components/atoms/InputBox';
import { TopNavigation } from '../components/atoms/TopNavigation';
import { useSiteState } from '../contexts/SiteContext';

export default function JoinGame() {
  const navigate = useNavigate();
  const [inviteCode, setInviteCode] = useState<string>(
    localStorage.getItem('room_code') || localStorage.getItem('GGCD') || '',
  );
  const [inviteMessage, setInviteMessage] = useState<string>('');

  const joinGame = () => {
    const isGG = inviteCode.slice(0, 2) === 'G_';

    if (isGG) {
      localStorage.removeItem('room_code');
      localStorage.removeItem('ri');
      restApi
        .post(`/game-room-group/join`, { group_code: inviteCode })
        .then((e) => {
          const { data } = e;
          if (data.status_code === '200') {
            localStorage.setItem('GGCD', data.result_data.group_code);
            localStorage.setItem('GGID', data.result_data.game_room_group_id);
            localStorage.setItem('GGND', data.result_data.group_name);
            navigate('/creategroup');
          } else {
            setInviteMessage(
              data.status_code === '505'
                ? '게임방 정보가 없습니다. 초대 코드를 확인해주세요.'
                : data.status_message,
            );
          }
        });
    } else {
      restApi
        .get(`/guest/check-room-code`, {
          params: {
            room_code: inviteCode,
          },
        })
        .then((e) => {
          if (e.data.status_code === '200') {
            localStorage.setItem('room_code', inviteCode);
            localStorage.setItem('ri', e.data.result_data);
            navigate('/gameshare');
          } else {
            localStorage.removeItem('room_code');
            localStorage.removeItem('ri');
            setInviteMessage(
              e.data.status_code === '505'
                ? '게임방 정보가 없습니다. 초대 코드를 확인해주세요.'
                : e.data.status_message,
            );
          }
        });
    }
  };

  return (
    <Container component="main">
      <TopNavigation type="close" handleEvent={() => navigate('/clubhouse')}>
        게임 참여하기
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TopInfo>
          공유받은 초대코드를 입력해주세요.
          <br />
          초대코드를 입력 후 버튼을 누르면 해당 게임으로 들어갈 수 있습니다.
        </TopInfo>

        <InputBox
          id="roomcode"
          title="초대코드"
          value={inviteCode}
          placehold="초대코드를 입력해주세요."
          handleEvent={(event) => {
            setInviteCode(event.target.value);
          }}
          message={inviteMessage}
        />

        <BottomButton
          txt="게임 참여하기"
          disabled={!inviteCode}
          handleEvent={joinGame}
        />
      </Box>
    </Container>
  );
}

const TopInfo = styled.div`
  width: 100%;
  padding: 19px 16px 18px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #fafafb;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.1px;
  text-align: left;
  color: #0f0f0f;
`;
