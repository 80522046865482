import React, { useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import { BottomButton } from '../components/atoms/BottomButton';
import { InputBox } from '../components/atoms/InputBox';
import { TopNavigation } from '../components/atoms/TopNavigation';
import { isValidPassword } from '../components/blocks/format';
import { useSiteState } from '../contexts/SiteContext';

interface SignupFormData {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
}

const ChangePassword: React.FC = () => {
  const { memberInfo } = useSiteState();
  const navigate = useNavigate();
  const [pw, setPw] = useState<string>('');
  const [prePw, setPrePw] = useState<string>('');
  const [pwMessage, setPwMessage] = useState<string>('');
  const [prePwMessage, setPrePwMessage] = useState<string>('');

  const [formData, setFormData] = useState<SignupFormData>({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
  };

  const send = () => {
    console.log(prePw, pw);

    const pwChk = isValidPassword(pw);
    if (!pwChk) {
      setPwMessage('비밀번호 형식이 맞지 않습니다.');
      return pwChk;
    }
    if (prePw === pw) {
      setPwMessage(
        '새로운 비밀번호가 현재 비밀번호와 동일합니다. 확인해주세요.',
      );
      return;
    }

    restApi
      .put('/user/update-password', {
        user_id: memberInfo?.user_id,
        current_password: prePw,
        after_password: pw,
      })
      .then((e) => {
        console.log(e);
        const { data } = e;
        if (data.status_code === '2007') {
          setPrePwMessage(data.status_message);
          return;
        }
        if (data.status_code === '200' && data.result_data === 1) {
          alert('비밀번호가 정상적으로 변경되었습니다.');
          setPw('');
          setPrePw('');
          navigate('/setting');
        }
      });
  };

  return (
    <Container>
      <TopNavigation type="back" handleEvent={() => navigate('/setting')}>
        비밀번호 변경
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
          <>
            <InputBox
              id="password"
              title="현재 비밀번호"
              value={prePw}
              placehold="영문, 숫자 2가지 조합 8~15자"
              handleEvent={(event) => {
                setPrePw(event.target.value);
                setPrePwMessage('');
              }}
              pwView={true}
              message={prePwMessage}
            />
            <InputBox
              id="password"
              title="새로운 비밀번호"
              value={pw}
              placehold="영문, 숫자 2가지 조합 8~15자"
              handleEvent={(event) => {
                setPw(event.target.value);
                setPwMessage('');
              }}
              pwView={true}
              message={pwMessage}
            />
          </>
        </form>

        <BottomButton
          txt="비밀번호 변경하기"
          disabled={!prePw || !pw}
          handleEvent={send}
        />
      </Box>
    </Container>
  );
};

export default ChangePassword;

const TopInfo = styled.div`
  width: 100%;
  padding: 19px 16px 18px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #fafafb;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.1px;
  text-align: left;
  color: #0f0f0f;
`;
