import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/images/golfun.svg';

export default function OnBoarding() {
  const navigate = useNavigate();

  const go = (url: string) => {
    navigate(`/${url}`);
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '75vh',
        }}
      >
        <LogoArea src={logo} alt="logo" />
        <Typography
          variant="h4"
          sx={{ mt: '18px', textAlign: 'center', fontWeight: 'bold' }}
        >
          골프게임부터 점수관리까지
          <br />
          <span style={{ color: '#1fb04b' }}>골펀</span>과 함께해요!
        </Typography>
      </Box>
      <BottomContents>
        <Button variant="contained" fullWidth onClick={() => go('signup')}>
          계정 만들기
        </Button>
        <GrayButton
          variant="contained"
          fullWidth
          onClick={() => go('nonmember')}
        >
          비회원으로 계속하기
        </GrayButton>
        <Typography variant="h5">
          계정이 이미 있다면?{' '}
          <Link to="/signin" style={{ display: 'contents' }}>
            로그인하기
          </Link>
        </Typography>
      </BottomContents>
    </Container>
  );
}

const LogoArea = styled.img`
  width: 68%;
`;

const GrayButton = styled(Button)`
  margin: 8px 0 23px;
  background-color: #fafafb;
  color: #0f0f0f;
`;

const BottomContents = styled.div`
  width: 90%;
  max-width: 460px;
  position: fixed;
  bottom: 48px;
  text-align: center;
`;
