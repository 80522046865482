import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import restApi from '../apis/apiAxios';
import { searchParams } from '../components/blocks/CommonFn';

export interface IMemberInfo {
  user_id?: number;
  user_name?: string;
  nickname?: string;
  email?: string;
  game_data?: any;
  average_score?: number;
  life_best_score?: number;
  profile_image_file_id?: number;
  profile_image_url?: string;
  profile_image_file_url?: string;
}

interface IHoleList {
  hole_id: number;
  golf_club_id: number;
  created_manager_id: number;
  created_datetime: string;
  update_manager_id: number;
  update_datetime: string;
  is_use: string;
  par: number;
  hdcp: number;
  hole_no: number;
  distance: number;
  is_custom: string;
}

interface ICourse_list {
  course_id: number;
  hole_list: IHoleList[] | any;
}

export interface IGameInfo {
  course_id_list: ICourse_list[] | any;
  game_room_id: number;
  golf_club_id: number;
  player_count: number;
  room_code: string;
  room_content: string;
  room_title: string;
  pid: number;
  owner_user_id: number;
  game_data: any;
}

type ILanguage = 'KR' | 'EN' | 'JP' | 'CN';

interface ISiteContext {
  language: ILanguage;
  languageChange: (lang: any) => void;
  memberInfo: IMemberInfo | undefined;
  memberInfoChange: (d: IMemberInfo) => void;
  gameInfo: IGameInfo | any;
  gameInfoChange: (d: any) => void;
  room: string;
  isReactNativeWebview: boolean;
  siteVersion: string;
}
const defaultContext: ISiteContext = {
  language: 'KR',
  languageChange: () => {},
  memberInfo: {},
  memberInfoChange: () => {},
  gameInfo: {},
  gameInfoChange: () => {},
  room: '',
  isReactNativeWebview: false,
  siteVersion: '',
};

const SiteContext = React.createContext(defaultContext);

interface Props {
  children: JSX.Element | JSX.Element[];
}

const SiteContextProvider: React.FC<Props> = ({ children }) => {
  const [memberInfo, setMemberInfo] = useState<IMemberInfo>();
  const memberInfoChange = (d: IMemberInfo) => {
    setMemberInfo({ ...memberInfo, ...d });
  };
  const [gameInfo, setGameInfo] = useState<IGameInfo>();
  const gameInfoChange = (d: IGameInfo) => {
    setGameInfo({ ...gameInfo, ...d });
  };
  const [room, setRoom] = useState<string>(searchParams('game') || '');
  const navigate = useNavigate();
  const [isReactNativeWebview, setIsReactNativeWebview] = useState(false);
  const [language, setLanguage] = useState(
    (localStorage.getItem('language') as ILanguage) || 'KR',
  );
  const languageChange = (lang: ILanguage) => {
    localStorage.setItem('language', lang);
    setLanguage(lang);
  };
  const [bgColor, setBgColor] = useState('#1fb04b');
  const bgColorChange = (colorCode: string) => {
    setBgColor(colorCode);
  };
  const siteVersion = '1.0.9';

  useEffect(() => {
    if (!memberInfo && localStorage.getItem('accessToken')) {
      restApi
        .get(`/user/detail`, {
          params: {
            user_email: localStorage.getItem('user_id'),
          },
        })
        .then((e) => {
          const { data } = e;
          if (data.status_code === '505') {
            localStorage.clear();
            navigate('/onboarding');
            return;
          }
          memberInfoChange(data.result_data);
        });
    }

    if (!memberInfo && localStorage.getItem('nickname')) {
      memberInfoChange({ nickname: localStorage.getItem('nickname') || '' });
    }

    const searchGame = searchParams('game');
    if (searchGame) {
      localStorage.setItem('room_code', searchGame);
      setRoom(searchGame);

      if (localStorage.getItem('accessToken')) {
        navigate('/joingame');
      } else {
        //navigate('/nonmember');
        navigate('/onboarding');
      }
    }
    const inviteFriend = searchParams('af');
    if (inviteFriend) {
      if (localStorage.getItem('accessToken')) {
        navigate('/notice');
      } else {
        //navigate('/nonmember');
        navigate('/onboarding');
      }
    }

    // userAgent에 ios // android 가 있는 지 확인해줍니다.
    const iOS = navigator.userAgent.match(/iphone|ipad/i);
    const Android = navigator.userAgent.match(/Android/i);
    localStorage.setItem('device', navigator.userAgent);
    if (iOS) localStorage.setItem('device', 'iOS');
    if (Android) localStorage.setItem('device', 'Android');

    /*if (
      !localStorage.getItem('accessToken') &&
      window.location.pathname === '/clubhouse'
    )
      navigate('/onboarding');*/
  }, []);

  return (
    <SiteContext.Provider
      value={{
        language,
        languageChange,
        memberInfo,
        memberInfoChange,
        gameInfo,
        gameInfoChange,
        room,
        isReactNativeWebview,
        siteVersion,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

const useSiteState = () => useContext(SiteContext);

export { SiteContextProvider, useSiteState };
