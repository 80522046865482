import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import BottomNavigation from '../components/atoms/BottomNavigation';
import { TopNavigation } from '../components/atoms/TopNavigation';
import { useSiteState } from '../contexts/SiteContext';

export default function VersionChk() {
  const navigate = useNavigate();
  const [ver, setVer] = useState('');
  const { siteVersion } = useSiteState();

  const verCall = () => {
    restApi.get('/guest/site-version').then((e) => {
      const { data } = e;
      if (data.status_code === '200') {
        setVer(data.result_data);
      }
    });
  };

  useEffect(() => {
    verCall();
  }, []);

  return (
    <Container component="main">
      <TopNavigation type="back" handleEvent={() => navigate('/setting')}>
        사이트 버전 정보
      </TopNavigation>

      <MenuListWrap>
        <MenuList>
          {/* <Typography variant="h2">APP Version</Typography>
          <br />
          <Typography variant="h5">Ver {ver}</Typography>
          <br />
          <br />*/}
          <Typography variant="h2">Site Version</Typography>
          <br />
          <Typography variant="h5">Ver {ver}</Typography>
          {siteVersion !== ver && (
            <>
              <Typography variant="h5" style={{ marginTop: '20px' }}>
                최신 버전이 아닙니다.
                <br />
                하단 버튼을 클릭하여 버전으로 업데이트 하시기 바랍니다.
              </Typography>
              <Typography
                variant="h5"
                style={{ marginTop: '5px', color: 'blue' }}
                onClick={() => window.location.reload()}
              >
                버전 업데이트
              </Typography>
            </>
          )}
        </MenuList>
      </MenuListWrap>
      <BottomNavigation loc="mypage" />
    </Container>
  );
}

const MenuListWrap = styled.div``;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  padding: 22px 16px 19px 24px;
  border-radius: 10px;
  background-color: #fafafb;
  text-align: center;

  &:first-child {
    margin-top: 32px;
  }
`;
