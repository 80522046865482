import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import restApi from '../../apis/apiAxios';
import close from '../../assets/images/close.svg';

interface Props {
  clsView: () => void;
}

export const DimmedRankingView: React.FC<Props> = (props) => {
  const GGID = localStorage.getItem('GGID');
  const [scoreList, setScoreList]: any = useState([]);

  const callData = () => {
    const tempList: any = [];
    restApi
      .get('/guest/rank/metadata-list', {
        params: { game_room_group_id: GGID },
      })
      .then((e) => {
        const { data } = e;

        setScoreList([]);
        data.result_data.game_room_metadata_list.forEach(
          (v: any, i: number) => {
            const holeList = v.game_room_data.hole_list;

            v.participant_data_list.forEach((p: any, j: number) => {
              const playerHoleData = p.participants_data.hole_id_list;

              let totalScore = 0;
              console.log('playerHoleData = ', playerHoleData);

              if (playerHoleData.length === 0) {
                totalScore = 999;
              } else {
                playerHoleData.forEach((h: any, l: number) => {
                  totalScore += h.shot - holeList[l].par;
                });
              }

              tempList.push({
                rank: '',
                nickname: p.participants_data.user_nickname,
                score: totalScore,
                hole: playerHoleData.length,
              });
            });
          },
        );
        console.log('tempList = ', tempList);
        // tempList를 score에 따라 정렬
        tempList.sort((a: any, b: any) => a.score - b.score);

        // score를 기준으로 rank와 같은 점수를 가진 참가자 수를 저장하는 변수
        let currentRank = 1;
        let currentScore = 999;
        let sameScoreCount = 0;
        let playerRank = 1;

        tempList.forEach((player: any, index: number) => {
          if (player.score !== currentScore) {
            currentRank += sameScoreCount;
            sameScoreCount = 1;
            currentScore = player.score;
            playerRank = currentRank;
          } else {
            sameScoreCount++;
            playerRank = 0;
          }

          player.rank = playerRank;
        });

        setScoreList(tempList);
      });
  };

  useEffect(() => {
    callData();
  }, []);

  return (
    <Dimmed>
      <PopLayer>
        <TopArea>
          <Typography variant="h5Bold" style={{ color: '#0f0f0f' }}>
            점수 현황
          </Typography>
          <ClsButton src={close} onClick={props.clsView} alt="Close" />
        </TopArea>
        <StyledTable stickyHeader sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 30 }} style={{ textAlign: 'center' }}>
                Rank
              </TableCell>
              <TableCell sx={{ width: 85 }} style={{ textAlign: 'center' }}>
                닉네임
              </TableCell>
              <TableCell sx={{ width: 60 }} style={{ textAlign: 'center' }}>
                TOTAL
              </TableCell>
              <TableCell sx={{ width: 30 }} style={{ textAlign: 'center' }}>
                Hole
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ overflowY: 'unset' }}>
            {scoreList &&
              scoreList.map((v: any, i: number) => (
                <TableRow key={i}>
                  <TableCell style={{ textAlign: 'center' }}>
                    {v.rank !== 0 && v.rank}
                  </TableCell>
                  <TableCell>{v.nickname}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {v.hole === 0 ? '-' : v.score}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {v.hole === 0 ? '-' : v.hole}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
      </PopLayer>
    </Dimmed>
  );
};

export default DimmedRankingView;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000a;
  z-index: 500;
`;

const PopLayer = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  height: 90vh;
  padding: 24px 20px 0;
  background-color: #fff;
  border-radius: 32px 32px 0 0;
`;

const TopArea = styled.div``;
const ClsButton = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const StyledTable = styled(Table)`
  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
  }
`;
