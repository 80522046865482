import React, { useEffect, useState } from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Arrow from '../assets/images/icn_arrow_right.svg';
import IconSetting from '../assets/images/icn_setting.svg';
import MemberImg from '../assets/images/member.png';
import BottomNavigation from '../components/atoms/BottomNavigation';
import { searchParams } from '../components/blocks/CommonFn';
import { useSiteState } from '../contexts/SiteContext';

export default function MyPage() {
  const { memberInfo, memberInfoChange } = useSiteState();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');
  const nmNickname = localStorage.getItem('nickname');

  const go = (str: string) => {
    navigate(str);
  };

  useEffect(() => {
    if (!accessToken) {
      if (nmNickname || searchParams('game')) {
        memberInfoChange({ nickname: nmNickname || '' });
      } else {
        navigate('/onboarding');
      }
    }
  }, []);

  return (
    <Container component="main">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <TopArea>
          <Title variant="h3Bold">마이페이지</Title>
          <div>
            <img
              src={IconSetting}
              onClick={() => navigate('/setting')}
              alt="icon"
            />
          </div>
        </TopArea>

        <MemberWrap>
          <img src={memberInfo?.profile_image_url || MemberImg} alt="Member" />
          <div style={{ color: '#505866', width: '40%' }}>
            안녕하세요!{' '}
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {memberInfo?.nickname}
            </Typography>
          </div>
          <MemberInfoBox onClick={() => navigate('/profile')}>
            <ViewScore>프로필 수정</ViewScore>
          </MemberInfoBox>
        </MemberWrap>
      </Box>
      <MenuListWrap>
        {/*<MenuList onClick={() => ready()}>
          <Typography variant="h5">골프 일정</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>*/}
        {/*<MenuList onClick={() => ready()}>
          <Typography variant="h5">나의 골프가방</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>*/}
        <MenuList onClick={() => go('/favoritecc')}>
          <Typography variant="h5">자주가는 골프장</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => go('/friend')}>
          <Typography variant="h5">친구 관리</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
      </MenuListWrap>
      <BottomNavigation loc="mypage" />
    </Container>
  );
}

const TopArea = styled(Grid)`
  width: 100%;
  height: 50px;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 36px;
`;

const Title = styled(Typography)`
  color: #1a1e27;
`;

const MemberWrap = styled.div`
  width: 100%;
  padding: 10px;
  margin: 4px 4px 8px;
  display: flex;
  border-radius: 36px;
  background-color: #fafafb;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  > img {
    width: 64px;
    height: 64px;
    border-radius: 48px;
    background: center center / cover no-repeat;
  }
`;

const MemberInfoBox = styled.div`
  margin-right: 22px;
  padding: 7px 11px;
  border-radius: 8px;
  background-color: #e7e9ec;
`;

const ViewScore = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuListWrap = styled.div``;

const MenuList = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  padding: 22px 16px 19px 24px;
  border-radius: 10px;
  background-color: #fafafb;
`;
