import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../../apis/apiAxios';
import IconCheck from '../../assets/images/icn_checked.svg';
import IconPlus from '../../assets/images/icn_plus.svg';
import IconSearch from '../../assets/images/icon/search_g.svg';
import MemberImg from '../../assets/images/member.png';
import { postMessage } from '../../components/blocks/CommonFn';

interface Props {
  page: string;
  gameCode: string;
  gameID: number;
}

export const SearchFriend: React.FC<Props> = (props) => {
  const [searchString, setSearchString] = useState('');
  const [friendList, setFriendList] = useState([]);
  const inviteType = props.gameCode.slice(0, 1);

  const apiURL =
    inviteType === 'G'
      ? '/game-room-group/invite/nickname-search'
      : '/game/invite/nickname-search';

  const paramsData =
    inviteType === 'G'
      ? { game_room_group_id: props.gameID, nickname: searchString }
      : { game_room_id: props.gameID, nickname: searchString };

  const searchFriends = () => {
    if (!searchString) {
      alert('닉네임을 입력해주세요.');
      return;
    }
    restApi
      .get(apiURL, {
        params: paramsData,
      })
      .then((e) => {
        const { data } = e;
        if (data.status_code === '505') {
          setFriendList([]);
        }
        if (data.status_code === '200') {
          setFriendList(data.result_data);
        }
      });
  };

  const sendInvite = (id: number, dId: any) => {
    restApi.post('/user/friend/invite', { friend_user_id: id }).then((e) => {
      const { data } = e;
      if (data.status_code === '200') {
        searchFriends();
        //친구 초대 push 발송
        JSON.parse(dId).forEach((d: any) => {
          const txt = `{ "dk": "${d}", "roomCode": "0" }`;
          console.log('postMessage > ', txt);
          postMessage('addFriend', txt);
        });
        searchFriends();
        alert('친구초대가 발송 되었습니다.');
        return;
      }
    });
  };

  const inviteGame = (name: string, id: string, userID: number) => {
    const apiURL =
      inviteType === 'G' ? '/game-room-group/invite' : '/game/invite';
    const paramsData =
      inviteType === 'G'
        ? { game_room_group_id: props.gameID, friend_user_id: userID }
        : { game_room_id: props.gameID, user_id_list: [userID] };

    restApi.post(apiURL, paramsData).then((e) => {
      JSON.parse(id).forEach((d: any) => {
        const txt = `{ "dk": "${d}", "roomCode": "${props.gameCode}" }`;
        console.log('postMessage > ', txt);
        postMessage('inviteGame', txt);
      });

      const { data } = e;
      if (data.status_code === '200') {
        alert(name + '님께 게임 초대를 보냈습니다.');
      }
    });
  };

  return (
    <>
      <SearchBarWrap>
        <SearchBar
          id="search-bar"
          className="text"
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          placeholder="닉네임을 입력해주세요."
          style={{ width: '100%' }}
          value={searchString}
        />
        <img src={IconSearch} alt="icon" />
        <SearchButton onClick={searchFriends}>검색</SearchButton>
      </SearchBarWrap>
      {friendList.length === 0 && (
        <FriendList>검색된 결과가 없습니다.</FriendList>
      )}
      {friendList.map((d: any, i) => (
        <FriendList
          key={i}
          id={d.user_id}
          onClick={() =>
            props.page === 'friend' && d.is_friend_invite === '0'
              ? sendInvite(d.user_id, JSON.stringify(d.user_device))
              : inviteGame(d.nickname, JSON.stringify(d.user_device), d.user_id)
          }
        >
          <ProfileImg
            src={d.profile_image_file_url || MemberImg}
            alt="Profile image"
          />
          <Typography variant="h5">{d.nickname}</Typography>
          {props.page === 'game' ? (
            <InviteBtnStyled
              className={d.is_game_invite === '1' ? 'disabled' : 'enabled'}
            >
              초대
            </InviteBtnStyled>
          ) : (
            <StatusImg
              src={d.is_friend_invite === '1' ? IconCheck : IconPlus}
              alt="Icon"
            />
          )}
        </FriendList>
      ))}
    </>
  );
};

const SearchBarWrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  img {
    position: absolute;
    top: 13px;
    left: 16px;
    width: 24px;
  }
`;

const SearchBar = styled.input`
  width: 100%;
  height: 50px;
  padding: 13px 16px 13px 52px;
  border: solid 1px #b1b8c0;
  border-radius: 25px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: #b1b8c0;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  right: 0;

  width: 45px;
  font-size: 16px;
  font-weight: bold;
  color: #b1b8c0;
`;

const FriendList = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  width: 100%;
  margin-bottom: 8px;
  padding: 13px 16px 11px 12px;
  border-radius: 16px;
  background-color: #fafafb;
`;
const ProfileImg = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 24px;
`;
const StatusImg = styled.img`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 44px;
`;
const InviteBtnStyled = styled(Button)`
  position: absolute;
  right: 16px;
  width: 56px;
  height: 32px;
  border-radius: 8px;
  background-color: #1fb04b;

  font-size: 12px;
  font-weight: bold;
  color: #fff;

  &:hover {
    background-color: #1fb04b;
  }

  &.disabled {
    background-color: #e7e9ec;
    color: #b1b8c0;
  }
`;
