import React from 'react';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BottomNavigation from '../../components/atoms/BottomNavigation';
import { TopNavigation } from '../../components/atoms/TopNavigation';

export default function Service() {
  const navigate = useNavigate();

  return (
    <Container component="main">
      <TopNavigation type="back" handleEvent={() => navigate('/setting')}>
        개인정보취급방침
      </TopNavigation>

      <MenuListWrap>
        <PreStyled>
          {`  제 1조 (목적)
  본 약관은 (주)한국그린데이터 (이하 "회사"라고 합니다)가 제공하는 골펀 서비스(이하 "서비스"라고 합니다)를 이용하는 서비스 이용자(이하 "이용자"라고 합니다)와 회사간에 서비스의 이용에 관한 기본적인 사항을 규정함을 목적으로 합니다.
  
  제 2조 (용어의 정의)
  ①본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
  1."이용계약"이라 함은 회사가 제공하는 서비스 이용에 대해 회사와 이용자간에 체결하는 계약을 의미합니다.
  2."골펀 회원"(이하 "회원")이라 함은 회사가 제공하는 절차에 따른 가입 신청 및 이용계약 체결을 완료한 뒤, 계정을 발급받아 회사의 서비스를 이용할 수 있는 자를 의미합니다.
  3.서비스라 함은 회사가 제공하는 게임 및 제공자가 모든 권한과 책임을 가지고 회원에게 제공하는 게임과 이에 부수된 제반 서비스를 의미하며, 서비스는 회원이 별도 비용지급 없이 이용할 수 있는 무료서비스(이하 "무료서비스")와 회사가 회원에게 서비스 이용의 대가로 요금을 과금하거나 서비스에 필요한 아이템 등을 유상으로 판매하는 유료서비스(이하 "유료서비스")로 구성됩니다.
  4."제공자"란 회사에 통신판매중개를 의뢰한 자로, 회사가 제공하는 서비스를 통하여 이용자에게 직접 개별 서비스를 제공하는 자를 의미합니다.
  5."게임세계"라 함은 서비스를 통해 다중의 회원이 일정한 규칙에 따라 오락을 하거나 오락에 부수하여 여가선용, 친목도모, 정보매개 등을 할 수 있도록 게임성을 구현한 가변적인 가상세계를 의미합니다.
  6."골펀 계정(ID)" 또는 "골펀 ID(ID)"(이하 총칭하여 "계정")라 함은 회원 식별과 서비스 이용을 위하여 이용자가 선정하고 회사가 승인하는 문자, 숫자 또는 특수문자 등의 조합을 말합니다.
  7."골펀 계정정보"(이하 "계정정보")라 함은 회원의 계정, 비밀번호, 성명 등 회원이 회사에 제공한 일반정보 및 게임이용정보, 이용요금 결제상태 등 생성정보를 통칭합니다.
  8."캐릭터"라 함은 회원이 서비스의 이용을 위하여 게임세계 내에서 회사에서 제공하는 방식에 따라 선정하고 조종하는 게임데이터를 의미합니다.
  9."PASSWORD" 또는 "비밀번호"(이하 "비밀번호")라 함은 회원이 자신의 계정과 일치하는 이용자임을 확인하기 위해 회원 자신이 선정하여 비밀로 관리하는 문자, 숫자 또는 특수문자 등의 조합을 의미합니다.
  
  제 3조 (약관의 명시와 개정)
  ①회사는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 서비스 초기화면 또는 연결화면을 통하여 게시합니다.
  ②회사는 회원이 회사와 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다.
  ③회사는 서비스를 이용하고자 하는 자가 약관의 내용을 쉽게 알 수 있도록 작성하고 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 과오납금의 환급, 계약 해제ㆍ해지, 회사의 면책사항 및 회원에 대한 피해보상 등과 같은 중요한 내용을 회원이 쉽게 이해할 수 있도록 굵은 글씨 등으로 처리하거나 별도의 연결화면 또는 팝업화면 등을 제공하고 이용자의 동의를 얻도록 합니다.
  ④회사는 "전자상거래 등에서의 소비자보호에 관한 법률", "약관의 규제에 관한 법률", "게임산업진흥에 관한 법률", "정보통신망이용촉진 및 정보보호 등에 관한 법률", "콘텐츠산업진흥법" 등 관련법령에 위배되지 않는 범위 내에서 본 약관을 개정할 수 있습니다.
  ⑤회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 그 적용일자로부터 최소한 7일 이전부터 적용일 후 상당한 기간이 경과할 때까지 초기화면 또는 초기화면과의 연결화면을 통해 공지합니다. 다만 회원에게 불리하거나 중대한 사항의 변경은 30일 이전부터 공지하며, 기존 회원에게는 변경될 약관, 적용일자 및 변경사유를 이메일, 문자메시지 등으로 고지합니다.
  ⑥회사가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 개정약관 공지 시 회원이 동의 또는 거부의 의사표시를 하지 않으면 승낙한 것으로 간주하겠다는 내용도 함께 공지한 경우에는 회원이 약관 시행일까지 거부의사를 표시하지 않는다면 개정약관에 동의한 것으로 간주할 수 있습니다.
  ⑦회원이 개정약관의 적용에 동의하지 않는 경우 회사 또는 회원은 서비스 이용계약을 해지할 수 있습니다.
  
  제 4조 (약관 외 준칙)
  ①회사는 서비스에 대해서는 별도의 이용약관 및 운영정책(이하 "운영정책 등"이라 합니다)을 둘 수 있으며, 해당 내용이 본 약관의 내용과 상이할 경우에는 개별 서비스 약관 및 운영정책 등이 우선하여 적용됩니다.
  ②본 약관에 명시되지 아니한 사항이나 해석에 대해서는 "전자상거래 등에서의 소비자보호에 관한 법률", "약관의 규제에 관한 법률", "게임산업진흥에 관한 법률","정보통신망 이용촉진 및 정보보호 등에 관한 법률", "콘텐츠산업진흥법" 등 관계 법령에 따릅니다.
  
  제 5조 (운영정책)
  ①약관을 적용하기 위하여 필요한 사항과 회원의 권익을 보호하고 게임세계 내 질서를 유지하기 위하여 회사는 약관에서 구체적 범위를 정하여 위임한 사항을 서비스 운영정책(이하 "운영정책"이라 합니다)으로 정할 수 있습니다.
  ②회사는 운영정책의 내용을 회원이 알 수 있도록 게임 초기 화면이나 서비스 홈페이지에 게시하거나 연결화면을 제공하는 방법으로 회원에게 공지하여야 합니다.
  ③회원의 권리 또는 의무에 중대한 변경을 가져오거나 약관 내용을 변경하는 것과 동일한 효력이 발생하는 운영정책 개정의 경우에는 제4조의 절차에 따릅니다. 단, 운영정책 개정이 다음 각 호의 어느 하나에 해당하는 경우에는 제2항의 방법으로 사전에 공지합니다.
  1.약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우
  2.회원의 권리·의무와 관련 없는 사항을 개정하는 경우
  3.운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고 회원이 예측 가능한 범위 내에서 운영정책을 개정하는 경우
  
  제 6조 (이용신청 및 방법)
  ①회사가 제공하는 서비스를 이용하고자 하는 자는 약관의 내용에 대하여 동의를 하고, 개별 게임 초기 화면이나 서비스 홈페이지에서 제공하는 이용신청서를 작성하는 방법으로 이용신청을 하여야 합니다.
  ②이용자는 이용신청 시 회사에서 요구하는 제반 정보를 제공하여야 합니다.
  ③이용자는 제1항의 이용 신청 시 본인의 실명 및 실제정보를 기재하여야 합니다. 실명 또는 식별정보를 허위로 기재하거나 타인의 명의를 도용한 경우 회원은 이 약관에 의한 회원의 권리를 주장할 수 없고, 회사는 환급 없이 이용계약을 취소하거나 해지할 수 있습니다.
  ④청소년(18세 미만의 자로서 "초·중등교육법" 제2조의 규정에 의한 고등학교에 재학 중인 학생을 포함한다)이 이용신청을 할 경우에는 법정대리인의 동의를 얻어야 하고, 구체적인 동의절차는 "게임산업진흥에 관한 법률" 및 시행령에 따라 회사가 제공하는 방법에 따르도록 합니다.
  ⑤제공자 및 제휴서비스에서 제공하는 개별 서비스는 개인정보 제3자 제공동의 후 제공자의 이용약관 및 운영정책에 동의하여야만 이용할 수 있습니다.
  
  제 7조 (이용신청에 대한 승낙 및 제한)
  ①회사는 회사가 이용자에게 요구하는 정보에 대해 이용자가 실명 및 실제 정보를 정확히 기재하여 이용신청을 한 경우에 상당한 이유가 없는 한 이용신청을 승낙합니다.
  ②회사는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 하지 않거나 승낙 이후라도 승낙을 취소할 수 있습니다.
  1.제7조에 위반하여 이용신청을 하는 경우
  2.이용요금을 납부하지 않거나 잘못 납부하여 확인할 수 없는 경우
  3.청소년(제7조 제4항의 청소년과 같다)이 법정대리인의 동의를 얻지 아니하거나 동의를 얻었음을 확인할 수 없는 경우
  4.최근 3개월 내 이용제한 기록이 있는 이용자가 이용신청을 하는 경우
  5.제3자의 신용카드, 유/무선 전화, 은행 계좌 등을 무단으로 이용 또는 도용하여 서비스 이용요금을 결제하는 경우
  6.대한민국 이외의 국가 중 회사에서 아직 서비스를 제공할 것으로 결정하지 않은 국가에서 서비스를 이용하는 경우로 회사가 해외 서비스 업체와 체결한 계약이나 특정 국가에서 접속하는 회원에 대한 서비스 제공과 관련하여 서비스제공을 제한할 필요가 있는 경우
  7."게임산업진흥에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 및 그 밖의 관계 법령에서 금지하는 위법행위를 할 목적으로 이용신청을 하는 경우
  8.제22조 제5항에 따라 계약이 해지된 이용자가 이용신청을 하는 경우
  9.그 밖에 1호 내지 7호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우
  ③회사는 다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
  1.회사의 설비에 여유가 없거나 기술적 장애가 있는 경우
  2.서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우
  3.그 밖에 위 각 호에 준하는 사유로서 이용신청의 승낙이 곤란한 경우
  
  제 8조 (미성년자 법정대리인의 계약취소권)
  회사는 미성년자인 회원이 결제가 필요한 유료 서비스를 이용하고자 하는 경우 부모 등 법정 대리인의 동의를 얻어야 하고, 법정대리인이 그 계약에 동의하지 아니하면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 고지하여야 합니다. 단, 미성년자 회원의 사술로 인한 경우 및 법정대리인이 처분을 허락한 재산의 경우 제외됩니다.
  
  제 9조 (회원 계정(ID) 및 비밀번호)
  ①회사는 회원에 대하여 회원의 정보 보호, 서비스 이용안내 등의 편의를 위해 회원이 선정한 일정한 문자, 숫자 또는 특수문자의 조합을 계정으로 부여합니다.
  ②회사는 계정정보를 통하여 당해 회원의 서비스 이용가능 여부 등의 제반 회원 관리업무를 수행합니다.
  ③회원은 자신의 계정정보를 선량한 관리자로서의 주의 의무를 다하여 관리하여야 합니다. 회원이 본인의 계정정보를 소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다.
  ④비밀번호는 이용자가 직접 선정하는 사항으로 비밀번호의 관리책임은 회원에게 있습니다. 이용자가 원하는 경우에는 보안상의 이유 등으로 언제든지 변경이 가능하되, 회사가 요청하는 경우 이용자는 본인 인증을 하거나 회사가 요구하는 본인 확인 서류를 제출하여야 합니다.
  ⑤회원은 정기적으로 비밀번호를 변경하여야 합니다.
  ⑥회사는 회원의 계정정보 등 보안상 긴급한 이유로 이용자의 정보를 안전하게 보호하기 위하여 이용자에게 비밀번호를 변경하도록 요청할 수 있습니다. 이 경우 이용자는 회사가 요청한 날 이후 최초 접속 시에 회원이 보유한 계정의 비밀번호를 변경하여야 합니다.
  ⑦회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 변경사항을 알려야 합니다. 이를 회사에 알리지 않아 발생한 불이익에 대해 회사는 책임지지 않습니다.
  ⑧회원은 서비스 이용기간 동안 승인된 계정의 변경을 요청할 수 없습니다. 단, 회사가 다음과 같은 사유로 회원에게 계정 변경을 요청하는 경우 회원은 이에 응하여야 합니다.
  1.회사가 이용자에게 효율적으로 보다 많은 서비스를 제공하기 위해 부득이한 경우
  2.회사의 서비스 운영이나 정책상 다른 서비스와의 통합이 필요한 경우
  3.기타 회원이 신청한 계정이 관계 법령 또는 회사 정책에 따라 변경할 필요성이 상당한 경우
  ⑨회원은 이 약관에 의하여 회사에 정보를 제공하여야 하는 경우에는 진실된 정보를 제공하여야 하며, 허위정보 제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다.
  ⑩회원은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 계정(ID) 등은 수정이 불가능합니다.
  
  제 10조 (개인정보의 보호 및 관리)
  ①회사는 "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 등 관계 법령이 정하는 바에 따라 계정정보를 포함한 이용자의 개인정보를 보호하기 위해 노력합니다. 이용자 개인정보의 보호 및 사용에 대해서는 관계 법령 및 회사가 별도로 고지하는 개인정보처리방침이 적용 됩니다.
  ②회사의 공식 사이트 이외의 링크된 사이트(제공자의 사이트, 제3자 제공의 서비스 등) 및 회원의 제공 동의에 따라 회사가 제공자에게 개인정보를 제공한 이후에는 해당 제공자에게 개인정보보호의무가 있습니다. 또, 제공자가 운영 관리하는 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
  ③회사는 이용자의 귀책사유로 인하여 노출된 이용자의 계정정보를 포함한 모든 정보에 대해서 일체의 책임을 지지 않습니다.
  
  제 11조 (회사의 의무)
  ①회사는 관련 법령을 준수하고, 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 따라 성실하게 합니다.
  ②회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.
  ③회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스를 개선하던 중 설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변, 비상사태, 해결이 곤란한 기술적 결함 및 장애 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.
  ④제공자가 서비스 하는 개별 서비스를 이용하는 회원에 대한 고객지원서비스(회원의 의견·불만 사항 처리, 장애처리 등)는 제공자가 처리하며 자세한 사항은 제공자의 이용약관 및 운영정책에 따릅니다. 단, 고객지원 서비스 중 골펀계정 및 골펀캐쉬 관련 운영 업무는 한국그린데이, 코인 관련 운영업무는 회사가 담당합니다.
  ⑤회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약관련 절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력합니다.
  
  제 12조 (회원의 의무)
  ①회원은 아래 각 호에 해당하는 행위 또는 아래 각 호에 해당하는 내용을 목적으로 하거나 의도하는 행위를 하여서는 안 됩니다.
  1.신청 또는 변경 시 허위내용의 기재
  2.타인의 정보 도용
  3.회사의 임직원, 운영자, 기타 관계자를 사칭하는 행위
  4.회사가 게시한 정보의 변경
  5.회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시
  6.회사가 제공 또는 승인하지 아니한 컴퓨터 프로그램이나 기기 또는 장치를 제작, 배포, 이용, 광고하는 행위
  7.회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해
  8.회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
  9.외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위
  10.게임 데이터(계정, 캐릭터, 게임아이템 등)를 정상적이지 아니한 방법으로 취득 및 유상으로 처분(양도, 매매 등)하거나 권리의 객체(담보제공, 대여 등)로 하는 행위
  11.10호의 행위를 유도하거나 광고하는 행위
  12.회사의 동의 없이 영리, 영업, 광고, 정치활동 등을 목적으로 서비스를 사용하는 행위
  13.기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지 않는 행위
  14.서비스의 오류나 버그 등을 악용하여 서비스를 이용하는 행위
  15.기망 또는 사행행위 등의 방법으로 다른 이용자로부터 아이템을 획득하는 행위
  16.회사 및 결제 업체 등이 제공하는 이용자 가입, 게임 이용, 결제 환불 등과 관련된 제반 프로세스를 악용하여 부당이득을 취하는 경우
  17.회사 서비스의 운영을 고의 또는 중과실로 방해하는 행위
  18.회사의 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제 또는 유통시키거나 상업적으로 이용하는 경우
  19.회사와 계약된 사업장 이외의 장소에서 PC방 IP서비스를 이용하는 행위
  ②회원은 이 약관의 규정, 운영정책 및 서비스 홈페이지 및 개별 게임 사이트 상의 공지사항, 회사가 통지하는 사항 등을 확인하고 준수할 의무가 있습니다.
  ③회사는 본 조 제1항 내지 제2항 및 다음 각 호의 어느 하나에 해당하는 행위의 구체적인 유형을 운영정책 등에서 정할 수 있으며, 회원은 이를 준수하여야 합니다.
  1.회원의 계정명, 캐릭터명, 길드명에 대한 제한
  2.대화 등의 내용 및 방법에 대한 제한
  3.게시판 이용에 대한 제한
  4.게임플레이 방법에 대한 제한
  5.코인 및 이용 상품에 대한 제한
  6.기타 이용자의 서비스 이용에 대한 본질적 권리를 침해하지 않는 범위 내에서 회사가 서비스 운영상 필요하다고 인정하는 사항
  ④계정정보에 대한 관리책임은 회원에게 있으며, 회원은 제3자의 계정을 이용하거나 자신의 계정을 제3자에게 이용하게 하여서는 안 됩니다.
  ⑤회원은 타인의 신용카드, 유/무선 전화, 은행계좌 등을 무단으로 이용 또는 도용하여 코인 또는 골펀캐쉬를 충전하거나 타인의 골펀캐쉬를 무단으로 이용 또는 도용하여 이용 상품 등을 구매하여서는 안 됩니다.
  ⑥회원은 본 약관에서 규정하는 사항, 서비스 홈페이지 또는 개별 게임 사이트 상의 공지사항 및 회사가 정한 운영정책 등 제반 정책이나 규정을 수시로 확인하여야 합니다.
  
  제 13조 (서비스의 제공 및 중단 등)
  ①회사는 이용자에게 아래와 같은 서비스를 제공합니다.
  1.서비스
  2.보안서비스
  3.고객보호서비스
  4.관련 부가 서비스
  5.기타 회사가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해 회원에게 제공하는 일체의 서비스
  ②서비스 이용시간은 연중무휴 1일 24시간(00:00-24:00)으로 함을 원칙으로 합니다.
  ③제2항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우에는 일정한 시간 동안 서비스가 제공되지 아니할 수 있으며, 해당 시간 동안 회사는 서비스를 제공할 의무가 없습니다.
  1.컴퓨터 등 정보통신설비의 보수점검, 교체, 정기점검 또는 서비스 운영상 필요한 경우
  2.해킹 등의 전자적 침해사고, 통신 사고, 서비스 설비의 장애 또는 회원들의 비정상적인 게임 이용행태 등으로 예상하지 못한 서비스의 불안정성에 대응하기 위하여 필요한 경우
  3.관계 법령, 정부 및 회사의 정책에서 특정 시간 또는 횟수 제한 등의 방법으로 서비스 제공을 금지하는 경우
  4.천재지변, 비상사태, 정전 등 불가항력적인 사유로 정상적인 서비스 제공이 불가능할 경우
  5.회사의 분할, 합병, 영업양도, 영업의 폐지, 당해 서비스의 수익 악화 등 회사의 경영상 중대한 필요에 의한 경우
  ④회사는 제3항 제1호의 경우 매주 또는 격주 단위로 일정 시간을 정하여 서비스를 중지할 수 있습니다. 이 경우 회사는 최소한 24시간 전에 그 사실을 회원에게 게임 초기 화면이나 서비스 홈페이지에 고지합니다.
  ⑤회사는 제3항 2호의 사유로 사전고지 없이 서비스를 일시 중지할 수 있습니다. 회사는 이러한 경우 그 사실을 게임 초기 화면이나 서비스 홈페이지에 사후 고지할 수 있습니다.
  ⑥회사는 회사가 제공하는 무료서비스 이용과 관련하여 이용자에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.
  ⑦회사는 회사가 제공하는 유료서비스 이용과 관련하여 회사의 귀책사유로 사전고지 없이 1일 4시간(누적시간) 이상 연속하여 서비스가 중지되거나 장애가 발생한 경우 계속적 이용계약 계정에 한하여 서비스 중지·장애시간의 3배에 해당하는 이용시간을 무료로 연장하고, 이용자는 회사에 대하여 별도의 손해배상을 청구할 수 없습니다. 다만, 회사가 서버점검 등의 사유로 서비스 중지·장애를 사전에 고지하였으나, 서비스 중지·장애시간이 10시간이 초과하는 경우에는 그 초과된 시간만큼 이용시간을 무료로 연장하고, 이용자는 회사에 대하여 별도의 손해배상을 청구할 수 없습니다.
  ⑧제3항 제3호 내지 제5호의 경우에 회사는 기술상·운영상 필요에 의해 서비스 전부를 중단할 수 있으며, 30일전에 홈페이지에 이를 공지하고 서비스의 제공을 중단할 수 있습니다. 사전에 통지할 수 없는 부득이한 사정이 있는 경우는 사후에 통지를 할 수 있습니다.
  ⑨회사가 제8항에 따라 서비스를 종료하는 경우 회원은 무료서비스 및 사용 기간이 남아 있지 않은 유료서비스·계속적 유료 이용계약·기간제 유료아이템에 대하여 손해배상을 청구할 수 없습니다. 사용 기간에 제한이 없는 유료아이템은 서비스 종료일 까지를 해당 유료아이템의 사용 기간으로 봅니다.
  ⑩회사가 제공하는 서비스는 중 일부에 대해서는 유료로 제공할 수 있으며, 유료서비스의 이용에 관한 구체적인 사항은 본 이용약관 또는 개별 유료서비스 및 제공자의 이용약관, 한국그린데이 전자금융거래 이용약관 및 회사에서 정한 운영정책 등에 따릅니다.
  ⑪회사는 서비스 제공을 위하여 필요한 경우 회원에게 회사가 제공하는 컴퓨터 프로그램의 설치를 요청할 수 있습니다. 회사는 회원이 해당 프로그램을 설치하기 전까지 용량, 기능, 제거 방법, 다른 프로그램에 미치는 영향 등 중요사항을 프로그램의 성격에 따라 적절한 방법으로 고지하고 프로그램 설치에 대한 동의를 얻어야 합니다. 단, 회원이 ActiveX 프로그램을 자동으로 설치하도록 브라우저 옵션을 설정한 경우 회사는 회원이 ActiveX 프로그램 설치에 동의한 것으로 간주하고 별도 의사 확인 없이 해당 프로그램을 설치할 수 있습니다.
  ⑫회사가 제11항에서 고지한 내용을 크게 변경하지 않는 범위 내에서 프로그램을 패치하거나 업데이트할 경우 중요사항 고지 및 동의를 생략할 수 있습니다.
  
  제 14조 (서비스의 변경 및 내용수정)
  ①회원은 회사가 제공하는 서비스를 이 약관, 운영정책 및 회사가 설정한 게임 규칙에 따라 이용할 수 있습니다.
  ②회사가 서비스를 통하여 회원에게 제공하는 게임 세계는 회사가 창조한 가상세계로서 회사는 게임 내용의 제작, 변경, 유지, 보수에 관한 포괄적인 권한을 가집니다. 회사가 제공하는 서비스 및 개별 게임 및 제휴서비스의 내용은 운영상, 기술상의 필요에 따라 수시로 변경(패치)될 수 있으며, 이 경우 회사는 각 서비스 홈페이지, 각 서비스 별 홈페이지 및/또는 제휴서비스 홈페이지 등을 통해 이용자에게 공지합니다.
  ③회사가 제공하는 제휴서비스에 대한 서비스 제공 내용, 제3자 회사의 의무, 이용자의 권리/의무 등 상세 사항은 각 제휴서비스에 대하여 제3자가 제공하는 별도 약관, 운영정책 등(이하 "제휴서비스 이용약관"이라 합니다)에서 정하는 바에 따릅니다. 제휴서비스 이용 시, 이용자는 제3자가 제공하는 별도의 제휴서비스 이용약관에 대한 동의가 필요할 수 있습니다.
  ④회사는 서비스의 일환으로 제공되는 각 게임 별 홈페이지 및 서비스에서 제공하는 각종 게임 및 관련 사이트를 개편, 분리시킬 수 있습니다. 이 경우 회사는 이용자들에게 적극적으로 공지하며, 이용자들은 개편되거나 분리된 사이트에서 서비스를 제공받게 됩니다.
  ⑤서비스 및 개별 게임의 기획이나 운영상 필요하다고 판단될 경우 회사는 서비스 및 개별 게임의 게임 관련 정보를 추가, 삭제, 변경할 수 있습니다.
  
  제 15조 (게시물의 관리)
  ①이용자는 자신의 게시물이 타인의 권리를 침해하지 않도록 관리하여야 합니다.
  ②회사는 이용자의 게시물을 소중하게 생각하며, 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 다만, 회사는 다음 각 호에 해당하는 게시물을 사전통지 없이 삭제하거나 이동 또는 등록 거부를 할 수 있으며, 해당 게시물을 게시한 이용자에게는 이용약관 및 운영정책 등에 따라 일정 조치를 취할 수 있습니다.
  1.다른 이용자 또는 제3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우
  2.이용자가 음란물을 게재하거나 음란사이트를 링크한 경우
  3.회사, 다른 이용자 또는 제3자의 저작권 등 기타 지적재산권을 포함한 일체의 권리를 침해하는 내용인 경우
  4.회사에서 규정한 게시물 원칙에 어긋나거나 게시판의 성격에 부합하지 않는 내용인 경우
  5.회사의 정책상 허용되지 않는 경우로서 이용자 계정 또는 게임상 사이버 아이템, 게임상 가상 자산 등의 매매에 관련된 내용인 경우
  6.불법복제 또는 해킹을 조장하는 내용인 경우
  7.범죄와 결부된다고 객관적으로 인정되는 내용일 경우
  8.영리를 목적으로 하는 광고성 내용인 경우
  9.회사나 서비스의 정상적인 운영을 방해하는 경우
  10.공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우
  11.기타 관계 법령에 위배된다고 판단되는 경우
  ③게시물로 인하여 법률상 이익이 침해된 자는 관계 법령과 고객센터에서 정한 절차에 따라 당해 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관계 법령에 따라 필요한 조치를 취하여야 합니다.
  
  제 16조 (정보의 수집 등)
  ①회사는 서비스 내에서 회원간에 이루어지는 대화, 게임 내 편지 등 모든 통신 내용(이하 "대화 등"이라 합니다)을 저장·보관할 수 있습니다. 회사는 회원간의 분쟁 조정, 민원 처리 또는 게임 질서의 유지를 위하여 회사가 필요하다고 판단하는 경우에 한하여 본 정보를 열람하도록 할 것이며, 본 정보는 회사만이 보유하고 법령으로 권한을 부여 받지 아니한 제3자는 절대로 열람할 수 없습니다. 회사는 해당 정보를 열람하기 전에 채팅 정보의 열람이 필요한 사유 및 열람 범위를 개인에게 사전 고지하기로 합니다. 단, 계정 도용, 현금거래, 언어폭력, 게임 내 사기 등 기망 행위, 버그 악용, 기타 현행 법령 위반행위 및 이 약관 제14조 및 제22조 4항에서 정하는 중대한 약관 위반 행위의 조사, 처리, 확인 및 이의 구제와 관련하여 회원의 채팅 정보를 열람해야 할 필요가 있는 경우에는, 사후에 채팅 정보가 열람 된 개인들에 대하여 열람한 사유와 열람한 정보 중 본인과 관련된 부분을 고지하기로 합니다.
  ②회사는 서비스 운영 및 프로그램 안정화 등 서비스 품질 개선을 위하여 회원 PC 등 단말기 설정 및 사양 정보를 수집·활용할 수 있습니다.
  
  제 17조 (저작권 등의 귀속)
  ①서비스 내 회사가 제작한 콘텐츠에 대한 저작권 기타 지적재산권은 회사의 소유입니다. 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 게임이나 캐릭터, 게임아이템, 게임머니, 무료코인 등을 이용할 수 있는 이용권만을 부여하며, 회원은 이를 유상양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
  ②회원은 회사가 제공하는 서비스를 이용함으로써 얻은 정보 중 회사 또는 제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
  ③회원은 게임내에서 보여지거나 서비스와 관련하여 회원 또는 다른 이용자가 게임 클라이언트 또는 서비스를 통해 업로드 또는 전송하는 대화 텍스트를 포함한 커뮤니케이션, 이미지, 사운드 및 모든 자료 및 정보(이하 "이용자 콘텐츠"라 한다.)에 대하여 회사가 다음과 같은 방법과 조건으로 이용하는 것을 허락합니다.
  1.해당 이용자 콘텐츠를 이용, 편집 형식의 변경 및 기타 변형하는 것(공표, 복제, 공연, 전송, 배포, 방송, 2차적 저작물 작성 등 어떠한 형태로든 이용 가능하며, 이용기간과 지역에는 제한이 없음)
  2.이용자 콘텐츠를 제작한 이용자의 사전 동의 없이 거래를 목적으로 이용자 콘텐츠를 판매, 대여, 양도 행위를 하지 않음
  ④게임 내에서 보여지지 않고 서비스와 일체화되지 않은 회원의 이용자 콘텐츠(예컨대, 일반게시판 등에의 게시물)에 대하여 검색결과 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집될 수 있습니다. 이 경우, 회사는 저작권법의 내용을 준수하여야 하며, 회원은 언제든지 고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등을 요청할 수 있습니다.
  ⑤회사는 제3항 및 제4항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 회원의 동의를 얻어야 합니다.
  ⑥회사는 회원이 게시하거나 등록하는 서비스 내의 게시물, 게시 내용에 대해 제 14조에서 규정하는 금지행위에 해당된다고 판단되는 경우, 사전통지 없이 이를 삭제하거나 이동 또는 등록을 거부할 수 있습니다.
  ⑦회사가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 회원은 회사에게 당해 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다. 이 경우 회사는 신속하게 필요한 조치를 취하고, 이를 신청인에게 통지합니다.
  ⑧제3항 및 제4항은 회사가 서비스를 운영하는 동안 유효하며 회원탈퇴 후에도 지속적으로 적용됩니다.
  
  제 18조 (광고게재 및 광고주와의 거래)
  ①회사는 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편, 전화 등의 방법으로 이용자에게 제공할 수 있습니다. 단, 이용자는 언제든지 전자우편, 전화 등을 통하여 수신 거절을 할 수 있습니다.
  ②회사는 서비스의 운영과 관련하여 서비스 홈페이지 및 개별 게임 사이트, 전자우편, 전화 등에 광고를 게재할 수 있습니다. 단, 전화 및 전자우편에 의하여 전송하려고 하는 경우에는 회원의 사전 동의를 받아서 전송 하며 수신한 회원은 언제든지 전자우편, 전화 등을 통하여 수신거절을 할 수 있습니다.
  ③회사가 제공하는 서비스는 배너와 링크(Link) 등 다양한 형태의 광고가 포함되어 있으며, 이는 제3자가 제공하는 페이지와 연결될 수 있습니다.
  ④전항에 따라 제3자가 제공하는 페이지로 연결될 경우 해당 페이지는 회사의 서비스 영역이 아니므로 회사가 신뢰성, 안정성 등을 보장하지 않으며 그로 인한 회원의 손해에 대하여도 회사는 책임을 지지 않습니다. 다만, 회사가 고의 또는 중과실로 손해의 발생을 용이하게 하거나 손해 방지를 위한 조치를 취하지 아니한 경우에는 그러하지 아니합니다.
  
  제 19조 (테스트 목적의 서비스)
  ①회사는 새로운 서비스를 정식으로 상용화하기 전, 이용자들을 대상으로 테스트 목적의 베타 서비스를 일정 기간 동안 진행할 수 있습니다. 각각의 경우 테스트 대상과 기간 및 이에 관련된 내용은 해당 서비스의 공지사항을 통해 별도로 공지합니다.
  ②베타 서비스는 안정적인 서비스 제공 등을 위해 게임 데이터의 변경, 추가, 삭제 등이 실시될 수 있으며, 테스트 기간 동안 회원이 취득한 게임머니, 캐릭터, 해당 테스트 목적 서비스와 관련된 모든 데이터 등은 복구가 불가능할 수 있습니다. 또한 베타 서비스 진행 중 예상치 못한 문제가 발생할 경우에는 회사는 사전 통지 없이 베타서비스를 중단할 수 있습니다.
  ③베타 서비스는 무료로 제공되는 한시적인 서비스로서 회사는 이로 인하여 이용자에게 발생한 손해에 대하여 책임을 부담하지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 이용자에게 손해가 발생한 경우 직접적인 손해에 한하여 배상합니다.
  
  제 20조 (회원에 대한 서비스 이용제한 및 이용중지)
  ①회사는 회원에게 다음 각 호의 구분에 따라 회원의 서비스 이용을 제한할 수 있습니다. 이용제한이 이루어지는 구체적인 회원의 의무위반 사유는 제24조에 따라 개별  운영정책에서 정하기로 합니다.
  1.캐릭터 일부 권한 제한: 일정기간 캐릭터의 채팅 등 일정 권한을 제한
  2.캐릭터 이용제한: 일정기간 또는 영구히 회원 캐릭터의 이용을 제한
  3.계정 이용제한: 일정기간 또는 영구히 회원 계정의 이용을 제한
  4.회원 이용제한: 일정기간 또는 영구히 회원의 서비스 이용을 제한
  ②회사의 이용제한이 정당한 경우 회사는 이용제한으로 인하여 회원이 입은 유료콘텐츠 손실, 포인트 소멸 등 손해를 별도로 배상하지 않습니다.
  ③회사는 "정보통신망이용촉진 및 정보보호 등에 관한 법률" 및 동법 시행령 또는 서비스 개선과 이용자의 개인 정보 보호를 위하여 최근 1년 간 서비스 이용 기록이 없는 계정에 대하여 휴면 계정으로 분류하고 해당 계정의 이용제한, 계정정보 삭제 등 필요한 조치를 취할 수 있습니다. 본 항에 따른 조치가 취해지는 경우 회사는 30일전에 이용자에게 그 사실을 제36조에 따라 통지합니다.
  ④회사는 회원이 본 약관 제14조에 정한 회원의 의무를 위반한 경우에는 사전 통보 후 기간을 정하여 서비스의 이용을 중지하거나 이용계약을 해지할 수 있습니다. 단, 회원이 본 약관 제14조 제1항 내지 제3항, 제5항에 기재된 회원의 의무를 위반한 경우나 고의 또는 중대한 과실로 회사에 손해를 입힌 경우에는 사전 통보 없이 기간을 정하여 서비스의 이용을 중지하거나 이용계약을 해지할 수 있습니다.
  ⑤회원이 본 약관 제14조 제1항 각 호를 위반하여 회원이 보유한 3개 이상의 계정이 본 조 제4항에 의한 조치를 받은 경우, 회사는 해당 회원이 보유한 모든 계정에 대하여 본 조 제4항의 조치를 취할 수 있습니다.
  ⑥제4항 또는 제5항에 따라 이용중지 또는 계약해지를 하는 경우 회사는 해지사유 및 해지일을 회원에게 서면, 전자우편 또는 이에 준하는 방법으로 통보하거나 서비스 홈페이지 등을 통해 해당 회원이 확인할 수 있도록 합니다. 또한 이 경우 해당 회원은 서비스 홈페이지 사이버 고객센터의 절차에 따라 이의신청을 할 수 있습니다.
  
  제 21조 (잠정 조치로서의 이용제한)
  ①회사는 다음 각 호에 해당하는 문제에 대한 조사가 완료될 때까지 계정을 정지할 수 있습니다.
  1.계정이 해킹 또는 도용 당하였다는 정당한 신고가 접수된 경우
  2.불법프로그램 사용자, 작업장 등 위법행위자로 합리적으로 의심되는 경우
  3.그 밖에 위 각호에 준하는 사유로 계정의 잠정조치가 필요한 경우
  ②제1항의 경우 회사는 조사가 완료된 후 서비스 이용 기간에 비례하여 일정액을 지급하여 이용하는 회원에게 정지된 기간만큼 회원의 서비스 이용기간을 연장합니다. 다만, 제1항에 의한 위법행위자로 판명된 경우에는 그러하지 아니합니다.
  
  제 22조 (이용제한의 사유와 절차)
  ①회사는 위반행위의 내용, 정도, 횟수, 결과 등 제반사정을 고려하여 이용제한이 이루어지는 구체적인 사유 및 절차를 운영정책으로 정합니다.
  ②회사가 제22조에서 정한 이용제한을 하는 경우에는 회원에게 서면 또는 전자우편이나 게임 초기 화면 또는 서비스 홈페이지에 게재하는 방법으로 다음 각 호의 사항을 회원에게 통보합니다.
  1.이용제한 사유
  2.이용제한 유형 및 기간
  3.이용제한에 대한 이의신청 방법
  
  제 23조 (이용제한에 대한 이의신청 절차)
  ①회원이 회사의 이용제한에 불복하고자 할 때에는 통보를 받은 날로부터 15일 이내에 회사의 이용제한에 불복하는 이유를 기재한 이의신청서를 서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다.
  ②제1항의 이의신청서를 접수한 회사는 접수한 날로부터 15일 이내에 회원의 불복 이유에 대하여 서면, 전자우편 또는 이에 준하는 방법으로 답변하여야 합니다. 다만, 회사는 15일 이내에 답변이 곤란한 경우 회원에게 그 사유와 처리일정을 통보합니다.
  ③회사는 위 답변 내용에 따라 상응하는 조치를 취하여야 합니다.
  
  제 24조 (이용 상품)
  ①회사는 무료서비스와 유료서비스를 제공할 수 있으며, 회원은 서비스를 선택하여 이용 할 수 있습니다.
  ②서비스의 이용에 대한 대금 지급은 회사가 미리 정한 방법을 통하여 할 수 있습니다.
  ③제 1항의 유료 서비스 이용과 관련하여, 회원이 선택한 유료 서비스 이용 신청 후, 다음 각 호에 해당하는 사항을 준수하여야 합니다.
  1.유료 서비스 이용을 신청한 회원은 서비스 이용 요금을 성실히 납부하여야 합니다.
  2.유료 서비스 이용을 신청한 회원은 서비스 이용 요금 신청 후 회사가 제공하는 방법으로 지급하여야 합니다. 단, 회사가 무통장 입금 방식을 제공하고 회원이 무통장입금 방식으로 요금 지급을 신청한 경우에는 회원은 신청 완료 후 3일 이내에 해당 금액을 입금하여야 하며, 3일 이내에 입금하지 않을 시에는 그 신청을 철회한 것으로 봅니다.
  
  제 25조 (청약의 철회)
  ①회사가 제공하는 유료서비스에는 청약철회가 가능한 서비스와 청약철회가 제한되는 서비스로 구분되어 제공되며, 이러한 내용은 이용자가 유료서비스 구매 시 또는 운영정책 등을 통하여 고지할 수 있습니다. 회사와 유료서비스 이용에 관한 계약을 체결한 회원은 구매일 또는 유료서비스 이용가능일로부터 7일 이내에는 청약의 철회를 할 수 있습니다.
  ②회원은 다음 각 호의 어느 하나에 해당하는 경우에는 회사의 의사에 반하여 제1항에 따른 청약철회 등을 할 수 없습니다.
  1.회원에게 책임이 있는 사유로 재화 등이 멸실되거나 훼손된 경우
  2.회원이 재화를 사용 또는 일부 소비한 경우
  3.시간이 지나 다시 판매하기 곤란할 경우
  4.복제 가능한 재화 등의 포장을 훼손한 경우
  5.그 밖에 거래의 안전을 위하여 법령으로 정하는 경우
  ③회사는 본 조 제2항 제2호부터 제4호까지 규정에 따라 청약철회 등이 불가능한 재화 등의 경우에는 그 사실을 재화 등의 포장이나 그밖에 회원이 쉽게 알 수 있는 곳에 명확하게 적시하거나 시험 사용 상품을 제공하는 등의 방법으로 청약철회 등의 권리 행사가 방해 받지 아니하도록 조치하여야 합니다. 만약 회사가 이러한 조치를 하지 아니한 경우에는 제2항 제2호 내지 제4호의 청약철회 제한사유에도 불구하고 회원은 청약철회를 할 수 있습니다.
  ④회원은 본 조 제1항 내지 제3항의 규정에도 불구하고 유료서비스의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 경우에는 구매일 또는 유료서비스 이용가능일로부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회를 할 수 있습니다.
  ⑤회원은 회사의 고객센터의 1:1 상담을 통해서 청약철회를 할 수 있습니다.
  
  제 26조 (회원의 해제 및 해지)
  ①회원은 서비스 이용계약을 해지(이하 "회원탈퇴"라 한다)할 수 있습니다. 회원이 회원탈퇴를 신청한 경우 회사는 본인인증을 통해 회원 본인 여부를 확인할 수 있으며, 해당 회원이 본인으로 확인되는 경우 관계법규 등이 정하는 바에 따라 이를 처리합니다.
  ②골펀(www.golfun.co.kr)에서 회원 탈퇴를 하시는 경우 다음 게임의 이용계약도 함께 해지됩니다. 이 경우 회원이 보유하고 있는 골펀캐쉬는 한국그린데이 전자금융거래 골펀캐쉬 이용약관 및 (제공자의 서비스 이용 시)제공자의 이용약관 등 별도 유료서비스 이용약관이 정하는 바에 따릅니다.
  ③회사와 회원의 계약이 해지되는 경우 제공자가 제공하는 서비스 이용 계약도 함께 해지 되며, 회원탈퇴로 인해 발생한 서비스 이용 중단 등 불이익에 대한 책임은 회원 본인이 집니다.
  ④회원이 이용계약을 해지할 경우, 개인정보처리방침 및 관계법령에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 개인정보를 삭제합니다.
  ⑤회원 탈퇴가 완료되면, 탈퇴 후 일정 기간 내 회원 가입이 불가능할 수 있습니다.
  
  제 27조 (과오금의 환급)
  ①과오금이 발생한 경우 회사는 이용 대금의 결제와 동일한 방법으로 과오금을 환불합니다. 단, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.
  ②회사의 귀책사유로 과오금이 발생한 경우 회사는 계약 비용, 수수료 등에 관계없이 과오금 전액을 환불합니다. 단, 회원의 귀책사유로 과오금이 발생한 경우 회사가 과오금을 환불하는 데 소요되는 비용은 합리적인 범위 내에서 회원이 부담하여야 합니다.
  ③회사는 회원이 주장하는 과오금에 대해 환불을 거부할 경우에 과오금이 없음을 입증할 책임을 집니다.
  ④회원이 실제 정상적인 구매 내역이 기록되는 이용 대금의 결제를 통하지 않고 서비스 중에 보상으로 취득하거나, 타 계정으로부터 선물 받거나, 회사가 내부 이벤트 혹은 외부 제휴 이벤트 등을 통하여 지급한 유료 아이템 등은 환불되지 않습니다.
  
  제 28조 (손해배상)
  ①회사가 고의 또는 중과실로 회원에게 손해를 끼친 경우, 손해에 대하여 배상할 책임이 있습니다. 단 회원이 본 약관을 위반하여 회사에 손해를 끼친 경우, 회원은 회사에 대하여 그 손해에 대하여 배상할 책임이 있습니다.
  ②회사는 회사가 제공하는 유료서비스의 중지·장애와 관련하여 다음 각 호에 따라 보상하며, 이용자는 회사에 대하여 별도의 손해배상을 청구할 수 없습니다.
  1.회사의 귀책사유로 사전고지 없이 1일 4시간(누적 시간)이상 연속해서 서비스가 중지되거나 장애가 발생한 경우 당해 서비스에 한하여 해당 시간의 3배에 해당하는 이용시간 무료 연장
  ③서비스 중지·장애 시간은 이용자가 회사에 통지한 후부터 계산하되, 서비스가 불가항력(천재지변, 비상사태, 해결이 곤란한 기술적 결함 등을 포함)이나 이용자의 고의 또는 과실로 인하여 중지되거나 장애가 발생한 경우에는 서비스 중지·장애 시간 계산에서 제외합니다.
  ④회원이 회사로부터 구매한 유료콘텐츠가 회사의 귀책사유로 인하여 손실된 경우 회사는 이를 손실 이전의 상태로 원상복구 하여야 합니다. 단, 원상복구가 불가능한 경우에는 회사는 동일한 내용의 콘텐츠를 다시 제공하여야 하며, 다시 제공할 수 없는 경우 유료콘텐츠 구매대금을 환불하여야 합니다.
  
  제 29조 (회사의 책임제한)
  ①회사는 전시, 사변, 천재지변, 국가비상사태, 해결이 곤란한 기술적 결함 및 정부정책의 존재 등 기타 불가항력적 사유로 서비스를 제공할 수 없는 경우에는 책임이 면제됩니다.
  ②회사는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
  ③회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
  ④회사는 이용자의 귀책사유로 인한 서비스의 장애, 이용중지 또는 계약해지 등에 대하여 책임을 지지 않습니다.
  ⑤회사는 회원의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는 회사의 고의 또는 중대한 과실이 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서 책임이 면제됩니다.
  ⑥이용자가 신상정보 등 개인정보를 부실 기재하거나 소홀히 관리하여 손해를 입은 경우 회사는 책임을 지지 않습니다.
  ⑦회사는 회원이 서비스를 이용하며 기대하는 캐릭터, 경험치, 아이템 등의 결과를 얻지 못하거나 상실한 것에 대하여 책임을 지지 않으며, 서비스에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
  ⑧회사는 회원의 게임상 사이버 자산(게임머니), 등급/내공 손실에 대하여 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
  ⑨회사는 회원 또는 제공자, 제3자가 서비스 내 또는 웹사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
  ⑩회사는 이용자 상호간, 이용자와 제공자간, 이용자와 제3자간에 서비스를 매개로 발생한 분쟁 또는 저작권 등 제3자의 권리침해 등 관련 법률 위반을 이유로 분쟁이 발생한 경우 이에 대해 개입할 의무가 없으며, 이로 인해 발생하는 손해 등에 대해서는 책임을 지지 않습니다.
  ⑪회사는 제공자가 제공하는 서비스 및 제휴서비스로 인하여 발생한 손해 등에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
  ⑫회사는 관계 법령, 정부 정책 등에 따라 서비스 또는 회원에 따라 서비스 이용시간 등을 제한할 수 있으며, 이러한 제한사항 및 제한에 따라 발생하는 서비스 이용 관련 제반사항에 대해서는 책임이 면제됩니다.
  ⑬회사가 제공하는 서비스 중 무료서비스의 경우에는 회사의 고의 또는 중대한 과실이 없는 한 회사는 손해배상을 하지 않습니다.
  ⑭회사는 회원의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전자우편주소를 부정확하게 기재하거나 미 기재 하여 손해가 발생한 경우에 대하여 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
  ⑮제공자가 서비스를 일시 중단하거나 사업적 판단에 따라 서비스를 영구적으로 중단시키는 경우 이는 제공자와 회원간에 체결된 약관 및 정책, 안내에 따라 처리되며 달리 회사의 귀책사유가 있지 않는 한 회사는 이에 대하여 어떠한 책임도 부담하지 않습니다.
  ⑯회원 또는 회사의 이용계약 해지 등으로 인하여 회원과 회사의 서비스 이용계약이 종료되는 경우, 관계법령 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 회사는 보다 나은 서비스 환경 등을 위하여 해지 즉시 회원의 계정정보 및 관련된 모든 데이터를 삭제할 수 있으며 회사는 이용계약 종료 이후 회 원의 계정정보 및 관련 데이터 삭제로 인한 책임을 부담하지 않습니다.
  
  제 30조 (고충처리 및 분쟁해결)
  ①회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 게임 초기화면이나 서비스 홈페이지에서 안내합니다. 회사는 이러한 회원의 의견이나 불만을 처리하기 위한 전담조직을 운영합니다.
  ②회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게 장기간이 소요되는 사유와 처리일정을 서비스 홈페이지 또는 각 개별 서비스 페이지에 공지하거나 전자우편, 전화 또는 서면 등으로 통보합니다.
  ③회사와 회원간에 분쟁이 발생하여 제3의 분쟁조정기관이 조정할 경우 회사는 이용제한 등 회원에게 조치한 사항을 성실히 증명하고, 조정기관의 조정에 따를 수 있습니다.
  
  제 31조 (회원에 대한 통지)
  ①회사가 회원에게 통지를 하는 경우 본 약관에 별도의 규정이 없는 한 한국그린데이터가 부여한 회사의 서비스 내 전자우편으로 하며, 회원이 전자우편을 지정한 경우 지정한 전자우편 및 전자메모 등으로 할 수 있습니다.
  ②회사는 회원 전체에게 통지를 하는 경우 7일 이상 회사의 서비스 홈페이지 또는 각 개별 게임 사이트의 초기 화면에 게시하거나 팝업화면 등을 제시함으로써 제1항의 통지에 갈음할 수 있습니다.
  
  제 32조 (준거법 및 재판관할)
  ①회사와 이용자 간에 발생한 분쟁에 대한 소송은 민사소송법 등 관계 법령에 따른 법원을 관할법원으로 합니다.
  ②본 약관은 대한민국법에 의하여 규정되고 이행되며, 회사와 이용자간에 제기된 소송에는 대한민국법을 적용합니다.
  
  부칙
  공고일자: 2023년 9월 22일
  시행일자: 2023년 9월 29일`}
        </PreStyled>
      </MenuListWrap>
      <BottomNavigation loc="mypage" />
    </Container>
  );
}

const MenuListWrap = styled.div`
  width: 100%;
`;

const PreStyled = styled.pre`
  white-space: pre-line;
  font-family: initial;
  text-wrap: unset;
`;
