import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import Arrow from '../assets/images/icn_arrow_right.svg';
import IconStar from '../assets/images/icn_star.svg';
import IconStarOn from '../assets/images/icn_star_on.svg';
import IconSearch from '../assets/images/icon/search_g.svg';
import { TopNavigation } from '../components/atoms/TopNavigation';

export interface IGolfList {
  golf_club_id: number;
  is_use: number;
  golf_club_name: string;
  location: string;
  lat: number;
  lon: number;
  code: string;
  course_list: ICourseList[];
}

interface ICourseList {
  course_id: number;
  golf_club_id: number;
  is_use: number;
  course_name: string;
  course_content: string;
}

export default function SelectCC() {
  const navigate = useNavigate();
  const [fieldList, setFieldList] = useState([
    { golf_club_name: '직접 입력', location: '-' },
  ]);

  const location = useLocation();

  const filterData = (query: string, data: any[]) => {
    if (!query) {
      return data;
    } else {
      return data.filter((d) =>
        d.golf_club_name.toLowerCase().includes(query.toLowerCase()),
      );
    }
  };

  const [searchQuery, setSearchQuery] = useState('');
  const dataFiltered = filterData(searchQuery, fieldList);

  const goNext = (id: number) => {
    navigate(`/gametype?g=${id}`, { state: location.state });
  };

  const favoriteCheck = (e: React.MouseEvent<HTMLImageElement>, id: number) => {
    e.stopPropagation();

    if (!id) {
      alert('골프장이 선택되지 않았습니다.');
      return;
    }
    restApi
      .put('/user/golf-course/favorite', { golf_club_id: id })
      .then((e) => {
        const { data } = e;

        if (data.status_code === '200') {
          callList();
        }
      });
  };

  const callList = () => {
    restApi.get('/game/golf-club-list').then((e) => {
      const { data } = e;

      if (data.status_code === '200') {
        setFieldList(data.result_data);
      }
    });
  };

  useEffect(() => {
    callList();
  }, []);

  return (
    <Container>
      <TopNavigation
        type="close"
        subTitle="게임 생성하기"
        handleEvent={() => navigate('/clubhouse')}
      >
        골프장(CC) 선택하기
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SearchBarWrap>
          <SearchBar
            id="search-bar"
            className="text"
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            placeholder="골프장 이름을 입력하세요."
            style={{ width: '100%' }}
            value={searchQuery}
          />
          <img src={IconSearch} alt="icon" />
        </SearchBarWrap>
        {dataFiltered.map((d, i) => (
          <CCListItem key={i} onClick={() => goNext(d.golf_club_id)}>
            <Typography variant="h5Bold">{d.golf_club_name}</Typography>
            <Typography variant="body1" style={{ color: '#505866' }}>
              {d.location}
            </Typography>

            <CCListIcon
              src={d.is_favorite === '1' ? IconStarOn : IconStar}
              onClick={(e: React.MouseEvent<HTMLImageElement>) =>
                favoriteCheck(e, d.golf_club_id)
              }
              alt="Favorite"
            />
          </CCListItem>
        ))}
      </Box>
    </Container>
  );
}

const SearchBarWrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  img {
    position: absolute;
    top: 13px;
    left: 16px;
    width: 24px;
  }
`;

const SearchBar = styled.input`
  width: 100%;
  height: 50px;
  padding: 13px 16px 13px 52px;
  border: solid 1px #b1b8c0;
  border-radius: 25px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: #b1b8c0;
  }
`;

const CCListItem = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  padding: 18px 16px 16px 24px;
  border-radius: 16px;
  background-color: #f2f3f5;
`;

const CCListIcon = styled.img`
  position: absolute;
  margin-top: -8px;
  top: 50%;
  right: 16px;
`;
