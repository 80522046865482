export const searchParams = (v: string) => {
  const params: { [key: string]: string } = {};
  const searchParams = new URLSearchParams(window.location.search);
  Array.from(searchParams.keys())?.map((key) => {
    const value = searchParams.get(key);
    if (value !== null) {
      params[key] = value;
    }
  });
  return params[v];
};

export const encodeFileToBase64 = async (image: File) => {
  const data = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event: any) => resolve(event.target.result);
    reader.onerror = (error) => reject(error);
  });
  return data;
};

export const postMessage = (type: string, data: any) => {
  window.ReactNativeWebView?.postMessage(JSON.stringify({ type, data }));
};
