import React, { useState } from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import { BottomButton } from '../components/atoms/BottomButton';
import { InputBox } from '../components/atoms/InputBox';
import { TopNavigation } from '../components/atoms/TopNavigation';
import { useSiteState } from '../contexts/SiteContext';

export default function NonMember() {
  const navigate = useNavigate();
  const [inviteCode, setInviteCode] = useState<string>(
    localStorage.getItem('room_code') || '',
  );
  const [nickname, setNickname] = useState('');
  const [nicknameMessage, setNicknameMessage] = useState('');
  const [inviteMessage, setInviteMessage] = useState<string>('');
  const { memberInfoChange } = useSiteState();

  const joinGame = () => {
    restApi
      .get(`/guest/check-room-code`, {
        params: {
          room_code: inviteCode,
        },
      })
      //.get('/guest/check-room-code?room_code=' + room)
      .then((e: any) => {
        const { data } = e;
        if (data.status_code === '200') {
          localStorage.setItem('room_code', inviteCode);
          localStorage.setItem('ri', data.result_data);
          memberInfoChange({ nickname: nickname });
          navigate('/gameshare');
        } else {
          console.log('error = ', data.status_code, data.status_message);
          localStorage.removeItem('room_code');
          localStorage.removeItem('ri');
          if (data.status_code === '505') {
            if (inviteCode.slice(0, 2) === 'G_') {
              setInviteMessage(
                '비회원은 그룹방에 참가하실수 없습니다. 파티초대를 요청하세요.',
              );
            } else {
              setInviteMessage('방을 찾을 수 없습니다.');
            }
          }
        }
      });
    //navigate('/playgame');
  };

  return (
    <Container component="main">
      <TopNavigation type="back" handleEvent={() => navigate('/onboarding')}>
        비회원으로 시작하기
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TopInfo>
          공유받은 초대코드를 입력해주세요.
          <br />
          초대코드를 입력 후 버튼을 누르면 해당 게임으로 들어갈 수 있습니다.
        </TopInfo>

        <InputBox
          id="roomcode"
          title="초대코드"
          value={inviteCode}
          placehold="초대코드를 입력해주세요."
          handleEvent={(event) => {
            setInviteCode(event.target.value);
          }}
          message={inviteMessage}
        />

        <InputBox
          id="nickname"
          title="닉네임"
          value={nickname}
          placehold="이름을 입력해주세요."
          handleEvent={(event) => {
            setNickname(event.target.value);
            setNicknameMessage('');
          }}
          message={nicknameMessage}
        />

        <BottomButton
          txt="게임 참여하기"
          disabled={!(inviteCode && nickname)}
          handleEvent={joinGame}
        />
      </Box>
    </Container>
  );
}

const TopInfo = styled.div`
  width: 100%;
  padding: 19px 16px 18px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #fafafb;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.1px;
  text-align: left;
  color: #0f0f0f;
`;
