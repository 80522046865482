import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Switch,
  // eslint-disable-next-line import/named
  SwitchProps,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import Arrow from '../assets/images/icn_arrow_right.svg';
import BottomNavigation from '../components/atoms/BottomNavigation';
import { DimmedConfirm } from '../components/atoms/DimmedPopup';
import { TopNavigation } from '../components/atoms/TopNavigation';
import { searchParams } from '../components/blocks/CommonFn';
import { useSiteState } from '../contexts/SiteContext';

export default function Setting() {
  const { memberInfo, memberInfoChange } = useSiteState();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');
  const nmNickname = localStorage.getItem('nickname');
  const [showLogout, setShowLogout] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [ver, setVer] = useState('');
  const { siteVersion } = useSiteState();

  const verCall = () => {
    restApi.get('/guest/site-version').then((e) => {
      const { data } = e;
      if (data.status_code === '200') {
        setVer(data.result_data);
      }
    });
  };

  const logoutPop = () => {
    setShowLogout(true);
  };

  const logout = () => {
    //localStorage.clear();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user_id');
    localStorage.removeItem('room_code');
    localStorage.removeItem('ri');
    setShowLogout(false);
    navigate('/onboarding');
  };

  const deletePop = () => {
    setShowDelete(true);
  };

  const deleteAccount = () => {
    restApi
      .delete('/user/withdrawal', {
        params: { user_id: memberInfo?.user_id },
      })
      .then((e) => {
        const { data } = e;
        if (data.result_data === 1) {
          localStorage.clear();
          setShowLogout(false);
          navigate('/onboarding');
        }
      });
  };

  useEffect(() => {
    if (!accessToken) {
      if (nmNickname || searchParams('game')) {
        memberInfoChange({ nickname: nmNickname || '' });
      } else {
        navigate('/onboarding');
      }
    }
    verCall();
  }, []);

  return (
    <Container component="main">
      <TopNavigation type="back" handleEvent={() => navigate('/mypage')}>
        설정
      </TopNavigation>

      <AlarmWrap>
        {/*<MenuList>
          <Typography variant="h5">알림 설정</Typography>
          <FormControlLabel
            control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
            label=""
          />
        </MenuList>*/}
      </AlarmWrap>

      <MenuListWrap>
        <MenuList onClick={() => navigate('/personal')}>
          <Typography variant="h5">개인정보처리방침</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => navigate('/service')}>
          <Typography variant="h5">서비스 이용약관</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => navigate('/changepassword')}>
          <Typography variant="h5">비밀번호 변경</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => logoutPop()}>
          <Typography variant="h5">로그아웃</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => deletePop()}>
          <Typography variant="h5">탈퇴하기</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList>
          <Typography variant="h5">
            버전 정보
            <SubText>v.{ver}</SubText>
          </Typography>
          <Update
            onClick={() => siteVersion !== ver && window.location.reload()}
            className={siteVersion === ver ? 'disabled' : 'enabled'}
          >
            {siteVersion === ver ? '최신상태' : '업데이트'}
          </Update>
        </MenuList>
      </MenuListWrap>
      <BottomNavigation loc="mypage" />
      {showLogout && (
        <DimmedConfirm
          title="로그아웃 하시겠어요?"
          subtitle="다시 돌아오길 기다릴게요!"
          buttontitle="로그아웃 하기"
          handleEvent={() => logout()}
          buttontitle2="아니요"
          handleEvent2={() => setShowLogout(false)}
        />
      )}
      {showDelete && (
        <DimmedConfirm
          title="정말 탈퇴하시겠어요?"
          subtitle="저희 다시 볼 수 있겠죠..?"
          buttontitle="탈퇴하기"
          handleEvent={() => deleteAccount()}
          buttontitle2="아니요"
          handleEvent2={() => setShowDelete(false)}
        />
      )}
    </Container>
  );
}

const AlarmWrap = styled.div``;

const MenuListWrap = styled.div``;

const MenuList = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  padding: 22px 16px 19px 24px;
  border-radius: 10px;
  background-color: #fafafb;

  &:first-child {
    margin-top: 32px;
  }
`;

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
  },
}));

const SubText = styled.span`
  padding-left: 8px;
  vertical-align: text-bottom;
  font-size: 12px;
  color: #b1b8c0;
`;

const Update = styled.span`
  padding: 7px 15px;
  margin: -6px 8px;
  border-radius: 8px;
  background-color: #e7e9ec;
  color: #b1b8c0;

  &.enabled {
    background-color: #e0fce5;
    color: #1fb04b;
  }
`;
