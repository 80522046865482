import React, { useState } from 'react';
import styled from 'styled-components';
import backarrow from '../../assets/images/backarrow.svg';
import close from '../../assets/images/close.svg';

interface Props {
  children: React.ReactElement | React.ReactElement[] | string;
  type: 'back' | 'close';
  subTitle?: string;
  handleEvent: () => void;
}

export const TopNavigation: React.FC<Props> = ({
  children,
  type,
  subTitle,
  handleEvent,
}) => {
  return (
    <TopArea>
      <TextArea>
        <IconArea onClick={handleEvent}>
          {type === 'close' ? (
            <img src={close} alt="close button" />
          ) : (
            <img src={backarrow} alt="back button" />
          )}
        </IconArea>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        <Title>{children}</Title>
      </TextArea>
    </TopArea>
  );
};

export default TopNavigation;

const TopArea = styled.div`
  width: 100%;
  height: 44px;
`;

const IconArea = styled.div`
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0;
  left: 16px;
`;

const TextArea = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.01px;
  color: #1fb04b;
`;

const Title = styled.div`
  margin-top: 2px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.02px;
  color: #0f0f0f;
`;
