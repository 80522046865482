import axios from 'axios';

// url 호출 시 기본 값 셋팅
const restApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // 기본 서버 주소 입력
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

const REFRESH_URL = `${process.env.REACT_APP_API_URL}/guest/check-refresh-token`;

const getRefreshToken = async () => {
  try {
    const { data } = await axios.get(
      `${REFRESH_URL}?refresh_token=${localStorage.getItem('refreshToken')}`,
    );

    localStorage.setItem('accessToken', data.result_data.access_token);
    if (data.refreshToken !== null) {
      localStorage.setItem('refreshToken', data.result_data.refresh_token);
    }

    return data.result_data.access_token;
  } catch (e) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    throw e;
  }
};

restApi.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    const accessToken = localStorage.getItem('accessToken'); // access 토큰을 가져오는 함수
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

function signOut() {
  //localStorage.removeItem('accessToken');
  //localStorage.removeItem('refreshToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user_id');
  localStorage.removeItem('room_code');
  localStorage.removeItem('ri');

  //console.log('go signin');
  if (window.location.pathname !== '/onboarding')
    window.location.href = '/onboarding';
}

restApi.interceptors.response.use(
  async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //const responseData = JSON.parse(response.data);

    //if (responseData.response_message === '') getRefreshToken();
    const { data } = response;

    if (data.status_code === '402') {
      const token = await getRefreshToken();
      response.config.headers['Authorization'] = `Bearer ${token}`;
      return restApi(response.config);
    }
    if (data.status_code === '401' || data.status_code === '422') {
      if (localStorage.getItem('accessToken') === 'undefined') {
        signOut();
      }
    }
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const { response: errorResponse } = error;
    const originalRequest = error.config;

    // 인증 에러 발생시
    if (errorResponse?.status === 401) {
      try {
        const token = await getRefreshToken();
        originalRequest.headers['Authorization'] = `Bearer ${token}`;
        return restApi(originalRequest); // 재요청
      } catch (error) {
        //signOut();
        return Promise.reject(error);
      }
    }

    if (errorResponse?.status === 422) return error.response.data;

    return Promise.reject(error);
  },
);

export default restApi;
