import React, { useState } from 'react';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Img01 from '../../assets/images/guide/guiderecord01.png';
import Img02 from '../../assets/images/guide/guiderecord02.png';
import Img03 from '../../assets/images/guide/guiderecord03.png';
import Img04 from '../../assets/images/guide/guiderecord04.png';
import Img05 from '../../assets/images/guide/guiderecord05.png';
import TopNavigation from '../../components/atoms/TopNavigation';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../../assets/css/swipe.css';

export default function GuideRecord() {
  const navigate = useNavigate();

  return (
    <Container component="main">
      <TopNavigation
        type="back"
        handleEvent={() => navigate('/golfunguide')}
        subTitle="게임 기록"
      >
        골펀 가이드
      </TopNavigation>
      <SwipeWrap>
        <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
          <SwiperSlide>
            <img src={Img01} alt="Guide" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Img02} alt="Guide" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Img03} alt="Guide" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Img04} alt="Guide" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Img05} alt="Guide" />
          </SwiperSlide>
        </Swiper>
      </SwipeWrap>
    </Container>
  );
}

const SwipeWrap = styled.div`
  height: 90vh;
`;
