import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import styled from 'styled-components';
import restApi from '../../apis/apiAxios';
import IconSearch from '../../assets/images/icon/search_g.svg';
import MemberImg from '../../assets/images/member.png';
import { BottomButton } from '../../components/atoms/BottomButton';
import { postMessage } from '../../components/blocks/CommonFn';

interface Props {
  gameCode: string;
  gameID: number;
}

export const InviteGame: React.FC<Props> = (props) => {
  const [friendList, setFriendList] = useState([
    { golf_club_name: '직접 입력', location: '-' },
  ]);
  const [selectFriendList, setSelectFriendList] = useState<number[]>([]);

  const filterData = (query: string, data: any[]) => {
    if (!query) {
      return data;
    } else {
      return data.filter(
        (d) => d.golf_club_name && d.golf_club_name.includes(query),
      );
    }
  };

  const [searchQuery, setSearchQuery] = useState('');
  const dataFiltered = filterData(searchQuery, friendList);

  const selectProcess = () => {
    console.log('select list : ', selectFriendList);
  };

  const isGG = props.gameCode.slice(0, 2) === 'G_';

  const inviteGame = (name: string, id: string, userID: number) => {
    const apiURL = isGG ? '/game-room-group/invite' : '/game/invite';
    const paramsData = isGG
      ? { game_room_group_id: props.gameID, friend_user_id: userID }
      : { game_room_id: props.gameID, user_id_list: [userID] };

    restApi.post(apiURL, paramsData).then((e) => {
      const { data } = e;

      JSON.parse(id).forEach((d: any) => {
        const txt = `{ "dk": "${d}", "roomCode": "${props.gameCode}" }`;
        postMessage('inviteGame', txt);
      });
      if (data.status_code === '200') {
        callFriendList();
        alert(name + '님께 게임 초대를 보냈습니다.');
      }
    });
  };

  const callFriendList = () => {
    const apiURL = isGG
      ? '/game-room-group/invite/friend-list'
      : '/game/invite/friend-list';

    const paramsData = isGG
      ? { game_room_group_id: props.gameID }
      : { game_room_id: props.gameID };

    restApi
      .get(apiURL, {
        params: paramsData,
      })
      .then((e) => {
        const { data } = e;
        if (data.status_code === '200') {
          setFriendList(data.result_data);
        } else if (data.status_code === '505') {
          setFriendList([{ golf_club_name: '직접 입력', location: '-' }]);
        }
      });
  };

  useEffect(() => {
    callFriendList();
  }, []);

  return (
    <>
      <SearchBarWrap>
        <SearchBar
          id="search-bar"
          className="text"
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          placeholder="이름을 입력하세요."
          style={{ width: '100%' }}
          value={searchQuery}
        />
        <img src={IconSearch} alt="icon" />
      </SearchBarWrap>
      {dataFiltered.length === 0 ? (
        <ListItem style={{ display: 'block', textAlign: 'center' }}>
          현재 등록된 친구가 없습니다.
          <br />
          친구를 추가하여 게임을 즐겨보세요
        </ListItem>
      ) : (
        dataFiltered.map((d, i) => (
          <ListItem
            key={i}
            onClick={() =>
              d.is_invite === '0' &&
              inviteGame(
                d.nickname,
                JSON.stringify(d.user_device),
                d.friend_user_id,
              )
            }
          >
            <img src={d.profile_image_file_url || MemberImg} alt="Member" />
            <Typography variant="h5">{d.nickname}</Typography>
            <InviteBtnStyled
              className={d.is_invite === '1' ? 'disabled' : 'enabled'}
            >
              초대
            </InviteBtnStyled>
          </ListItem>
        ))
      )}
    </>
  );
};

const SearchBarWrap = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  img {
    position: absolute;
    top: 13px;
    left: 16px;
    width: 24px;
  }
`;

const SearchBar = styled.input`
  width: 100%;
  height: 50px;
  padding: 13px 16px 13px 52px;
  border: solid 1px #b1b8c0;
  border-radius: 25px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: #b1b8c0;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  width: 100%;
  margin-bottom: 8px;
  padding: 12px 16px 12px 12px;
  border-radius: 16px;
  background-color: #fafafb;

  transition: all 300ms ease-out;

  img {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border-radius: 24px;
    border-radius: 24px;
  }
  &.on {
    background-color: #f2f3f5;
    .check {
      border-color: #1fb04b;
      background-color: #1fb04b;
    }
  }
`;

const InviteBtnStyled = styled(Button)`
  position: absolute;
  right: 16px;
  width: 56px;
  height: 32px;
  border-radius: 8px;
  background-color: #1fb04b;

  font-size: 12px;
  font-weight: bold;
  color: #fff;

  &:hover {
    background-color: #1fb04b;
  }

  &.disabled {
    background-color: #e7e9ec;
    color: #b1b8c0;
  }
`;
