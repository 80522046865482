import React, { useEffect } from 'react';
import styled from 'styled-components';
import logo from './assets/images/golfun_w.svg';

function Splash() {
  useEffect(() => {
    setTimeout(() => {
      const atk = localStorage.getItem('accessToken');
      const rtk = localStorage.getItem('refreshToken');
      if (atk && rtk) {
        window.location.href = '/clubhouse';
      } else {
        window.location.href = '/onboarding';
      }
    }, 3000);
    //}
  }, []);

  return (
    <MainWrap>
      <LogoImg src={logo} alt="logo" />
    </MainWrap>
  );
}

export default Splash;

const MainWrap = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #1fb04b;
`;

const LogoImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 30%);
  max-width: 350px;
`;
