import React, { useState } from 'react';
import {
  Box,
  Container,
  Radio,
  RadioGroup,
  Button,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Arrow from '../../assets/images/icn_arrow_right.svg';
import TopNavigation from '../../components/atoms/TopNavigation';

export default function GolfunGuide() {
  const navigate = useNavigate();
  const [type, setType] = useState('');

  const [joker, setJoker] = useState(false);
  const [doublePar, setDoublePar] = useState(false);
  const [birdie, setBirdie] = useState(false);
  const [tripleBogey, setTripleBogey] = useState(false);
  const [OECD4, setOECD4] = useState(false);
  const [OECD6, setOECD6] = useState(false);
  const [mobster, setMobster] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setType((e.target as HTMLInputElement).value);
  };

  const createGame = () => {
    if (!type) {
      alert('게임 타입을 설정해주세요.');
      return false;
    }

    navigate('/gameshare');
  };
  return (
    <Container component="main">
      <TopNavigation type="back" handleEvent={() => navigate('/clubhouse')}>
        골펀 가이드
      </TopNavigation>
      <MenuListWrap>
        <MenuList onClick={() => navigate('/guidecreate')}>
          <Typography variant="h5">게임 생성하기</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => navigate('/guideinvite')}>
          <Typography variant="h5">플레이어 초대하기</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => navigate('/guideplay')}>
          <Typography variant="h5">함께 게임하기</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => navigate('/guideexit')}>
          <Typography variant="h5">게임 나가기</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => navigate('/guidestroke')}>
          <Typography variant="h5">게임 - 일반 및 스트로크</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => navigate('/guidechip')}>
          <Typography variant="h5">게임 - 칩 게임</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => navigate('/guidedraw')}>
          <Typography variant="h5">게임 - 뽑기 게임</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
        <MenuList onClick={() => navigate('/guiderecord')}>
          <Typography variant="h5">게임 기록</Typography>
          <img src={Arrow} alt="Icon" />
        </MenuList>
      </MenuListWrap>
    </Container>
  );
}

const MenuListWrap = styled.div``;

const MenuList = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  padding: 22px 16px 19px 24px;
  border-radius: 10px;
  background-color: #fafafb;

  &:first-child {
    margin-top: 32px;
  }
`;
