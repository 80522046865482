import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html, body, h1, input, select {font-family:'Pretendard', 'Apple SD Gothic Neo', arial, sans-serif}
  body {margin: 0; background: #fff; font-size: 12px; line-height: 20px;font-weight: 500;}
  a {color: #1a0dab; -webkit-tap-highlight-color:rgba(0, 0, 0, .10)}
  // a:visited {color:#609}
  // a:hover {text-decoration:underline}
  cite, cite a:link, cite a:visited {color: #202124; font-style: normal}
  * {box-sizing: border-box; -webkit-tap-highlight-color:rgba(255,255,255,0);}
  button {margin: 0}
  ol li {list-style: none}
  ol, ul, li {margin: 0; padding: 0}
  em {font-weight: bold; font-style: normal}

  /* common */
  body{
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background-color: #ddd;
  }

  #root{
    background-color: #fff;
    min-height: calc(100vh - 53px);
    padding: 0;
  }

  p, a {
    font-size: 1em;
  }

  input::-webkit-inner-spin-button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
`;

export default GlobalStyles;
