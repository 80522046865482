import React, { useState } from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import { BottomButton } from '../components/atoms/BottomButton';
import { DimmedPopup } from '../components/atoms/DimmedPopup';
import { InputBox } from '../components/atoms/InputBox';
import { TopNavigation } from '../components/atoms/TopNavigation';

export default function FindPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [id, setId] = useState<string>('');
  const [idMessage, setIdmessage] = useState<string>('');
  const [sendComplete, setSendComplete] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const sendEmail = () => {
    if (!id) {
      alert('이메일을 입력해주세요');
      return false;
    }

    restApi.put('/guest/reset-password', { email: id }).then((e) => {
      const { data } = e;

      if (data.status_code === '505') {
        setIdmessage('존재하지 않는 이메일입니다.');
      } else if (data.status_code === '200') {
        alert(
          '메일이 발송되었습니다.\n임시비밀번호를 확인하시고 로그인하여 변경해주세요.',
        );
        setSendComplete(true);
        navigate('/signin');
      }
      console.log(data);
    });
  };

  return (
    <Container component="main">
      <TopNavigation type="close" handleEvent={() => navigate('/onboarding')}>
        비밀번호 찾기
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TopInfo>
          골펀 가입 시 작성한 이메일 주소를 입력해주세요.
          <br />
          비밀번호 재설정 링크를 해당 이메일 계정으로 보내드립니다.
        </TopInfo>

        <InputBox
          id="id"
          title="이메일 주소"
          value={id}
          placehold="ID@example.com"
          handleEvent={(event) => {
            setId(event.target.value);
            setIdmessage('');
          }}
          message={idMessage}
        />

        <div style={{ margin: '19px' }} />

        <BottomButton
          txt="재설정 이메일 보내기"
          disabled={!id}
          handleEvent={sendEmail}
        />
      </Box>

      {sendComplete && (
        <DimmedPopup
          title="이메일이 발송되었습니다!"
          subtitle="꼭 비밀번호를 재설정해주세요!"
          loc="bottom"
          buttontitle="로그인 페이지로 이동"
          handleEvent={() => navigate('/signin')}
        />
      )}
    </Container>
  );
}

const TopInfo = styled.div`
  width: 100%;
  padding: 19px 16px 18px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #fafafb;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.1px;
  text-align: left;
  color: #0f0f0f;
`;
