import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import IcnCheckG from '../../assets/images/icn_check_g.svg';
import IcnCheckR from '../../assets/images/icn_check_r.svg';

interface Props {
  title: string;
  subtitle?: string;
  loc?: string;
  icon?: string;
  closeHandler: () => void;
}

export const ToastPopup: React.FC<Props> = ({
  title,
  subtitle,
  loc,
  icon,
  closeHandler,
}) => {
  useEffect(() => {
    setTimeout(() => {
      closeHandler();
    }, 2000);
  }, []);

  return (
    <PopupLayer className={loc}>
      <IconStyled src={icon === 'green' ? IcnCheckG : IcnCheckR} alt="icon" />
      <Typography variant="body1Bold">{title}</Typography>
      <br />
      <Typography variant="body3">{subtitle}</Typography>
    </PopupLayer>
  );
};

export default ToastPopup;

const slidIn = keyframes`
  0% { 
    transform: translate(-50%, -80%);
    opacity: 0; 
  }
  100%{ 
    transform: translate(-50%, -50%);
    opacity: 1; 
  }
`;

const PopupLayer = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 40px;
  display: flex;
  max-width: 350px;
  padding: 16px 45px 16px 22px;
  border-radius: 8px;
  background-color: #18171c99;

  text-align: center;
  z-index: 102;

  animation: ${slidIn} 500ms;

  .MuiTypography-root {
    color: #fff;
  }

  &.top {
    top: 120px;
    bottom: unset;
  }

  &.middle {
    top: 50%;
    bottom: unset;
  }
`;

const IconStyled = styled.img`
  width: 16px;
  margin-right: 15px;
`;
