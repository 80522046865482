import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import BottomButton from '../components/atoms/BottomButton';
import InputBox from '../components/atoms/InputBox';
import TopNavigation from '../components/atoms/TopNavigation';
import { postMessage } from '../components/blocks/CommonFn';
import { isValidEmail } from '../components/blocks/format';
import { useSiteState } from '../contexts/SiteContext';

export default function Signin() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [id, setId] = useState<string>('');
  const [pw, setPw] = useState<string>('');
  const [idMessage, setIdMessage] = useState<string>('');
  const [pwMessage, setPwMessage] = useState<string>('');
  const [dt, setDt] = useState(localStorage.getItem('dt') || '');
  const [signinError, setSigninError] = useState<boolean>(false);
  const { memberInfoChange } = useSiteState();
  const device = localStorage.getItem('device');

  const listener = (e: any) => {
    const { type, data } = JSON.parse(e.data);
    if (type === 'callData') {
      localStorage.setItem('dt', data);
      setTimeout(() => {
        setDt(data);
        signin();
      }, 500);
    }
  };

  if (device === 'iOS') {
    window.addEventListener('message', (e) => listener(e));
  } else {
    document.addEventListener('message', (e) => listener(e));
  }

  const signin = () => {
    if (!dt) {
      postMessage('callData', 'token');
      return;
    }

    if (!id || !pw) {
      alert('ID, PW 입력해주세요');
      return false;
    }

    if (!isValidEmail(id)) {
      setIdMessage('이메일 형식이 맞지 않습니다.');
      return;
    }

    restApi
      .post('/guest/login', {
        email: id,
        password: pw,
        device_id: dt,
      })
      .then((e) => {
        const { data } = e;
        if (data?.status_code === '505' || data?.status_code === '422') {
          // 이메일과 비밀번호가 맞지 않습니다.
          console.log('이메일과 비밀번호가 맞지 않습니다');
          setSigninError(true);
          return;
        }

        if (data?.status_code === '200') {
          localStorage.setItem('accessToken', data.result_data.access_token);
          localStorage.setItem('refreshToken', data.result_data.refresh_token);
          localStorage.setItem('user_id', id);

          restApi
            .get(`/user/detail`, {
              params: {
                user_email: id,
              },
            })
            .then((e) => {
              const { data: detail } = e;
              if (detail?.status_code === '200') {
                memberInfoChange(detail.result_data);
                navigate('/clubhouse');
              }
            });
        } else {
          console.log('login_e = ', data);
        }
      });
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && accessToken !== 'undefined') {
      navigate('/clubhouse');
    }
  }, []);

  return (
    <Container>
      <TopNavigation type="back" handleEvent={() => navigate('/onboarding')}>
        로그인
      </TopNavigation>

      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <InputBox
          id="id"
          title="이메일 주소"
          value={id}
          placehold="ID@Example.com"
          handleEvent={(event) => {
            setSigninError(false);
            setId(event.target.value);
          }}
          message={idMessage}
        />
        <InputBox
          id="password"
          title="비밀번호"
          value={pw}
          placehold="비밀번호를 입력해주세요!"
          handleEvent={(event) => {
            setSigninError(false);
            setPw(event.target.value);
          }}
          message={pwMessage}
          pwView={true}
        />

        <ErrorMessage style={{ display: signinError ? 'block' : 'none' }}>
          이메일 혹은 비밀번호가 일치하지 않습니다.
          <br />
          입력한 내용을 다시 확인해 주세요.
        </ErrorMessage>

        <BottomButton
          txt="이메일로 로그인"
          disabled={!id || !pw}
          handleEvent={signin}
        />

        <FIndPassword to="/findpassword">비밀번호를 잊으셨나요?</FIndPassword>
      </Box>
    </Container>
  );
}

const FIndPassword = styled(Link)`
  margin-top: 16px;
  font-size: 12px;
  font-weight: 500;
  color: #1fb04b;
  text-decoration: none;
`;

const ErrorMessage = styled.div`
  width: 100%;
  margin: 19px 0 16px;
  padding: 19px 16px 18px;
  border-radius: 8px;
  background-color: #f9e7e9;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.01px;
  color: #c13537;
`;
