import React, { useState } from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import restApi from '../apis/apiAxios';
import Camera from '../assets/images/icn_carmera.svg';
import Edit from '../assets/images/icn_edit.svg';
import Upload from '../assets/images/icn_upload.svg';
import MemberImg from '../assets/images/member.png';
import { BottomButton } from '../components/atoms/BottomButton';
import { InputBox } from '../components/atoms/InputBox';
import { TopNavigation } from '../components/atoms/TopNavigation';
import { encodeFileToBase64 } from '../components/blocks/CommonFn';
import { isImage, isNickname } from '../components/blocks/format';
import { useSiteState } from '../contexts/SiteContext';

interface SignupFormData {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
}

const Profile: React.FC = () => {
  const { memberInfo, memberInfoChange } = useSiteState();
  const navigate = useNavigate();
  const [nickname, setNickname] = useState(memberInfo?.nickname);
  const [nicknameMessage, setNicknameMessage] = useState('');
  const myImage = memberInfo?.profile_image_url || MemberImg;
  const [formData, setFormData] = useState<SignupFormData>({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (!e.target.files[0]) {
        return;
      }

      const imgFile = e.target.files[0];

      if (!isImage(imgFile)) {
        alert('jpg, jpeg, png, heic 타입의 이미지만 추가 할 수 있습니다.');
        return;
      }

      if (imgFile.size > 3000000) {
        alert('이미지의 사이즈는 3Mb를 넘을 수 없습니다.');
        return;
      }

      const file = new FormData();
      file.append('file', imgFile);

      //console.log('asfd = ', encodeFileToBase64(imgFile));

      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgFile);
        reader.onload = (event: any) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
      })
        .then((d) => {
          console.log('d = ', d);

          restApi
            .post('/user/profile-image', {
              file: d, //이미지 파일 base64변환하여 올리기
            })
            .then((e) => {
              console.log(e);
              const { data } = e;
              if (data.status_code === '200') {
                memberInfoChange({ profile_image_url: data.result_data });
                alert('이미지 변경이 완료 되었습니다.');
              } else {
                alert(
                  '등록에 실패 하였습니다. 다른 이미지 파일을 선택해주세요.',
                );
              }
            });
        })
        .catch((e) => {
          console.log(e);
        });

      /*restApi
        .post(
          '/user/profile-image',
          {
            file: encodeFileToBase64(imgFile), //이미지 파일 base64변환하여 올리기
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((e) => {
          console.log(e);
          const { data } = e;
          if (data.status_code === '200') {
            memberInfoChange({ profile_image_url: data.result_data });
          } else {
            alert('등록에 실패 하였습니다. 다른 이미지 파일을 선택해주세요.');
          }
        });*/
    }
  };

  const editProfile = () => {
    if (isNickname(nickname)) {
      send();
    } else {
      setNicknameMessage(
        '닉네임 형식을 맞춰 주세요(한글, 영문 포함 3자 이상 12자 이하)',
      );
    }
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
  };

  const send = () => {
    restApi
      .put('/user/update', {
        nickname: nickname,
      })
      .then((e) => {
        console.log(e);
        const { data } = e;
        if (data.status_code === '200') {
          memberInfoChange({ nickname: nickname });
          alert('이름 변경이 완료되었습니다.');
        } else {
          setNicknameMessage(data.status_message);
        }
      });
  };

  return (
    <Container>
      <TopNavigation type="back" handleEvent={() => navigate('/mypage')}>
        프로필 수정
      </TopNavigation>
      <Box
        sx={{
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
          <>
            <ImgBox>
              <label htmlFor="file" className="sr-only">
                <MyImage style={{ backgroundImage: `url(${myImage})` }} />
                <IconArea>
                  <IconStyled src={Upload} alt="Camera" />
                </IconArea>
              </label>
              <input
                id="file"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".png, .jpg, .jpeg, .HEIC"
              />
            </ImgBox>
            <InputBox
              id="nickname"
              title="닉네임"
              value={nickname}
              placehold="한글, 영문 포함 2자리 이상 12자리 이하"
              handleEvent={(event) => {
                setNickname(event.target.value);
                setNicknameMessage('');
              }}
              message={nicknameMessage}
            />
          </>
        </form>

        <BottomButton
          txt="닉네임 변경하기"
          disabled={nickname === memberInfo?.nickname}
          handleEvent={editProfile}
        />
      </Box>
    </Container>
  );
};

export default Profile;

const ImgBox = styled.div`
  position: relative;
  width: 100%;
  margin: 24px 0;
  text-align: center;
`;

const MyImage = styled.div`
  width: 96px;
  height: 96px;
  margin: 0 auto;
  border-radius: 48px;

  background: no-repeat center/100%;
  background-size: cover;
`;

const IconArea = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: 20px;

  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: #e7e9ec;
`;

const IconStyled = styled.img`
  width: 15px;
  margin-top: 5px;
`;

const TopInfo = styled.div`
  width: 100%;
  padding: 19px 16px 18px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #fafafb;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.1px;
  text-align: left;
  color: #0f0f0f;
`;
